import React, { useState, useEffect } from "react";
import './herdWise.css';
import { Col, Container, Row, Offcanvas, Accordion, Modal } from "react-bootstrap";
import { FormControlLabel, Radio, RadioGroup, TextField, FormControl, InputLabel, MenuItem, ListItemText, OutlinedInput, Select, List } from '@mui/material';
import { ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid, BarChart, Bar, Cell } from "recharts";
import { ChartComponent, Category, LineSeries, Inject, SeriesCollectionDirective, SeriesDirective, ColumnSeries } from '@syncfusion/ej2-react-charts';
import { downloadExcel } from "react-export-table-to-excel";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import filter from '../../Photo/milk-union/filter.svg';
import save from '../../Photo/milk-union/save.svg';
import preview from '../../Photo/milk-union/preview.svg';
import close from '../../Photo/milk-union/close.svg';
import moment from "moment";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import Dropdown from "../../Componet/Dropdown/Dropdown";
import Table from "../../Componet/DataTable/Table";
import { STORAGE_KEY } from "../../constant/common";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { ResponsiveBar } from "@nivo/bar";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { serviceUrl } from "../../API/Api";
import Searcable from "../../Componet/Dropdown/Searcable";
import BackBtn from "../../Componet/Button/BackBtn";



export default function HerdWise() {

  const API_KEY = serviceUrl;
  // const API_KEY = "https://hais.hap.in";
  const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
  const [heightOffSet, setHeight] = useState(0);

  const [progressData, setProgressData] = useState("0%");
  const [selectTab, selectTabFn] = useState("");
  const [reportName, setReportName] = useState("");
  const [Sddmmyyyy, setSDdMmYyyy] = useState("Date");
  const [sari, setSari] = useState("");
  const [search, setSearch] = useState("");
  const [searchSari, setSearchSari] = useState("");
  const [supervisorSearch, setSupervisorSearch] = useState("");
  const [editName, setEditName] = useState("");
  const [bene1, setBene1] = useState("");
  const [bene2, setBene2] = useState("");
  const [bene3, setBene3] = useState("");
  const [bene4, setBene4] = useState("");
  const [bene5, setBene5] = useState("");
  const [bene6, setBene6] = useState("");
  const [bene7, setBene7] = useState("");
  const [bene8, setBene8] = useState("");
  const [bene9, setBene9] = useState("");
  const [bene10, setBene10] = useState("");
  const [bene11, setBene11] = useState("");
  const [bene12, setBene12] = useState("");
  const [bene13, setBene13] = useState("");
  const [bene14, setBene14] = useState("");
  const [bene15, setBene15] = useState("");
  const [bene16, setBene16] = useState("");
  const [bene17, setBene17] = useState("");
  const [bene18, setBene18] = useState("");
  const [bene19, setBene19] = useState("");
  const [bene20, setBene20] = useState("");
  const [species, setSpecies] = useState("")
  const [breed, setBreed] = useState("")
  const [status, setStatus] = useState("")
  const [type, setType] = useState("")
  const [menuName, setMenuName] = useState("")
  const [excelReportName, setExcelReportName] = useState("")
  const [reportExcelName, setReportExcelName] = useState("")
  const [reportExcelTab, setReportExcelTab] = useState("")
  const navigate = useNavigate()
  const logOut = () => { navigate("/") }


  const [reportList, setReportList] = useState([]);
  const [fatchTabList, setFatchTabList] = useState([]);
  const [tabList, setTabList] = useState([]);
  const [speciesList, setSpeciesList] = useState([]);
  const [breedList, setBreedList] = useState([]);
  const [sariList, setSariList] = useState([]);
  const [sariIDList, setSariIDList] = useState([]);
  const [herdList, setHerdList] = useState([]);
  const [rowsList, setRowsList] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [rowsDateList, setRowsDateList] = useState([]);
  const [subHerdList, setSubHerdList] = useState([]);
  const [herdlistcolumn, setDynamicList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [chartData, setChartData] = useState([])
  const [subFatchList, setSubFatchList] = useState([])

  const lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);

  const selectedFromDate = lastWeek.toISOString().slice(0, 10);
  const selectedToDate = new Date().toISOString().slice(0, 10);

  const [showGraph, setShowGraph] = useState(true)
  const [fullscreen, setFullscreen] = useState(true);
  const [datashow, setDataShow] = useState(true);
  const [monthshow, setMonthShow] = useState(false);
  const [yearshow, setYearShow] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [herdModal, setHerdModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [centerShow, setCenterShow] = useState(false);
  const [headerShow, setHeaderShow] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(true); }
  const handleShow = () => { setHeaderShow(!headerShow); }

  const [showAccordion, setShowAccordion] = useState(true); // To control visibility of the div

  // List of values to hide the div
  const hideValues = new Set([1, 2, 3, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 39, 40]);

  const handleSearchableChange = (selectedOption) => {
    setReportName(selectedOption);
    setExcelReportName(selectedOption.label);

    const selectedValue = parseInt(selectedOption.value, 10);

    if (hideValues.has(selectedValue)) {
      setShowAccordion(false);
      console.log('a' + selectedValue);
    } else {
      setShowAccordion(true);
      console.log('b' + selectedValue);
    }
  };

  const menuitm = ['Average', 'Average ± Std.Dev', 'Average ± Std.Err', 'Count', 'Max', 'Min', 'Precent', 'Std.Dev', 'Std.Err', 'Total', 'Median', 'Mode'];
  const menuitm1 = ['Male', 'Female'];

  const fatchSelectFn = () => {
    fetch(`${API_KEY}/reportUnion/getall`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`
      },
    })
      .then(res => res.json())
      .then((result) => { setReportList(result); })

    fetch(`${API_KEY}/reportUnion/getFillTab`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`
      },
    })
      .then(res => res.json())
      .then((result) => { setFatchTabList(result); })

    fetch(`${API_KEY}/species/getall`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`
      },
    })
      .then(res => res.json())
      .then((result) => { setSpeciesList(result); })


    fetch(`${API_KEY}/breed/getall`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`
      },
    })
      .then(res => res.json())
      .then((result) => { setBreedList(result) })

    fetch(`${API_KEY}/straw-management/getSire`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`
      },
    })
      .then(res => res.json())
      .then((result) => { setSariList(result); })
  }

  const selectFn = (e) => {
    selectTabFn(e);
    if (e) {
      fetch(`${API_KEY}/reportUnion/fillTab`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json",
          "Authorization": `Bearer ${added_by}`
        },
        body: JSON.stringify({ Lot: 0, Route: 0, Zone: 0, tab: e })
      })
        .then(res => res.json())
        .then((result) => { setTabList(result); setFilterList(result) })
    }
    fetch(`${API_KEY}/reportUnion/fillTab`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify({ Lot: 0, Route: 0, Zone: 0, tab: "Supervisor" })
    })
      .then(res => res.json())
      .then((result) => { setSubFatchList(result); })
  }


  const selectFn1 = (e) => {
    console.log(e)
    if (e) {
      fetch(`${API_KEY}/reportUnion/fillTab`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json",
          "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify({ Lot: 0, Route: 0, Zone: e, tab: "Paravet" })
      })
        .then(res => res.json())
        .then((result) => { setTabList(result); })
    }
  }

  const colums = [
    {
      name: "Center Name",
      selector: "Center Name",
      cell: row => (
        <div
          style={{
            display: 'flex',
            width: "70px",
            justifyContent: 'flex-start'
          }}
        >
          {row['Center Name']}
        </div>
      )
    },
    {
      name: "ID",
      selector: "ID",
      cell: row => (
        <div
          style={{
            display: 'flex',
            width: "50px",
            justifyContent: 'flex-end'
          }}
        >
          {row['ID']}
        </div>
      ),
      width: "50px",
    },
  ]

  const colums1 = [
    {
      name: "Sire Name",
      selector: "Sire Name",
      cell: row => (
        <div
          style={{
            display: 'flex',
            width: "100px",
            justifyContent: 'flex-end'
          }}
        >
          {row['Sire Name']}
        </div>
      )
    },
    {
      name: "Sire Code",
      selector: "Sire Code",
      cell: row => (
        <div
          style={{
            display: 'flex',
            width: "100px",
            justifyContent: 'flex-end'
          }}
        >
          {row['Sire Code']}
        </div>
      )
    },
  ]

  const paravetColums = [
    // {
    //     name: <input type="checkbox" checked={isCheckAll} onChange={(e) => headercheckBox(e, true)} />,
    //     cell: (d) => <input type="checkbox" checked={d.checked} onChange={(e) => setCheckBoxFn(e, d, true)} />,
    //     width: "30px"
    // },
    {
      name: "ID",
      selector: "ID",
      width: "70px",
      left: true
    },
    {
      name: "Code",
      selector: "code",
      width: "100px",
      left: true
    },
    {
      name: "Paravet Name",
      selector: "Paravet Name",
    },
  ]

  const districtColums = [
    // {
    //   name: "Select",
    //   cell: (e) => <input type="checkbox" className="ms-1" />,
    //   width: "50px"
    // },
    {
      name: "Taluka Name",
      selector: "Taluka Name",
    },
    {
      name: "ID",
      selector: "ID",
      width: "50px",
    }
  ];

  const doctorColms = [
    // {
    //   name: "Select",
    //   cell: (e) => <input type="checkbox" className="ms-1" />,
    //   width: "50px"
    // },
    {
      name: "Doctor Name",
      selector: "Doctor Name",
    },
    {
      name: "ID",
      selector: "ID",
      width: "50px",
    }
  ];

  const dscColms = [
    // {
    //   name: "Select",
    //   cell: (e) => <input type="checkbox" className="ms-1" />,
    //   width: "50px"
    // },
    {
      name: "Lot Name",
      selector: "Lot Name",
    },
    {
      name: "Lot No",
      selector: "Lot No",
      width: "50px",
    },
    {
      name: "ID",
      selector: "ID",
      width: "50px",
    }
  ];

  const ProjectColms = [
    // {
    //   name: "Select",
    //   cell: (e) => <input type="checkbox" className="ms-1" />,
    //   width: "50px"
    // },
    {
      name: "Project Name",
      selector: "Project Name",
    },
    {
      name: "ID",
      selector: "ID",
      width: "40px",
    }
  ];

  const SariColms = [
    // {
    //     name: <input type="checkbox" className="ms-1" checked={isCheckAll} onChange={(e) => headercheckBox(e)} />,
    //     cell: (d) => <input type="checkbox" className="ms-1" checked={d.checked} onChange={(e) => setCheckBoxFn(e, d)} />,
    //     width: "30px"
    // },
    {
      name: "Name",
      selector: "Name",
    },
    {
      name: "ID",
      selector: "id",
      width: "40px",
    },
  ];

  const herdcol = [
    {
      name: "Code",
      selector: "Paravet Code",
      width: "100px"
    },
    {
      name: "Name",
      selector: "Paravet Name",
      width: "250px"
    },
    {
      name: "Animal",
      selector: "Animal(s) registered",
      width: "60px"
    },
    {
      name: "Eligible A.I",
      selector: "Eligible A I",
      width: "60px"
    },
    {
      name: "PD Recorded",
      selector: "PD Recorded",
      width: "60px"
    },
    {
      name: "Abortion Recorded",
      selector: "Abortion Recorded",
    },
    {
      name: "Calving Recorded",
      selector: "Calving Recorded",
    },
    {
      name: "Calving Recorded(C.A.I)",
      selector: "Calving Recorded(C_A_I)",
    },
    {
      name: "Calving Recorded(S.A.I)",
      selector: "Calving Recorded(S_A_I)",
    },
    {
      name: "Female Calf Birth(C)",
      selector: "Female Calf Birth(C)",
    },
    {
      name: "Female Calf Birth(S)",
      selector: "Female Calf Birth(S)",
    },
    {
      name: "Registered Calf(C A I)",
      selector: "Registered Calf(C A I)",
    },
    {
      name: "Registered Calf(C A I)",
      selector: "Registered Calf(S A I)",
    },
    {
      name: "Dry Recorded",
      selector: "Dry Recorded",
    },
    {
      name: "Milk Recorded",
      selector: "Milk Recorded",
    },
    {
      name: "Vaccination Recorded",
      selector: "Vaccination Recorded",
    },
    {
      name: "Deworming Recorded",
      selector: "Deworming Recorded",
    },
    {
      name: "Sold Recorded",
      selector: "Sold Recorded",
    },
    {
      name: "Died Recorded",
      selector: "Died Recorded",
    },
    {
      name: "Culled Recorded",
      selector: "Culled Recorded",
    },
  ];


  const dateColms = [
    {
      name: "Date",
      cell: (e) => <p className="m-0">{moment(e).format("MM-DD-YYYY")}</p>,
    },
  ];

  const monthColms = [
    {
      name: "Month",
      cell: (e) => <p className="m-0">{moment(e).format("MMM-YYYY")}</p>,
    },
  ];

  const yearColms = [
    {
      name: "Year",
      cell: (e) => <p className="m-0">{moment(e).format("YYYY")}</p>,
    },
  ];

  // const modalColms = [
  //   // {
  //   //     name: 'Total A.I',
  //   //     AI_Report: `${bene1}`,
  //   // },
  //   {
  //     name: 'Eligible A.I',
  //     AI_Report: `${bene2}`,
  //   },
  //   {
  //     name: 'Animal(s) registered',
  //     AI_Report: `${bene3}`,
  //   },
  //   {
  //     name: 'PD Recorded',
  //     AI_Report: `${bene4}`,
  //   },
  //   {
  //     name: 'Abortion Recorded',
  //     AI_Report: `${bene5}`,
  //   },
  //   {
  //     name: 'Calving Recorded',
  //     AI_Report: `${bene6}`,
  //   },
  //   {
  //     name: 'Calving Recorded(C.A.I)',
  //     AI_Report: `${bene7}`,
  //   },
  //   {
  //     name: 'Calving Recorded(S.A.I)',
  //     AI_Report: `${bene8}`,
  //   },
  //   {
  //     name: 'Female Calf Birth(C)',
  //     AI_Report: `${bene9}`,
  //   },
  //   {
  //     name: 'Female Calf Birth(S)',
  //     AI_Report: `${bene10}`,
  //   },
  //   {
  //     name: 'Registered Calf(C.A.I)',
  //     AI_Report: `${bene11}`,
  //   },
  //   {
  //     name: 'Registered Calf(S.A.I)',
  //     AI_Report: `${bene12}`,
  //   },
  //   {
  //     name: 'Dry Recorded',
  //     AI_Report: `${bene13}`,
  //   },
  //   {
  //     name: 'Milk Recorded',
  //     AI_Report: `${bene14}`,
  //   },
  //   {
  //     name: 'Vaccination Recorded',
  //     AI_Report: `${bene15}`,
  //   },
  //   {
  //     name: 'Deworming Recorded',
  //     AI_Report: `${bene16}`,
  //   },
  //   {
  //     name: 'Sold Recorded',
  //     AI_Report: `${bene17}`,
  //   },
  //   {
  //     name: 'Died Recorded',
  //     AI_Report: `${bene18}`,
  //   },
  //   {
  //     name: 'Culled Recorded',
  //     AI_Report: `${bene19}`,
  //   }
  // ];

  // const modalColms = [
  //   {
  //     name: herdlistcolumn.map((e) => e),
  //     // AIReport: `${bene1}`,
  //     // AIReport: herdList.map((e) => herdlistcolumn[e]),
  //   },
  //   { AIReport: `${bene2}` },
  //   { AIReport: `${bene3}` },
  //   { AIReport: `${bene4}` },
  //   { AIReport: `${bene5}` },
  //   { AIReport: `${bene6}` },
  //   { AIReport: `${bene7}` },
  //   { AIReport: `${bene8}` },
  //   { AIReport: `${bene9}` },
  //   { AIReport: `${bene10}` },
  //   { AIReport: `${bene11}` },
  //   { AIReport: `${bene12}` },
  //   { AIReport: `${bene13}` },
  //   { AIReport: `${bene14}` },
  //   { AIReport: `${bene15}` },
  //   { AIReport: `${bene16}` },
  //   { AIReport: `${bene17}` },
  //   { AIReport: `${bene18}` },
  //   { AIReport: `${bene19}` },
  //   { AIReport: `${bene20}` },
  // ];

  const ExcelColums = [
    {
      name: "Sl No",
      selector: "Sl No",
      width: "50px"
    },
    {
      name: "AIT Code",
      selector: "AIT Code",
      width: "90px"
    },
    {
      name: "AIT Name",
      selector: "AIT Name",
      width: "260px"
    },
    {
      name: "Route",
      selector: "Route",
      width: "100px"
    },
    {
      name: "DCS",
      selector: "DCS",
      // width: "100px"
    },
    {
      name: "Farmer Code",
      selector: "Farmer Code",
      width: "100px"
    },
    {
      name: "SAP Code",
      selector: "SAP Code",
      // width: "100px"
    },
    {
      name: "Farmer Name",
      selector: "Farmer Name",
      width: "300px"
    },
    {
      name: "Middle Name",
      selector: "Middle Name",
      width: "90px"
    },
    {
      name: "Last Name",
      selector: "Last Name",
      width: "80px"
    },
    {
      name: "Old TagID",
      selector: "Old TagID",
      width: "90px"
    },
    {
      name: "TagId",
      selector: "TagId",
    },
    {
      name: "Species",
      selector: "Species",
    },
    {
      name: "Breed",
      selector: "Breed",
    },
    {
      name: "Birth Date",
      cell: (e) => <p className="m-0">{moment(e).format("DD-MM-YYYY")}</p>,
    },
    {
      name: "Last Calving Date",
      cell: (e) => <p className="m-0">{moment(e).format("DD-MM-YYYY")}</p>,
    },
    {
      name: "Service Date",
      cell: (e) => <p className="m-0">{moment(e).format("DD-MM-YYYY")}</p>,
    },
    {
      name: "Repeat",
      selector: "Repeat",
      width: "80px"
    },
    {
      name: "No of A.I",
      cell: (e) => <p className="m-0">{e['No of A.I']}</p>,
      width: "60px"
    },
    {
      name: "Sire",
      selector: "Sire",
    },
    {
      name: "Service Type",
      selector: "Service Type",
      width: "90px"
    },
    {
      name: "Semen Type",
      selector: "Semen Type",
      width: "80px"
    },
    {
      name: "A.I to PD Days",
      cell: (e) => <p className="m-0">{e['A.I to PD Days']}</p>,
    },
    {
      name: "PD Check Date",
      selector: "PD Check Date",
      width: "90px"
    },
    {
      name: "PD 2 Result",
      selector: "PD 2 Result",
      width: "80px"
    },
    {
      name: "Abortion Date",
      selector: "Abortion Date",
      width: "90px"
    },
    {
      name: "Abortion Days",
      selector: "Abortion Days",
      width: "90px"
    },
    {
      name: "Calving Date",
      selector: "Calving Date",
      width: "90px"
    },
    {
      name: "Calving Type",
      selector: "Calving Type",
      width: "90px"
    },
    {
      name: "Calf1 Gender",
      selector: "Calf1 Gender",
      width: "90px"
    },
    {
      name: "Calf1 Tagid",
      selector: "Calf1 Tagid",
      width: "80px"
    },
    {
      name: "Calf2 Gender",
      selector: "Calf2 Gender",
      width: "80px"
    },
    {
      name: "Calf2 Tagid",
      selector: "Calf2 Tagid",
      width: "80px"
    },
    {
      name: "Entry Type",
      selector: "Entry Type",
      width: "80px"
    },
    {
      name: "Calving Interval",
      selector: "Calving Interval",
    },
    {
      name: "Lactation Number",
      selector: "Lactation Number",
      width: "110px"
    },
    {
      name: "Breeding Status",
      selector: "Breeding Status",
    },
    {
      name: "Status",
      selector: "Status",
    },
    {
      name: "PD 1 Result",
      selector: "PD 1 Result",
      width: "80px"
    },
    {
      name: "Calving Type Option",
      selector: "Calving Type Option",
    },
    {
      name: "id",
      selector: "id",
      width: "70px"
    },
    {
      name: "Calf1 Image",
      cell: (e) => (<img src={e['Calf1 Image']} />),
      width: "100px"
    },
  ]

  const DateFn = () => {

    const date1 = new Date(document.getElementById("date-1").value);
    const date2 = new Date(document.getElementById("date-2").value);

    if (date1.getTime() && date2.getTime()) {

      function dateRange(date1, date2, steps = 1) {
        const dateArray = [];
        let currentDate = date1;

        while (currentDate <= date2) {
          dateArray.push(moment(currentDate).format("L"));
          currentDate.setUTCDate(currentDate.getUTCDate() + steps);
        }
        return dateArray
      }
      const dates = dateRange(date1, date2);
      setDateList(dates);

      // start year - - 
      var startDate = document.getElementById("date-1").value;
      var endDate = document.getElementById("date-2").value;
      var startDate1 = moment(startDate).format("YYYY");
      var endDate1 = moment(endDate).format("YYYY");
      const Year = [];

      for (let i = startDate1; i <= endDate1; i++) {
        Year.push(moment(`${i}`).format("MM-DD-YYYY"));
      }
      setYearList(Year);
      // end year - - 

      // Start Month - -
      var jash1 = document.getElementById("date-1").value;
      var jash2 = document.getElementById("date-2").value;
      var startDate = jash1;
      var endDate = jash2;
      var month = [];
      var d0 = startDate.split('-');
      var d1 = endDate.split('-');

      for (var y = d0[0]; y <= d1[0]; y++) {
        for (var m = d0[1]; m <= 12; m++) {
          month.push(moment(y + "-" + m + "-1").format("MM-DD-YYYY"));
          if (y >= d1[0] && m >= d1[1]) break;
        };
        d0[1] = 1;
      };
      setMonthList(month);
      // End Month - -

    }
  }

  const chartDataList = [];
  const list = [];

  const ThData = () => {
    return herdlistcolumn?.map((data) => {
      return <th key={data}>{data}</th>;
    });
  };

  const tdData = () => {
    return tFilterList.map((data, index) => {
      if (index === tFilterList.length - 1) {
        return (
          <tr key={index} style={{ backgroundColor: '#ECECEC' }}>
            {herdlistcolumn.map((v, columnIndex) => {
              const isNumber = typeof data[v] === 'number' || /^\d+$/.test(data[v]);
              const alignmentStyle = isNumber ? { textAlign: "right" } : {};

              if (columnIndex === 1) {
                return <td key={v} style={{ position: "sticky", left: 0, zIndex: 1 }}>Total</td>;
              } else if (v === "Total") {
                return <td key={v} style={{ ...alignmentStyle, position: "sticky", left: 0, zIndex: 1, background: "#fff" }}>{data[v]}</td>;
              } else {
                return <td key={v} style={alignmentStyle} onClick={() => editFn(data, v)}>{data[v]}</td>;
              }
            })}
          </tr>
        );
      } else {
        return (
          <tr key={index}>
            {herdlistcolumn.map((v) => {
              const isNumber = typeof data[v] === 'number' || /^\d+$/.test(data[v]);
              const alignmentStyle = isNumber ? { textAlign: "right" } : {};

              if (v === "Total") {
                return <td key={v} style={{ ...alignmentStyle, position: "sticky", left: 0, zIndex: 1, background: "#fff" }}>{data[v]}</td>;
              } else {
                return <td key={v} style={alignmentStyle} onClick={() => editFn(data, v)}>{data[v]}</td>;
              }
            })}
          </tr>
        );
      }
    });
  };



  chartData?.forEach(e => {
    // Remove unnecessary fields
    delete e["ID"];
    delete e["Paravet Code"];
    delete e["Paravet Name"];
    delete e["Route Code"];
    delete e["Route Name"];
    delete e["Total"];

    // Get keys and values
    const dataKeys = Object.keys(e);
    const dataValues = Object.values(e);

    // Process and push only valid numbers
    dataValues.forEach((element, index) => {
      const key = dataKeys[index];
      if (!isNaN(element) && element !== null) { // Check for valid numbers
        chartDataList.push({ "name": key, "chartNum": element });
      }
    });
  });

  // const tdData = () => {
  //   return herdList.map((data) => {
  //     return (
  //       <tr>
  //         {
  //           herdlistcolumn.map((v) => {
  //             return <td onClick={() => editFn(data, v)}>{data[v]}</td>
  //           })
  //         }
  //       </tr>
  //     )
  //   })
  // }

  // const tdData = () => {
  //   return herdList.map((data) => {
  //     return (
  //       <tr>
  //         {
  //           herdlistcolumn.map((v) => {
  //             if (v === "Total") {
  //               return <td style={{ position: "sticky", left: 0, zIndex: 1, background: "#fff" }}>{data[v]}</td>;
  //             } else {
  //               return <td key={v} onClick={() => editFn(data, v)}>{data[v]}</td>;
  //             }
  //           })
  //         }
  //       </tr>
  //     );
  //   });
  // };



  // const tdData = () => {
  //   return herdList.map((data, index) => {
  //     return (
  //       <tr key={index}>
  //         <td style={{ position: "sticky", left: 0, zIndex: 1, background: "#fff" }}>
  //           <span style={{ fontWeight: index === herdList.length - 1 ? "bold" : "normal" }}>
  //             {index === herdList.length - 1 ? "Total" : data["Total"]}
  //           </span>
  //         </td>
  //         {herdlistcolumn.map((v) => {
  //           if (v != "Total") {
  //             return <td key={v} onClick={() => editFn(data, v)}>{data[v]}</td>;
  //           }
  //         })}
  //       </tr>
  //     );
  //   });
  // };

  // const tdData = () => {
  //   return herdList.map((data, index) => {
  //     const filteredData = Object.keys(data).filter(key => key !== "Total");
  //     return (
  //       <tr key={index}>
  //         {filteredData.map((key) => (
  //           <td key={key} onClick={() => editFn(data, key)}>{data[key]}</td>
  //         ))}
  //       </tr>
  //     );
  //   });
  // };




  // const tdData = () => {
  //   return herdList.map((data) => {
  //     return (
  //       <tr>
  //         {
  //           herdlistcolumn.map((v) => {
  //             if (v === "Total") {
  //               return <td style={{ position: "sticky", left: 0, zIndex: 1, background: "#fff" }}>{data[v]}</td>;
  //             } else {
  //               return <td onClick={() => editFn(data, v)}>{data[v]}</td>;
  //             }
  //           })
  //         }
  //       </tr>
  //     );
  //   });
  // };

  // const tdData = () => {
  //   return herdList.map((data, index) => {
  //     return (
  //       <tr key={index}>
  //         {herdlistcolumn.map((v) => {
  //           if (v !== "Total") {
  //             return <td onClick={() => editFn(data, v)}>{data[v]}</td>;
  //           }
  //         })}
  //       </tr>
  //     );
  //   });
  // };




  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  const ApplyFn = () => {
    chartData.length = 0;
    let ids = (rowsList?.map(i => i?.ID)).join(',');
    let sariids = (sariIDList?.map(i => i?.id)).join(',');

    const setdateformate = rowsDateList?.map((e) => {
      const abc = moment(e).format("MM-DD-YYYY");
      return abc;
    })

    let dateShare = setdateformate == "" ? selectedFromDate : setdateformate.toString();

    if (ids) {
      setIsLoading(true);
      fetch(`${API_KEY}/reportUnion/MilkUnionReportHerdwise`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json",
          "Authorization": `Bearer ${added_by}`
        },
        body: JSON.stringify({
          Breed: [breed], CalculateType: "", DT: dateShare, DateType: Sddmmyyyy, RegFlag: "", Report_by: selectTab, Reportcode: reportName.value, Sire: sariids ? sariids : "", Species: species ? species : "",
          Status: status ? status : "All", TMWhereSQL: ids, TotCalculateType: "", UnknownSireFlag: "", UserID: "201", semenType: sari
        })
      })
        .then(response => {
          const contentLength = response.headers.get('content-length');
          let loaded = 0;
          return new Response(
            new ReadableStream({
              start(controller) {
                const reader = response.body.getReader();
                read();
                function read() {
                  reader.read()
                    .then((progressEvent) => {
                      if (progressEvent.done) {
                        controller.close();
                        return;
                      }
                      loaded += progressEvent.value.byteLength;
                      const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                      setProgressData(percentageComplete)
                      controller.enqueue(progressEvent.value);
                      read();
                    })
                }
              }
            })
          );
        })
        .then(res => res.json())
        .then((result) => {
          setModalShow(false)
          setHerdList(result);
          setTFilterList(result)
          const column = Object.keys(result[0]);
          setDynamicList(column);
          setIsLoading(false);
          setCenterShow(true);
          setProgressData("0%");
          var last = result[result.length - 1];
          chartData.push(last)
          // setBene1(last["Total A.I"]);
          // setBene2(last['Eligible A.I']);
          // setBene3(last['Animal(s) registered']);
          // setBene4(last['PD Recorded']);
          // setBene5(last['Abortion Recorded']);
          // setBene6(last['Calving Recorded']);
          // setBene7(last['Calving Recorded(C.A.I)']);
          // setBene8(last['Calving Recorded(S.A.I)']);
          // setBene9(last['Female Calf Birth(C)']);
          // setBene10(last['Female Calf Birth(S)']);
          // setBene11(last['Registered Calf(C.A.I)']);
          // setBene12(last['Registered Calf(S.A.I)']);
          // setBene13(last['Dry Recorded']);
          // setBene14(last['Milk Recorded']);
          // setBene15(last['Vaccination Recorded']);
          // setBene16(last['Deworming Recorded']);
          // setBene17(last['Sold Recorded']);
          // setBene18(last['Died Recorded']);
          // setBene19(last['Culled Recorded']);
          // setBene20(last['Culled Recorded']);
        },
          (err) => {
            setIsLoading(false);
          })
    }
  }

  const SelectedRowFn = (rows) => {
    setRowsList(rows.selectedRows);
  }
  const SelectedRowFn1 = (rows) => {
    setRowsDateList(rows.selectedRows);
  }
  const SelectedRowFn2 = (rows) => {
    setSariIDList(rows.selectedRows);
  }

  const editFn = (e, value) => {
    let id = e.ID;
    let ids = (rowsList.map(i => i.ID)).join(',');
    setEditName(value);
    if (e.ID || e.ID == 0) {
      setIsLoading(true);
      const dateShare = rowsDateList == "" ? selectedFromDate : rowsDateList;
      fetch(`${API_KEY}/reportUnion/MilkUnionReportDetails`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json",
          "Authorization": `Bearer ${added_by}`
        },
        body: JSON.stringify({
          Breed: [breed], CalculateType: null, DT: dateShare.toString(), DateType: Sddmmyyyy, FieldValue: value, RegFlag: null, Report_by: selectTab, Reportcode: reportName.value,
          Sire: null, Species: null, Status: "All", TMWhereSQL: id == 0 ? ids : id, TotCalculateType: null, UnknownSireFlag: null, UserID: 201, requestType: "HERDWISE", semenType: 2
        })
      })
        .then(response => {
          const contentLength = response.headers.get('content-length');
          let loaded = 0;
          return new Response(
            new ReadableStream({
              start(controller) {
                const reader = response.body.getReader();
                read();
                function read() {
                  reader.read()
                    .then((progressEvent) => {
                      if (progressEvent.done) { controller.close(); return; }
                      loaded += progressEvent.value.byteLength;
                      const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                      setProgressData(percentageComplete)
                      controller.enqueue(progressEvent.value);
                      read();
                    })
                }
              }
            })
          );
        })
        .then(res => res.json())
        .then((result) => { setSubHerdList(result); setHerdModal(true); setIsLoading(false); setProgressData("0%"); })
    }
  }


  // const handleDownloadExcel = () => {
  //   const opt = editName == "Eligible A.I" ? `Eligible AI ${selectedFromDate}` : `${editName} ${selectedFromDate}`;
  //   const header = ["Sl No", "Tag ID", "Old Tag ID", "Entry Type", "A.I Date", "Service Time", "HI", "No of A.I", "Calving Date", "Sire Name", "Sire Deduction Code", "A.I Cost",
  //     "Total A.I Cost", "Sire SAP Code", "A.I Dose", "Repeat", "Breeding Status", "Species", "Breed", "Farmer Name", "Farmer Code", "SAP Code", "Mobile Number", "Route Name",
  //     "DCS Name", "DCS Code", "AIT Code", "AIT Name", "Center Name", "Disposal Type", "Owner Suspended", "DCS Closed", "SAP Sending Date", "SAP Status", "SAP Message",
  //     "SAP Transaction No", "SAP Order Number"
  //   ];

  //   downloadExcel({
  //     fileName: opt,
  //     sheet: "data",
  //     tablePayload: {
  //       header,
  //       body: subHerdList
  //     }
  //   });
  // }

  // const handledExcel = () => {
  //   if (centerShow === true) {
  //     const opt = `Report Detail ${selectedFromDate}`;
  //     const header = ["Paravet Code", "Paravet Name", "Total A.I", "Eligible A.I", "Animal(s) registered", "PD Recorded", "Abortion Recorded", "Calving Recorded", "Calving Recorded(C.A.I)",
  //       "Calving Recorded(S.A.I)", "Female Calf Birth(C)", "Female Calf Birth(S)", "Registered Calf(C.A.I)", "Registered Calf(S.A.I)", "Dry Recorded", "Milk Recorded",
  //       "Vaccination Recorded", "Deworming Recorded", "Sold Recorded", "Died Recorded", "Culled Recorded"];

  //     downloadExcel({
  //       fileName: opt,
  //       sheet: "data",
  //       tablePayload: {
  //         header,
  //         body: herdList
  //       }
  //     });
  //   }
  //   else {
  //     alert("Not Data Found");
  //   }
  // }

  const handledExcel = () => {
    if (centerShow === true) {
      const dateShare = rowsDateList === "" ? selectedFromDate : rowsDateList;
      if (centerShow === true && herdList.length > 0) {
        const opt = `Herdwise Detail:- ${dateShare}`;
        const ReportName = `Report Name:- ${reportName.label}`;
        const ReportTab = `Report Tab:- ${selectTab}`;
        const header = Object.keys(herdList[0]);

        const csvContent = `${opt}\n${ReportName}\n${ReportTab}\n${header.join(",")}\n${herdList.map(row => Object.values(row).join(",")).join("\n")}`;

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${opt}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        alert('No Data Found');
      }
    }
    else {
      alert("Not Data Found");
    }
  }


  const handleDownloadExcel = () => {
    const dateShare = rowsDateList === "" ? selectedFromDate : rowsDateList;
    if (centerShow === true && subHerdList.length > 0) {
      const opt = `HerdWise Detail:- ${dateShare}`;
      const ReportName = `Report Name:- ${reportExcelName}`;
      const ReportTab = `Report Tab:- ${reportExcelTab}`;
      const header = Object.keys(subHerdList[0]);

      const csvContent = `${opt}\n${ReportName}\n${ReportTab}\n${header.join(",")}\n${subHerdList.map(row => Object.values(row).join(",")).join("\n")}`;

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `${opt}.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("No Data Found");
    }
  };

  const Selectdmy = (e) => {
    setSDdMmYyyy(e);
    console.log("e : " + e);

    if (e == "Month") {
      setMonthShow(true); setDataShow(false); setYearShow(false);
    }
    else if (e == "Year") {
      setYearShow(true); setDataShow(false); setMonthShow(false);
    }
    else if (e == "Date") {
      setDataShow(true); setMonthShow(false); setYearShow(false);
    }
  }

  const handleChange = (event) => {
    const { target: { value }, } = event;
    const preventDuplicate = value.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
    setMenuName(typeof preventDuplicate === 'string' ? preventDuplicate.split(',') : preventDuplicate);
  };

  const handleCheckboxChange = (optionValue) => {
    const selectedIndex = selectedOptions.indexOf(optionValue);
    let newSelectedOptions = [...selectedOptions];

    if (selectedIndex === -1) {
      newSelectedOptions.push(optionValue);
    } else {
      newSelectedOptions.splice(selectedIndex, 1);
    }

    setSelectedOptions(newSelectedOptions);
  };

  const handleSelectedOptions = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  useEffect(() => {
    setSDdMmYyyy("Date");
    setDataShow(true);
    setMonthShow(false);
    setYearShow(false);
    setHeaderShow(false);
    fatchSelectFn();
    DateFn();
    let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
    setHeight(divHeight);
  }, []);

  const graphHandle = () => {
    setShowGraph(prev => !prev)
  }

  const [filterList, setFilterList] = useState(tabList);

  const searchFn = (e) => {
    const data = e.target.value;
    const abc = tabList;
    let filteredData = [];

    if (data != "" || data != null) {
      if (selectTab === "Center" || selectTab === "Route") {
        filteredData = abc.filter((item) => {
          return (
            item["Center Name"].toLowerCase().includes(data.toLowerCase())
          );
        });
      } else if (selectTab === "District" || selectTab === "Taluka" || selectTab === "Village" || selectTab === "Hamlet") {
        filteredData = abc.filter((item) => {
          return item["Taluka Name"].toLowerCase().includes(data.toLowerCase());
        });
      } else if (selectTab === "Doctor/VO") {
        filteredData = abc.filter((item) => {
          return item["Doctor Name"].toLowerCase().includes(data.toLowerCase());
        });
      } else if (selectTab === "DCS/Lot") {
        filteredData = abc.filter((item) => {
          return item["DCS/Lot Name"].toLowerCase().includes(data.toLowerCase());
        });
      } else if (selectTab === "Paravet") {
        filteredData = abc.filter((item) => {
          return item["Paravet Name"].toLowerCase().includes(data.toLowerCase()) || item["code"].toLowerCase().includes(data.toLowerCase() || item["ID"].includes(data));
        });
      } else if (selectTab === "Project Wise") {
        filteredData = abc.filter((item) => {
          return item["Project Name"].toLowerCase().includes(data.toLowerCase());
        });
      }
    } else {
      filteredData = tabList
    }
    setFilterList(filteredData);
    console.log(filteredData);
    setSupervisorSearch(data);
  }

  const [tFilterList, setTFilterList] = useState([]);

  const SearchTableData = (e) => {
    const data = e.toLowerCase();
    setSearch(data);
    const abc = herdList

    let filteredData = [];

    if (data !== "") {
      if (selectTab === "Center" || selectTab === "Route") {
        filteredData = abc.filter((item) => item["Route Name"].toLowerCase().includes(data));
      }
      else if (selectTab === "District" || selectTab === "Taluka" || selectTab === "Village" || selectTab === "Hamlet") {
        filteredData = abc.filter((item) => item["Taluka Name"].toLowerCase().includes(data));
      }
      else if (selectTab === "Doctor/VO") {
        filteredData = abc.filter((item) => item["Doctor Name"].toLowerCase().includes(data));
      } else if (selectTab === "DCS/Lot") {
        filteredData = abc.filter((item) => item["DCS/Lot Name"].toLowerCase().includes(data));
      }
      else if (selectTab === "Paravet") {
        filteredData = abc.filter((item) =>
        (item["Paravet Name"]?.toLowerCase().startsWith(data.toLowerCase()) ||
          item["Paravet Code"]?.toLowerCase().includes(data) ||
          String(item["ID"])?.includes(data))
        );
      }

      else if (selectTab === "Project Wise") {
        filteredData = abc.filter((item) => item["Project Name"].toLowerCase().includes(data));
      }
      setTFilterList(filteredData);
    }
    else {
      setTFilterList(herdList)
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          {headerShow && (
            <Col lg="2" className="hideNavbar">
              <Sidenav />
            </Col>
          )}
          <Col className="header" lg={headerShow ? "10" : "12"}>
            <Container fluid>
              <Row className="pt-1 sub-header align-items-center justify-content-between">
                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                  <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                  <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                  <h6 className="ms-2 mt-2 dataHeading">Milk Union Reports / Herd Wise</h6>
                </Col>
                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                  <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                </Col>
                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                  <img src={profile} width={40} />
                  <div className="dropdpwn_Open">
                    <Row className="text-center align-items-center">
                      <Col xs="12" className="menuHover mb-3">
                        <Row >
                          <Col className="" xs="3">
                            <span className=""><MdOutlineVpnKey size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Change Password
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" className="menuHover" onClick={logOut}>
                        <Row>
                          <Col className="" xs="3">
                            <span className=""><GoSignOut size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Sign Out
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-start">
                <Col lg="2" md="2">
                  <div className="blue_bg_Animal p-1 align-items-start justify-content-center">
                    <Row className="justify-content-center">
                      <Col xl="11">
                        <FormControl variant="standard" fullWidth>
                          {/* <InputLabel id="demo-simple-select-standard-label">Report Name</InputLabel> */}
                          {/* <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={reportName} onChange={(e) => setReportName(e.target.value)}>
                            {reportList.map((e) => {
                              return (
                                < MenuItem className='drop_value' key={e.id} value={e.id} onClick={() => setReportExcelName(e.name)} > {e.name}</MenuItem>
                              )
                            })}
                          </Select> */}
                          <Searcable
                            placeholder="Report Name"
                            options={reportList}
                            onChange={(selectedOption) => { handleSearchableChange(selectedOption); setReportName(selectedOption); setExcelReportName(selectedOption.label) }}
                            value={reportName}
                            labelKey="name"
                            valueKey="id"
                          />
                        </FormControl>
                      </Col>
                    </Row>
                    <Row className="pt-3 justify-content-center">
                      <Col xl="11">
                        <FormControl variant="standard" fullWidth>
                          <Searcable
                            placeholder="Tabs"
                            options={fatchTabList}
                            onChange={(selectedOption) => selectFn(selectedOption.value)}
                            // value={selectTab}
                            labelKey="Name"
                            valueKey="Name"
                          />
                        </FormControl>
                      </Col>
                    </Row>
                    {
                      selectTab === "Paravet" && <Row className="pt-3 justify-content-center">
                        <Col xl="11">
                          <FormControl variant="standard" fullWidth>
                            <Searcable
                              placeholder="Supervisor Name"
                              options={subFatchList}
                              onChange={(selectedOption) => selectFn1(selectedOption.value)}
                              // value={selectTab}
                              labelKey="Paravet Name"
                              valueKey="ID"
                            />
                          </FormControl>
                        </Col>
                      </Row>
                    }
                    {selectTab ?
                      <Row className="justify-content-center">
                        <Col xl="11">
                          <TextField label={`Search ${selectTab}`} value={supervisorSearch} onChange={(e) => { setSupervisorSearch(e.target.value); searchFn(e); }} fullWidth variant="standard" />
                        </Col>
                      </Row>
                      : ""}
                    <Row className="pt-2 justify-content-center">
                      <Col>
                        {selectTab === "Center" || selectTab === "Route" ?
                          <Table columns={colums} data={filterList ? filterList : tabList} height={`calc(100vh - (${heightOffSet}px + 210px))`} selectableRows onSelectedRowsChange={SelectedRowFn} /> :
                          <>
                            {selectTab === "Bull" ?
                              <Table columns={colums1} data={filterList ? filterList : tabList} selectableRows onSelectedRowsChange={SelectedRowFn} /> : ""}
                            {selectTab === "District" || selectTab === "Taluka" || selectTab === "Village" || selectTab === "Hamlet" ?
                              <Table columns={districtColums} data={filterList ? filterList : tabList} selectableRows onSelectedRowsChange={SelectedRowFn} /> : ""}
                            {selectTab === "Doctor/VO" ? <Table columns={doctorColms} data={filterList ? filterList : tabList} selectableRows onSelectedRowsChange={SelectedRowFn} height={`calc(100vh - (${heightOffSet}px + 230px))`} /> : ""}
                            {selectTab === "DCS/Lot" ? <Table columns={dscColms} data={filterList ? filterList : tabList} selectableRows onSelectedRowsChange={SelectedRowFn} height={`calc(100vh - (${heightOffSet}px + 230px))`} /> : ""}
                            {selectTab === "Paravet" ? <Table columns={paravetColums} data={filterList ? filterList : tabList} selectableRows onSelectedRowsChange={SelectedRowFn} height={`calc(100vh - (${heightOffSet}px + 230px))`} /> : ""}
                            {selectTab === "Project Wise" ? <Table columns={ProjectColms} data={filterList ? filterList : tabList} selectableRows onSelectedRowsChange={SelectedRowFn} height={`calc(100vh - (${heightOffSet}px + 230px))`} /> : ""}
                          </>
                        }
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={showAccordion === true ? "8" : "10"} md={showAccordion === true ? "8" : "10"} className="ps-0">
                  <Row className="align-items-start">
                    <Col xl="12">
                      <div className="blue_bg_Animal align-items-end justify-content-center" id={centerShow ? "" : "center"}>
                        {centerShow && (
                          <>
                            <Row className="text-start align-items-end justify-content-between ms-2 mb-2">
                              <Col xl="2"><TextField label="Search Paravet Name" value={search} onChange={e => SearchTableData(e.target.value)} id="standard-basic" fullWidth variant="standard" /></Col>
                              <Col xl="8" className="d-flex justify-content-end">
                                <Col xl="2"><button className="btn-blue-Animal" onClick={() => setModalShow(true)}><img src={filter} className="me-2" width="15" />Filter</button></Col>
                                <Col xl="3"><button className="btn-blue-Animal" onClick={graphHandle}><img src={save} className="me-2" width="15" />Graph</button></Col>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mx-2 mainTable" style={{ overflow: "auto", maxHeight: showGraph === false ? "72vh" : "46vh" }}>
                                <table className="overTable" border={1}>
                                  <thead border={1}>
                                    <tr className="herdHeader1">{ThData()}</tr>
                                  </thead>
                                  <tbody border={1}>
                                    {tdData()}
                                  </tbody>
                                </table>
                              </Col>
                            </Row>
                            <hr className="mb-1 mt-1" />
                            {
                              showGraph ? <Row>
                                <Col>
                                  <ResponsiveContainer aspect={4}>
                                    <BarChart data={chartDataList} margin={{ right: 0, left: 0, top: 0, bottom: 65 }}>
                                      <CartesianGrid />
                                      <XAxis dataKey="name" style={{ fontSize: "10px" }} interval={0} textAnchor="end" angle={-25} />
                                      <YAxis type="number" domain={[0, bene1 + 100]} />
                                      <Tooltip contentStyle={{ fontSize: "12px", backgroundColor: "white" }} cursor={{ fill: 'transparent' }} />
                                      <Bar dataKey="chartNum" fill="#8FA9F5"> </Bar>
                                    </BarChart>
                                  </ResponsiveContainer>
                                </Col>
                              </Row> : false
                            }
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-end justify-content-end" id='heightOffsetStock'>
                    <Col xxl="2" xl="1" lg="2" md="4" className="p-0 justify-content-end"><button className="btn-blue-Animal mt-2 justify-content-end" onClick={() => ApplyFn()}>Apply</button></Col>
                    <Col xxl="2" xl="2" lg="2" md="4" className="p-0 justify-content-end"><button className="btn-blue-Animal mt-2" onClick={handledExcel}>Export to Excel</button></Col>
                    <Col xxl="2" xl="2" lg="2" className="p-0 justify-content-end"><button className="btn-blue-Animal mt-2"><img src={preview} className="me-2" width="15" />Preview</button></Col>
                    <Col xxl="2" xl="2" lg="2" className="p-0 justify-content-end"><button className="btn-blue-Animal mt-2 justify-content-end"><img src={close} className="me-2" width="15" />Close</button></Col>
                  </Row>
                </Col>
                {showAccordion && (
                  <Col lg="2" md="2">
                    <div className="blue_bg_Animal p-2" id="TableHeight">
                      <Accordion defaultActiveKey={['0']} flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header><h6 className="align-items-center mt-2 accordHeading">Select Date</h6></Accordion.Header>
                          <Accordion.Body className="p-2">
                            <Row className="justify=content-center">
                              <Col>
                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" value={Sddmmyyyy} name="row-radio-buttons-group">
                                  <FormControlLabel value="Date" control={<Radio className="p-1" />} onChange={e => Selectdmy(e.target.value)} label="Date" className="mt-1" />
                                  <FormControlLabel value="Month" control={<Radio className="p-1" />} onChange={e => Selectdmy(e.target.value)} label="Month" className="mt-1" />
                                  <FormControlLabel value="Year" control={<Radio className="p-1" />} onChange={e => Selectdmy(e.target.value)} label="Year" className="mt-1" />
                                </RadioGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div>
                                  <sub className="mb-0 pb-0">From Date</sub>
                                  <input type="date" className="dateSelect_bg" id="date-1" max={new Date().toISOString().split('T')[0]} defaultValue={selectedFromDate} onChange={() => DateFn()} />
                                </div>
                                <div className="mt-1">
                                  <sub className="mb-0 pb-0">To Date</sub>
                                  <input type="date" className="dateSelect_bg" id="date-2" max={new Date().toISOString().split('T')[0]} defaultValue={selectedToDate} onChange={() => DateFn()} />
                                </div>
                              </Col>
                            </Row>
                            {datashow && (
                              <Row>
                                <Col className="mt-2">
                                  <Table columns={dateColms} data={dateList} height="59vh" selectableRows onSelectedRowsChange={SelectedRowFn1} />
                                </Col>
                              </Row>
                            )}
                            {monthshow && (
                              <Row>
                                <Col className="mt-2">
                                  <Table columns={monthColms} data={monthList} height="59vh" selectableRows onSelectedRowsChange={SelectedRowFn1} />
                                </Col>
                              </Row>
                            )}
                            {yearshow && (
                              <Row>
                                <Col className="mt-2">
                                  <Table columns={yearColms} data={yearList} height="59vh" selectableRows onSelectedRowsChange={SelectedRowFn1} />
                                </Col>
                              </Row>
                            )}

                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header><h6 className="align-items-center mt-2 accordHeading">Select Sire</h6></Accordion.Header>
                          <Accordion.Body className="p-2">
                            {/* <Row>
                            <Col>
                              <TextField label="Search Sire" value={searchSari} onChange={e => setSearchSari(e.target.value)} id="standard-basic" fullWidth variant="standard" />
                              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" value={sari} name="row-radio-buttons-group">
                                <FormControlLabel value="0" control={<Radio className="p-1" />} onChange={e => setSari(e.target.value)} label="Normal" className="mt-2" style={{ fontSize: "12px" }} />
                                <FormControlLabel value="1" control={<Radio className="p-1" />} onChange={e => setSari(e.target.value)} label="Sorted" className="mt-2" />
                                <FormControlLabel value="2" control={<Radio className="p-1" />} onChange={e => setSari(e.target.value)} label="Both" className="mt-2" />
                              </RadioGroup>
                            </Col>
                          </Row> */}
                            <Row>
                              <Col className="mt-2 px-1">
                                <Table columns={SariColms}
                                  data={sariList.filter((item) => {
                                    if (searchSari === "") {
                                      return item;
                                    } else if (item.id == searchSari) {
                                      return item;
                                    }
                                  })} height="66vh" selectableRows onSelectedRowsChange={SelectedRowFn2} />
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Col>
                )}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container >

      {show && (<Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>)
      }

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="align-items-center justify-content-start">
              {/* <Col xl="6">
                <Dropdown label="Sex" menuList={menuitm1} />
              </Col> */}
              <Col xl="6">
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">Species</InputLabel>
                  <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={species} onChange={(e) => setSpecies(e.target.value)}>
                    {speciesList.map((e) =>
                      <MenuItem className='drop_value' key={e.id} value={e.id}>{e.Name}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Col>
              <Col xl="6">
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">Breed</InputLabel>
                  <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={breed} onChange={(e) => setBreed(e.target.value)}>
                    {breedList.map((option) => (
                      <MenuItem className='drop_value' key={option.id} value={option.id}><input
                        type="checkbox"
                        value={option.id}
                        checked={selectedOptions.includes(option.id)}
                        onChange={() => handleCheckboxChange(option.id)}
                        className="me-2"
                      />
                        {option.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <ReactMultiSelectCheckboxes options={breedList.map((e) => e.Name)} /> */}
              </Col>
              <Col xl="12">
                <Dropdown label="Field Type" menuList={menuitm} />
              </Col>
            </Row>
            <Row className="mt-1 align-items-center justify-content-start">
              <Col xl="2"><h6 className="mb-0 text-end">Type :</h6></Col>
              <Col>
                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                  <FormControlLabel value="Adults" control={<Radio className="pe-1" />} label="Adults" className="mt-1" onChange={(e) => setType(e.target.value)} />
                  <FormControlLabel value="Heifers" control={<Radio className="pe-1" />} label="Heifers" className="mt-1" onChange={(e) => setType(e.target.value)} />
                  <FormControlLabel value="Calf" control={<Radio className="pe-1" />} label="Calf" className="mt-1" onChange={(e) => setType(e.target.value)} />
                  <FormControlLabel value="Both" control={<Radio className="pe-1" />} label="Both" className="mt-1" onChange={(e) => setType(e.target.value)} />
                </RadioGroup>
              </Col>
            </Row>
            <Row className="align-items-start justify-content-start">
              <Col xl="2"><h6 className="mb-0 text-end">Status&nbsp;:</h6></Col>
              <Col>
                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                  <FormControlLabel value="All" control={<Radio className="pe-1 py-0" />} label="All" className="mt-1" onChange={(e) => setStatus(e.target.value)} />
                  <FormControlLabel value="Open" control={<Radio className="pe-1 py-0" />} label="Open" className="mt-1" onChange={(e) => setStatus(e.target.value)} />
                  <FormControlLabel value="Preg" control={<Radio className="pe-1 py-0" />} label="Preg" className="mt-1" onChange={(e) => setStatus(e.target.value)} />
                  <FormControlLabel value="Milk" control={<Radio className="pe-1 py-0" />} label="Milk" className="mt-1" onChange={(e) => setStatus(e.target.value)} />
                  <FormControlLabel value="Dry" control={<Radio className="pe-1 py-0" />} label="Dry" className="mt-1" onChange={(e) => setStatus(e.target.value)} />
                </RadioGroup>
              </Col>
            </Row>
            <Row className="align-items-start justify-content-start">
              <Col xl="2"><h6 className="mb-0 text-end">Sire&nbsp;:</h6></Col>
              <Col>
                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" value={sari} name="row-radio-buttons-group">
                  <FormControlLabel value="0" control={<Radio className="pe-1 py-0" />} onChange={e => setSari(e.target.value)} label="Normal" className="mt-2" style={{ fontSize: "12px" }} />
                  <FormControlLabel value="1" control={<Radio className="pe-1 py-0" />} onChange={e => setSari(e.target.value)} label="Sorted" className="mt-2" />
                  <FormControlLabel value="2" control={<Radio className="pe-1 py-0" />} onChange={e => setSari(e.target.value)} label="Both" className="mt-2" />
                </RadioGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="mt-1">
                <input type="checkbox" className="me-2 mt-1" />
                <label>Show Animal(s) Count</label>
              </Col>
              <Col className="mt-1">
                <input type="checkbox" className="me-2 mt-1" />
                <label>Hide Blank</label>
              </Col>
            </Row>
            <Row>
              <Col className="mt-1">
                <input type="checkbox" className="me-2 mt-1" />
                <label>Show Percentage</label>
              </Col>
              <Col className="mt-1">
                <input type="checkbox" className="me-2 mt-1" />
                <label>Show Both</label>
              </Col>
            </Row>
            <Row>
              <Col className="mt-1">
                <input type="checkbox" className="me-2 mt-1" />
                <label>Hide SAP Synced Data</label>
              </Col>
              <Col className="mt-1">
                <input type="checkbox" className="me-2 mt-1" />
                <label>Include Unknown Sire</label>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-end">
              <Col xl="3"><button className="btn-blue-Animal mt-1" onClick={ApplyFn}>Apply</button></Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen
        show={herdModal}
        onHide={() => setHerdModal(false)}
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title id="contained-modal-title-vcenter">{reportName?.label}{selectTab}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <Col>
                {subHerdList?.length > 25 ? <Table columns={ExcelColums} data={subHerdList} pagination height="69vh" />
                  :
                  <Table columns={ExcelColums} data={subHerdList} height="55vh" />}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="py-2">
          <Container fluid>
            <Row className="justify-content-between">
              <Col xl="1"><button className="btn-blue-Animal" onClick={handleDownloadExcel}>Export</button></Col>
              <Col xl="1"><button className="btn-blue-Animal" onClick={() => setHerdModal(false)}>Cancel</button></Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      {
        isLoading ?
          <div className="circleText">
            <div className="progressText">{progressData}</div>
            <div className="">
              <span className="loader-circle-13"></span>
            </div>
          </div>
          : false
      }

    </>
  )
};