import { STORAGE_KEY } from "../../constant/common";
import React, { useEffect, useState } from "react";
import './sidenav.css';
import { Col, Container, Row } from "react-bootstrap";
import { NavLink, useLocation } from 'react-router-dom';
import dashboard from "../../Photo/dashboard.svg";
import { BiTransferAlt } from "react-icons/bi";
import Alt_Semen from '../../Photo/Alt_Semen.svg';
import car from '../../Photo/car.svg';
import AHP from '../../Photo/AHP.svg';
import VO_Management from '../../Photo/VO_Management.svg';
import Visit_Management from '../../Photo/Visit_Management.svg';
import overview from '../../Photo/overview.svg';
import User from "../../Photo/User.svg";
import setting from "../../Photo/setting.svg";
import projectIcon from "../../Photo/project.svg";
import masterimg from "../../Photo/master.svg";
import helath from "../../Photo/helath.svg";
import medicines from "../../Photo/medicines.svg";
import lab_microscope from "../../Photo/lab_microscope.svg";
import inverment from "../../Photo/inverment.svg";
import Feed_Management from "../../Photo/Feed_Management.svg"
import Cattle_Management from "../../Photo/Cattle_Management.svg"
import Listing from "../../Photo/Listing.svg";
import milk from "../../Photo/milk.svg";
import right from '../../Photo/right.svg';
import down from '../../Photo/down.svg';
import transfer1 from '../../Photo/transfer.svg';
import vaccine from '../../Photo/vaccine.svg';
import Blood_Press from '../../Photo/Blood_Press.svg';
import sync from '../../Photo/sync.svg';
import Docter from '../../Photo/Docter.svg';
import map from '../../Photo/map.svg';
import virus from '../../Photo/virus.svg';
import { API_URLS } from "../../API/Api";
import { TbReport } from "react-icons/tb";
import { MdOutlineQrCodeScanner } from "react-icons/md";
const { UserManagApi } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function getUserData(credentials) {
    return fetch(UserManagApi.GetDetail, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}


const Sidenav = () => {
    const [userGet, setUserGet] = useState([])
    const GROUP = window.localStorage.getItem("GROUP")

    const fetchData = async () => {
        const res = await getUserData()
        if (res) { setUserGet(res.data); }
    }

    const location = useLocation();
    const userName = localStorage.getItem("USERNAME")
    // const userGroup = localStorage.getItem("userGroup")
    const userGroup = localStorage.getItem("GROUP")

    const [cattle, setCattle] = useState(false);
    const allowStatic = () => setCattle(true);
    const ontrading = () => setCattle(!cattle);

    const [listing, setListing] = useState(false);
    const onListing = () => setListing(true);
    const allowListing = () => setListing(!listing);

    const [milkUnion, setMilkUnion] = useState(false);
    const onMilkUnion = () => setMilkUnion(true);
    const allowMilkUnion = () => setMilkUnion(!milkUnion);

    const [health, setHealth] = useState(false);
    const onHealth = () => setHealth(true);
    const allowHealth = () => setHealth(!health);

    const [master, setMaster] = useState(false);
    const onMaster = () => setMaster(true);
    const allowMaster = () => setMaster(!master);

    const [user, setUser] = useState(false);
    const onUser = () => setUser(true);
    const allowUser = () => setUser(!user);

    const [transfer, setTransfer] = useState(false);
    const onTransfer = () => setTransfer(true);
    const allowTransfer = () => setTransfer(!transfer);

    const [salvage, setSalvage] = useState(false);
    const onSalvage = () => setSalvage(true);
    const allowSalvage = () => setSalvage(!salvage);

    const [straw, setStraw] = useState(false);
    const onStraw = () => setStraw(true);
    const allowStraw = () => setStraw(!straw);

    const [subStraw, setSubStraw] = useState(false);
    const onSubStraw = () => setSubStraw(true);
    const allowSubStraw = () => setSubStraw(!subStraw);

    const [medicine, setMedicine] = useState(false);
    const onMedicine = () => setMedicine(true);
    const allowMedicine = () => setMedicine(!medicine);

    const [lab, setLab] = useState(false);
    const onLab = () => setLab(true);
    const allowLab = () => setLab(!lab);

    const [ahc, setAhc] = useState(false);
    const onAhc = () => setAhc(true);
    const allowHsc = () => setAhc(!ahc);

    // DscWise
    const [mpp, setMpp] = useState(false);
    const onMpp = () => setMpp(true);
    const allowMpp = () => setMpp(!mpp);

    const [project, setProject] = useState(false);
    const onProject = () => setProject(true);
    const allowProject = () => setProject(!project);

    const [camp, setCamp] = useState(false);
    const onCamp = () => setCamp(true);
    const allowCamp = () => setCamp(!camp);

    const [milkEntry, setMilkEntry] = useState(false);
    const onMilkEntry = () => setMilkEntry(true);
    const allowMilkEntry = () => setMilkEntry(!camp);

    const [fip, setFip] = useState(false);
    const onFip = () => setFip(true);
    const allowFip = () => setFip(!fip);

    const [report, setReport] = useState(false);
    const onreport = () => setReport(true);
    const allowReport = () => setReport(!report);

    const [visitManagement, setVisitManagement] = useState(false);
    const onVisitManagement = () => setVisitManagement(true);
    const allowVisitManagement = () => setVisitManagement(!visitManagement);

    useEffect(() => {
        fetchData()
        if (location.pathname == "/Cattle-Registration" || location.pathname == "/BreedingDetails" || location.pathname == "/IndividualEntry" || location.pathname == "/Deworming" || location.pathname == "/Reproduction" ||
            location.pathname == "/Production" || location.pathname == "/Treatment" || location.pathname == "/Vaccination" || location.pathname == "/Weight") {
            allowStatic();
        }
        if (location.pathname == "/Alarm" || location.pathname == "/DailyActionPlan") {
            onListing();
        }
        if (location.pathname == "/AnimalWise" || location.pathname == "/HerdWise" || location.pathname == "/Datewise" || location.pathname == "/CustomReport") {
            onMilkUnion();
        }

        if (location.pathname == "/SystemAffected" || location.pathname == "/Diagnosis" || location.pathname == "/FarmerComplaint") {
            onHealth();
        }
        if (location.pathname == "/RouteHerd" || location.pathname == "/CcCenter" || location.pathname == "/ZoneSubCenter" || location.pathname == "/SocietyLot" ||
            location.pathname == "/OwnerFarmer" || location.pathname == "/Project" || location.pathname == "/Sire" || location.pathname == "/Staff" ||
            location.pathname == "/Insurance" || location.pathname == "/Source" || location.pathname == "/ClafSchedule" || location.pathname == "/SmsSetting" ||
            location.pathname == "/MedicineRoute" || location.pathname == "/DewormingType" || location.pathname == "/VoPost" || location.pathname == "/VoCategory" ||
            location.pathname == "/Shift" || location.pathname == "/ShiftAllocation") {
            onMaster();
        }
        if (location.pathname == "/Userdetails" || location.pathname == "/UserWiseLoginDetails") {
            onUser();
        }
        if (location.pathname == "/Animal-Transfer" || location.pathname == "/Farmer-Transfer" || location.pathname == "/Village-Transfer" || location.pathname == "/Project-Transfer") {
            onTransfer();
        }
        if (location.pathname == "/Salvel-in" || location.pathname == "/Salvel-out" ) {
            onSalvage();
        }
        if (location.pathname == "/OpeningBalance" || location.pathname == "/StrawDistribution" || location.pathname == "/StrawDamage" || location.pathname == "/TransferStraw" ||
            location.pathname == "/StrawToSemen" || location.pathname == "/StrawToSupplier" || location.pathname == "/MissingStraw" || location.pathname == "/MannualEntry" ||
            location.pathname == "/CampUsage" || location.pathname == "/Recovery" || location.pathname == "/SupplierStock" || location.pathname == "/AITStock") {
            onStraw();
        }
        if (location.pathname == "/SemenSupplier" || location.pathname == "/SemenRequest") {
            onSubStraw();
        }
        if (location.pathname == "/Opening_Balance" || location.pathname == "/MedicineDistribution") {
            onMedicine();
        }

        if (location.pathname == "/Dcswisetest" || location.pathname == "/FarmerWise" || location.pathname == "/Animal-Wise" || location.pathname == "/CampMaster") {
            onProject();
            onMpp();
        }
        if (location.pathname == "/FipTretment" || location.pathname == "/Allocation" || location.pathname == "/Campmaster") {
            onProject();
            onFip();
            // onCamp();
        }
        if (location.pathname == "/TransferAnimal" || location.pathname == "/Implimentation" || location.pathname == "/Campplanning" || location.pathname == "/Vapawerness") {
            onProject();
        }
        if (location.pathname == "/MilkTest" || location.pathname == "/Bulk-Wise-MilkEntry" || location.pathname == "/MilkPdTest" || location.pathname == "/IndividualEntry" || location.pathname == "/SccMppReport") {
            onMilkEntry();
        }
        if (location.pathname == "/LabRegistration" || location.pathname == "/TestRequest") {
            onLab();
        }

        if (location.pathname == "/AhcReport") {
            onAhc();
        }
        if (location.pathname == "/Campreport" || location.pathname == "/TestRequest") {
            onreport();
        }
        if (location.pathname == "/Visit_Registration" || location.pathname == "/Visit_Reallocation_User" || location.pathname == "/AIT_Visit_Registration" || location.pathname == "/Visit_Reallocation" || location.pathname == "/Visit_Cancellation") {
            onVisitManagement();
        }
    }, []);

    const logoutHandler = () => {
        localStorage.removeItem("Fld");
        localStorage.removeItem("Parity");
    }

    return (
        <>
            <Container fluid>
                <Row className="text-center align-items-start justify-content-center">
                    <Col className="sidebar">
                        <div className="pt-4 mt-1 d-flex text-center align-items-center justify-content-center mLogo">
                            <Col xl="12"><h3 style={{ color: "black" }}>HERDMAN</h3></Col>
                        </div>
                        <Col>
                            <h6>{userName}</h6>
                        </Col>
                        {
                            GROUP != "35" ?
                                userName == "Pmmanger" ?
                                    <>
                                        <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowProject()}>
                                            <Col xl="1" xs="3">
                                                <div className="icons text-center">
                                                    <img src={projectIcon} />
                                                </div>
                                            </Col>
                                            <Col className="text-start">
                                                <div className="link_text">Project</div>
                                            </Col>
                                            <Col xs="1" className="mainArrow">
                                                <img src={project == true ? down : right} className="link_text" />
                                            </Col>
                                        </div>
                                        {project && (
                                            <div>
                                                <hr className="m-0" />
                                                <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowMpp()}>
                                                    <Col xl="1" xs="3">
                                                        {/* <div className="icons text-center">
                                                <img src={virus} />
                                            </div> */}
                                                    </Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">MPP</div>
                                                    </Col>
                                                    <Col xs="1" className="mainArrow">
                                                        <img src={mpp == true ? down : right} className="link_text" />
                                                    </Col>
                                                </div>
                                                {mpp && (
                                                    <div className="mx-2">
                                                        <hr className="m-0" />
                                                        <NavLink to="/CampMaster" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                            <Col xl="1" lg="3" xs="3"></Col>
                                                            <Col className="text-start">
                                                                <div className="link_text">Camp Master</div>
                                                            </Col>
                                                        </NavLink>
                                                        <NavLink to="/Dcswisetest" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                            <Col xl="1" lg="3" xs="3"></Col>
                                                            <Col className="text-start">
                                                                <div className="link_text">Dsc Wise Test</div>
                                                            </Col>
                                                        </NavLink>
                                                        <NavLink to="/FarmerWise" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                            <Col xl="1" lg="3" xs="3"></Col>
                                                            <Col className="text-start">
                                                                <div className="link_text">Farmer Wise Test</div>
                                                            </Col>
                                                        </NavLink>
                                                        <NavLink to="/Animal-Wise" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                            <Col xl="1" lg="3" xs="3"></Col>
                                                            <Col className="text-start">
                                                                <div className="link_text">Animal Wise Test</div>
                                                            </Col>
                                                        </NavLink>
                                                        <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowReport()}>
                                                            <Col xl="1" xs="3">                                                        </Col>
                                                            <Col className="text-start">
                                                                <div className="link_text">Reports</div>
                                                            </Col>
                                                            <Col xs="1" className="mainArrow">
                                                                <img src={report == true ? down : right} className="link_text" />
                                                            </Col>
                                                        </div>
                                                        {report && (
                                                            <div className="mx-2">
                                                                <hr className="m-0" />
                                                                <NavLink to="/Campreport" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                                    <Col className="text-start">
                                                                        <div className="link_text">Camp Report</div>
                                                                    </Col>
                                                                </NavLink>
                                                                <NavLink to="/Animalreport" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                                    <Col className="text-start">
                                                                        <div className="link_text">Animal wise Reports</div>
                                                                    </Col>
                                                                </NavLink>
                                                                <NavLink to="/Societyreport" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                                    <Col className="text-start">
                                                                        <div className="link_text">Society wise Reports</div>
                                                                    </Col>
                                                                </NavLink>
                                                                <hr className="m-0" />
                                                            </div>
                                                        )}
                                                        <hr className="m-0" />
                                                    </div>
                                                )}

                                                <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowFip()}>
                                                    <Col xl="1" xs="3">
                                                        {/* <div className="icons text-center">
                                                <img src={virus} />
                                            </div> */}
                                                    </Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">FIP</div>
                                                    </Col>
                                                    <Col xs="1" className="mainArrow">
                                                        <img src={fip == true ? down : right} className="link_text" />
                                                    </Col>
                                                </div>
                                                {fip && (
                                                    <div className="mx-2">
                                                        <hr className="m-0" />
                                                        {/* <NavLink to="/Allocation" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                <Col xl="1" lg="3" xs="3"></Col>
                                                <Col className="text-start">
                                                    <div className="link_text">Camp Treatment</div>
                                                </Col>
                                            </NavLink> */}
                                                        <NavLink to="/Allocation" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                            <Col xl="1" lg="3" xs="3"></Col>
                                                            <Col className="text-start">
                                                                <div className="link_text">Camp Allocation</div>
                                                            </Col>
                                                        </NavLink>
                                                        <NavLink to="/FipTretment" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                            <Col xl="1" lg="3" xs="3"></Col>
                                                            <Col className="text-start">
                                                                <div className="link_text">FIP Tretment</div>
                                                            </Col>
                                                        </NavLink>
                                                        <hr className="m-0" />
                                                    </div>
                                                )}
                                                {/* <NavLink to="/Allocation" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                        <Col xl="1" lg="3" xs="3"></Col>
                                        <Col className="text-start">
                                            <div className="link_text">Allocation</div>
                                        </Col>
                                    </NavLink> */}
                                                {/* <NavLink to="/Vapawerness" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                        <Col xl="1" lg="3" xs="3"></Col>
                                        <Col className="text-start">
                                            <div className="link_text">VAP Awerness</div>
                                        </Col>
                                    </NavLink> */}
                                                {/* <NavLink to="/Campplanning" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                        <Col xl="1" lg="3" xs="3"></Col>
                                        <Col className="text-start">
                                            <div className="link_text">Camp Planning</div>
                                        </Col>
                                    </NavLink> */}
                                                <NavLink to="/TransferAnimal" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Transfer Animal</div>
                                                    </Col>
                                                </NavLink>

                                            </div>
                                        )}
                                    </> :
                                    <div className="mt-4">
                                        {(userGroup == 1 || userGroup == 2) &&
                                            <NavLink to="/AllMilkUnion" className="link mt-2 d-flex text-center align-items-center justify-content-center" activeclassName="active">
                                                <Col xl="1" lg="3" xs="3">
                                                    <div className="icons text-center">
                                                        <img src={dashboard} />
                                                    </div>
                                                </Col>
                                                <Col className="text-start"><div className="link_text">All Milk Union</div></Col>
                                            </NavLink>
                                        }
                                        <NavLink to="/Overview" className="link d-flex text-center align-items-center justify-content-center" activeclassName="active">
                                            <Col xl="1" lg="3" xs="3">
                                                <div className="icons text-center">
                                                    <img src={overview} />
                                                </div>
                                            </Col>
                                            <Col className="text-start"><div className="link_text">Overview</div></Col>
                                        </NavLink>
                                        <NavLink to="/AITStock" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                            <Col xl="1" lg="3" xs="3">
                                                <div className="icons text-center" style={{ color: '#696969', fontSize: '19px' }}>
                                                    <BiTransferAlt />
                                                </div>
                                            </Col>
                                            <Col className="text-start">
                                                <div className="link_text">AIT Stock</div>
                                            </Col>
                                        </NavLink>
                                        <NavLink to="/AnimalHistory" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                            <Col xl="1" lg="3" xs="3">
                                                <div className="icons text-center" style={{ color: '#696969', fontSize: '19px' }}>
                                                    <BiTransferAlt />
                                                </div>
                                            </Col>
                                            <Col className="text-start">
                                                <div className="link_text">Animal History</div>
                                            </Col>
                                        </NavLink>

                                        {(userGroup == 1 || userGroup == 2) &&
                                            <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowUser()}>
                                                <Col xl="1" xs="3">
                                                    <div className="icons text-center">
                                                        <img src={User} />
                                                    </div>
                                                </Col>
                                                <Col className="text-start">
                                                    <div className="link_text">User Management</div>
                                                </Col>
                                                <Col xs="1" className="mainArrow">
                                                    <img src={user == true ? down : right} className="link_text" />
                                                </Col>
                                            </div>
                                        }

                                        {user && (
                                            <div>
                                                <hr className="m-0" />
                                                <NavLink to="/Userdetails" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">User Details</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/UserWiseLoginDetails" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">User Wise Login Details</div>
                                                    </Col>
                                                </NavLink>
                                            </div>
                                        )}
                                        <NavLink to="/" className="link d-flex text-center align-items-center justify-content-center" activeclassName="active">
                                            <Col xl="1" lg="3" xs="3">
                                                <div className="icons text-center">
                                                    <img src={setting} />
                                                </div>
                                            </Col>
                                            <Col className="text-start"><div className="link_text">Setting</div></Col>
                                        </NavLink>
                                        <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowProject()}>
                                            <Col xl="1" xs="3">
                                                <div className="icons text-center">
                                                    <img src={projectIcon} />
                                                </div>
                                            </Col>
                                            <Col className="text-start">
                                                <div className="link_text">Project</div>
                                            </Col>
                                            <Col xs="1" className="mainArrow">
                                                <img src={project == true ? down : right} className="link_text" />
                                            </Col>
                                        </div>
                                        {project && (
                                            <div>
                                                <hr className="m-0" />
                                                <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowMpp()}>
                                                    <Col xl="1" xs="3">
                                                        {/* <div className="icons text-center">
                                                <img src={virus} />
                                            </div> */}
                                                    </Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">MPP</div>
                                                    </Col>
                                                    <Col xs="1" className="mainArrow">
                                                        <img src={mpp == true ? down : right} className="link_text" />
                                                    </Col>
                                                </div>
                                                {mpp && (
                                                    <div className="mx-2">
                                                        <hr className="m-0" />
                                                        <NavLink to="/CampMaster" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                            <Col xl="1" lg="3" xs="3"></Col>
                                                            <Col className="text-start">
                                                                <div className="link_text">Camp Master</div>
                                                            </Col>
                                                        </NavLink>
                                                        <NavLink to="/Dcswisetest" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                            <Col xl="1" lg="3" xs="3"></Col>
                                                            <Col className="text-start">
                                                                <div className="link_text">Dsc Wise Test</div>
                                                            </Col>
                                                        </NavLink>
                                                        <NavLink to="/FarmerWise" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                            <Col xl="1" lg="3" xs="3"></Col>
                                                            <Col className="text-start">
                                                                <div className="link_text">Farmer Wise Test</div>
                                                            </Col>
                                                        </NavLink>
                                                        <NavLink to="/Animal-Wise" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                            <Col xl="1" lg="3" xs="3"></Col>
                                                            <Col className="text-start">
                                                                <div className="link_text">Animal Wise Test</div>
                                                            </Col>
                                                        </NavLink>
                                                        <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowReport()}>
                                                            <Col xl="1" xs="3">                                                        </Col>
                                                            <Col className="text-start">
                                                                <div className="link_text">Reports</div>
                                                            </Col>
                                                            <Col xs="1" className="mainArrow">
                                                                <img src={report == true ? down : right} className="link_text" />
                                                            </Col>
                                                        </div>
                                                        {report && (
                                                            <div className="mx-2">
                                                                <hr className="m-0" />
                                                                <NavLink to="/Campreport" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                                    <Col className="text-start">
                                                                        <div className="link_text">Camp Report</div>
                                                                    </Col>
                                                                </NavLink>
                                                                <NavLink to="/Animalreport" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                                    <Col className="text-start">
                                                                        <div className="link_text">Animal wise Reports</div>
                                                                    </Col>
                                                                </NavLink>
                                                                <NavLink to="/Societyreport" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                                    <Col className="text-start">
                                                                        <div className="link_text">Society wise Reports</div>
                                                                    </Col>
                                                                </NavLink>
                                                                <hr className="m-0" />
                                                            </div>
                                                        )}
                                                        <hr className="m-0" />
                                                    </div>
                                                )}

                                                <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowFip()}>
                                                    <Col xl="1" xs="3">
                                                        {/* <div className="icons text-center">
                                                <img src={virus} />
                                            </div> */}
                                                    </Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">FIP</div>
                                                    </Col>
                                                    <Col xs="1" className="mainArrow">
                                                        <img src={fip == true ? down : right} className="link_text" />
                                                    </Col>
                                                </div>
                                                {fip && (
                                                    <div className="mx-2">
                                                        <hr className="m-0" />
                                                        {/* <NavLink to="/Allocation" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                <Col xl="1" lg="3" xs="3"></Col>
                                                <Col className="text-start">
                                                    <div className="link_text">Camp Treatment</div>
                                                </Col>
                                            </NavLink> */}
                                                        <NavLink to="/Allocation" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                            <Col xl="1" lg="3" xs="3"></Col>
                                                            <Col className="text-start">
                                                                <div className="link_text">Camp Allocation</div>
                                                            </Col>
                                                        </NavLink>
                                                        <NavLink to="/FipTretment" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                            <Col xl="1" lg="3" xs="3"></Col>
                                                            <Col className="text-start">
                                                                <div className="link_text">FIP Tretment</div>
                                                            </Col>
                                                        </NavLink>
                                                        <hr className="m-0" />
                                                    </div>
                                                )}
                                                {/* <NavLink to="/Allocation" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                        <Col xl="1" lg="3" xs="3"></Col>
                                        <Col className="text-start">
                                            <div className="link_text">Allocation</div>
                                        </Col>
                                    </NavLink> */}
                                                {/* <NavLink to="/Vapawerness" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                        <Col xl="1" lg="3" xs="3"></Col>
                                        <Col className="text-start">
                                            <div className="link_text">VAP Awerness</div>
                                        </Col>
                                    </NavLink> */}
                                                {/* <NavLink to="/Campplanning" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                        <Col xl="1" lg="3" xs="3"></Col>
                                        <Col className="text-start">
                                            <div className="link_text">Camp Planning</div>
                                        </Col>
                                    </NavLink> */}
                                                <NavLink to="/TransferAnimal" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Transfer Animal</div>
                                                    </Col>
                                                </NavLink>

                                            </div>
                                        )}
                                        <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowMaster()}>
                                            <Col xl="1" xs="3">
                                                <div className="icons text-center">
                                                    <img src={masterimg} />
                                                </div>
                                            </Col>
                                            <Col className="text-start">
                                                <div className="link_text">Master</div>
                                            </Col>
                                            <Col xl="1" xs="1" className="mainArrow">
                                                <img src={master == true ? down : right} className="link_text" />
                                            </Col>
                                        </div>
                                        {master && (
                                            <div>
                                                <hr className="m-0" />
                                                <NavLink to="/CcCenter" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Cc Center</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/ZoneSubCenter" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Zone SubCenter</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/RouteHerd" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Router Herd</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/SocietyLot" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active" >
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Society Lot</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/OwnerFarmer" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Owner Farmer</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/Project" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Project</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/Sire" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Sire</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/Staff" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Staff</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/Insurance" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Insurance Company</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/Source" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Source</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/ClafSchedule" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Claf Schedule</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/SmsSetting" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Sms Setting</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/MedicineRoute" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Medicine Route</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/DewormingType" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Deworming Type</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/VoPost" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Vo Post</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/VoCategory" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Vo Category</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/Shift" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Shift</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/ShiftAllocation" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Shift Allocation</div>
                                                    </Col>
                                                </NavLink>
                                                <hr className="m-0" />
                                            </div>
                                        )}

                                        <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowHealth()}>
                                            <Col xl="1" xs="3">
                                                <div className="icons text-center">
                                                    <img src={helath} />
                                                </div>
                                            </Col>
                                            <Col className="text-start">
                                                <div className="link_text">Health</div>
                                            </Col>
                                            <Col xs="1" xl="1" className="mainArrow">
                                                <img src={health == true ? down : right} className="link_text" />
                                            </Col>
                                        </div>
                                        {health && (
                                            <div>
                                                <hr className="m-0" />
                                                <NavLink to="/SystemAffected" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">System Affected</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/Diagnosis" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Diagnosis</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/FarmerComplaint" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3   " xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Farmer Complaint</div>
                                                    </Col>
                                                </NavLink>
                                                <hr className="m-0" />
                                            </div>
                                        )}

                                        <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => ontrading()}>
                                            <Col xl="1" xs="3">
                                                <div className="icons text-center">
                                                    <img src={Cattle_Management} />
                                                </div>
                                            </Col>
                                            <Col className="text-start">
                                                <div className="link_text">Cattle Management</div>
                                            </Col>
                                            <Col xs="1" xl="1" className="mainArrow">
                                                <img src={cattle == true ? down : right} className="link_text" />
                                            </Col>
                                        </div>
                                        {cattle && (
                                            <div>
                                                <hr className="m-0" />
                                                <NavLink to="/Cattle-Registration" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Cattle Registration</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/BreedingDetails" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Breeding Details</div>
                                                    </Col>
                                                </NavLink>
                                                {/* <NavLink to="/Deworming" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                        <Col xl="1" lg="2" xs="3"></Col>
                                        <Col className="text-start">
                                            <div className="link_text">Deworming</div>
                                        </Col>
                                    </NavLink>
                                    <NavLink to="/Reproduction" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                        <Col xl="1" lg="2" xs="3"></Col>
                                        <Col className="text-start">
                                            <div className="link_text">Reproduction</div>
                                        </Col>
                                    </NavLink> */}
                                                <NavLink to="/IndividualEntry" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Individual Milk Entry</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/Treatment" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Treatment</div>
                                                    </Col>
                                                </NavLink>
                                                {/* <NavLink to="/Vaccination" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                        <Col xl="1" lg="2" xs="3"></Col>
                                        <Col className="text-start">
                                            <div className="link_text">Vaccination</div>
                                        </Col>
                                    </NavLink> */}
                                                <NavLink to="/Weight" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Weight Entry</div>
                                                    </Col>
                                                </NavLink>
                                                <hr className="m-0" />
                                            </div>
                                        )}
                                        <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowMilkEntry()}>
                                            <Col xl="1" xs="3">
                                                <div className="icons text-center">
                                                    <img src={Blood_Press} />
                                                </div>
                                            </Col>
                                            <Col className="text-start">
                                                <div className="link_text">Milk Entry</div>
                                            </Col>
                                            <Col xs="1" xl="1" className="mainArrow">
                                                <img src={milkEntry == true ? down : right} className="link_text" />
                                            </Col>
                                        </div>
                                        {milkEntry && (
                                            <div>
                                                <hr className="m-0" />
                                                <NavLink to="/MilkTest" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Official Milk Entry</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/IndividualEntry" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Individual Milk Entry</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/MilkPdTest" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Milk PD Test</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/Bulk-Wise-MilkEntry" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Bulk Wise MilkEntry</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/SccMppReport" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Scc Mpp Report</div>
                                                    </Col>
                                                </NavLink>
                                                <hr className="m-0" />
                                            </div>
                                        )}

                                        <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowListing()}>
                                            <Col xl="1" xs="3">
                                                <div className="icons text-center">
                                                    <img src={Listing} />
                                                </div>
                                            </Col>
                                            <Col className="text-start">
                                                <div className="link_text">Listing</div>
                                            </Col>
                                            <Col xs="1" xl="1" className="mainArrow">
                                                <img src={listing == true ? down : right} className="link_text" />
                                            </Col>
                                        </div>
                                        {listing && (
                                            <div>
                                                <hr className="m-0" />
                                                <NavLink to="/Alarm" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Alarm List</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/DailyActionPlan" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="2" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Daily Action Plan</div>
                                                    </Col>
                                                </NavLink>
                                                <hr className="m-0" />
                                            </div>
                                        )}

                                        <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowMilkUnion()}>
                                            <Col xl="1" xs="3">
                                                <div className="icons text-center">
                                                    <img src={milk} />
                                                </div>
                                            </Col>
                                            <Col className="text-start">
                                                <div className="link_text">Milk Union Reports</div>
                                            </Col>
                                            <Col xs="1" xl="1" className="mainArrow">
                                                <img src={milkUnion == true ? down : right} className="link_text" />
                                            </Col>
                                        </div>
                                        {milkUnion && (
                                            <div>
                                                <hr className="m-0" />
                                                {/* <NavLink to="/AnimalWise" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                        <Col xl="3" lg="3" xs="3"></Col>
                                        <Col className="text-start">
                                            <div className="link_text">Animal Wise</div>
                                        </Col>
                                    </NavLink>
                                    <NavLink to="/" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                        <Col xl="3" lg="2" xs="3"></Col>
                                        <Col className="text-start">
                                            <div className="link_text">Projection Report</div>
                                        </Col>
                                    </NavLink> */}
                                                <NavLink to="/HerdWise" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="2" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Herd-wise</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/Datewise" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="2" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Date-wise</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/CustomReport" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="2" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Custom Reports</div>
                                                    </Col>
                                                </NavLink>
                                                <hr className="m-0" />
                                            </div>

                                        )}

                                        <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowTransfer()}>
                                            <Col xl="1" xs="3">
                                                <div className="icons text-center">
                                                    <img src={transfer1} />
                                                </div>
                                            </Col>
                                            <Col className="text-start">
                                                <div className="link_text">Transfer</div>
                                            </Col>
                                            <Col xs="1" xl="1" className="mainArrow">
                                                <img src={transfer == true ? down : right} className="link_text" />
                                            </Col>
                                        </div>
                                        {transfer && (
                                            <div>
                                                <hr className="m-0" />
                                                <NavLink to="/Animal-Transfer" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Animal</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/Farmer-Transfer" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Farmer</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/Village-Transfer" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Village</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/Project-Transfer" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Project</div>
                                                    </Col>
                                                </NavLink>
                                            </div>
                                        )}
                                        <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowSalvage()}>
                                            <Col xl="1" xs="3">
                                                <div className="icons text-center">
                                                    <img src={transfer1} />
                                                </div>
                                            </Col>
                                            <Col className="text-start">
                                                <div className="link_text">Salvage</div>
                                            </Col>
                                            <Col xs="1" xl="1" className="mainArrow">
                                                <img src={transfer == true ? down : right} className="link_text" />
                                            </Col>
                                        </div>
                                        {salvage && (
                                            <div>
                                                <hr className="m-0" />
                                                <NavLink to="/Salvage-In" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Salvage-In</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/Salvage-Out" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Salvage-Out</div>
                                                    </Col>
                                                </NavLink>
                                                
                                            </div>
                                        )}
                                        <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowVisitManagement()}>
                                            <Col xl="1" xs="3">
                                                <div className="icons text-center">
                                                    <img src={transfer1} />
                                                </div>
                                            </Col>
                                            <Col className="text-start">
                                                <div className="link_text">Vo Management</div>
                                            </Col>
                                            <Col xs="1" xl="1" className="mainArrow">
                                                <img src={visitManagement == true ? down : right} className="link_text" />
                                            </Col>
                                        </div>
                                        {visitManagement && (
                                            <div>
                                                <hr className="m-0" />
                                                <NavLink to="/Visit_Registration" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Vo Visit Registration</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/AIT_Visit_Registration" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">AIT Visit Registration</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/Visit_Reallocation" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Visit Reallocation</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/Visit_Cancellation" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Visit Cancellation</div>
                                                    </Col>
                                                </NavLink>
                                            </div>
                                        )}
                                        {/* <NavLink to="/" className="link d-flex text-center align-items-center justify-content-center" activeclassName="active">
                                <Col xl="3" lg="3" xs="3">
                                    <div className="icons text-center mt-1">
                                        <img src={VO_Management} />
                                    </div>
                                </Col>
                                <Col className="text-start"><div className="link_text">VO Management</div></Col>
                            </NavLink> */}
                                        {/* <NavLink to="/" className="link d-flex text-center align-items-center justify-content-center" activeclassName="active">
                                <Col xl="3" lg="3" xs="3">
                                    <div className="icons text-center mt-1">
                                        <img src={Visit_Management} />
                                    </div>
                                </Col>
                                <Col className="text-start"><div className="link_text">Visit Management</div></Col>
                            </NavLink> */}

                                        <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowStraw()}>
                                            <Col xl="1" xs="3">
                                                <div className="icons text-center">
                                                    <img src={vaccine} />
                                                </div>
                                            </Col>
                                            <Col className="text-start">
                                                <div className="link_text">Straw Management</div>
                                            </Col>
                                            <Col xs="1" xl="1" className="mainArrow">
                                                <img src={straw == true ? down : right} className="link_text" />
                                            </Col>
                                        </div>
                                        {straw && (
                                            <div>
                                                <hr className="m-0" />
                                                <NavLink to="/OpeningBalance" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Opening Balance</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/StrawDistribution" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Straw Distribution</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/StrawDamage" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Straw Damage</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/TransferStraw" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Transfer Straw</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/StrawToSemen" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Return Straw To Semen Center</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/StrawToSupplier" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Return Straw To Supplier</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/MissingStraw" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Missing Straw</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/MannualEntry" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Mannual Entry</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/CampUsage" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Camp Usage</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/Recovery" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Recovery</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/SupplierStock" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Supplier Stock</div>
                                                    </Col>
                                                </NavLink>


                                                <div className="link d-flex text-center align-items-center justify-content-evenly" activeclassName="active" onClick={() => allowSubStraw()}>
                                                    <Col className="text-start">
                                                        <div className="link_text">Semen Supplier</div>
                                                    </Col>
                                                    <Col xs="1" xl="1" className="mainArrow">
                                                        <img src={subStraw == true ? down : right} className="link_text" />
                                                    </Col>
                                                </div>
                                                {subStraw && (
                                                    <div>
                                                        <hr className="m-0" />
                                                        <NavLink to="/SemenSupplier" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                            <Col xl="1" lg="3" xs="3"></Col>
                                                            <Col className="text-start">
                                                                <div className="link_text">Semen Supplier</div>
                                                            </Col>
                                                        </NavLink>
                                                        <NavLink to="/SemenRequest" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                            <Col xl="1" lg="3" xs="3"></Col>
                                                            <Col className="text-start">
                                                                <div className="link_text">AIT Semen Request</div>
                                                            </Col>
                                                        </NavLink>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowMedicine()}>
                                            <Col xl="1" xs="3">
                                                <div className="icons text-center">
                                                    <img src={medicines} />
                                                </div>
                                            </Col>
                                            <Col className="text-start">
                                                <div className="link_text">Medicine Management</div>
                                            </Col>
                                            <Col xs="1" xl="1" className="mainArrow">
                                                <img src={medicine == true ? down : right} className="link_text" />
                                            </Col>
                                        </div>
                                        {medicine && (
                                            <div>
                                                <hr className="m-0" />
                                                <NavLink to="/Opening_Balance" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Opening Balance</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/MedicineDistribution" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Medicine Distribution</div>
                                                    </Col>
                                                </NavLink>
                                                <hr className="m-0" />
                                            </div>
                                        )}

                                        <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowLab()}>
                                            <Col xl="1" xs="3">
                                                <div className="icons text-center">
                                                    <img src={lab_microscope} />
                                                </div>
                                            </Col>
                                            <Col className="text-start">
                                                <div className="link_text">Lab Management</div>
                                            </Col>
                                            <Col xs="1" xl="1" className="mainArrow">
                                                <img src={lab == true ? down : right} className="link_text" />
                                            </Col>
                                        </div>
                                        {lab && (
                                            <div>
                                                <hr className="m-0" />
                                                <NavLink to="/LabRegistration" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Registration</div>
                                                    </Col>
                                                </NavLink>
                                                <NavLink to="/TestRequest" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">Test Request Master</div>
                                                    </Col>
                                                </NavLink>
                                                <hr className="m-0" />
                                            </div>
                                        )}

                                        <NavLink to="/Inventory" className="link d-flex text-center align-items-center justify-content-center" activeclassName="active">
                                            <Col xl="1" lg="3" xs="3">
                                                <div className="icons text-center mt-1">
                                                    <img src={inverment} />
                                                </div>
                                            </Col>
                                            <Col className="text-start"><div className="link_text">Inventory Management</div></Col>
                                        </NavLink>
                                        <NavLink to="/FeedManagement" className="link d-flex text-center align-items-center justify-content-center" activeclassName="active">
                                            <Col xl="1" lg="3" xs="3">
                                                <div className="icons text-center mt-1">
                                                    <img src={Feed_Management} />
                                                </div>
                                            </Col>
                                            <Col className="text-start"><div className="link_text">Feed Management</div></Col>
                                        </NavLink>
                                        <NavLink to="/Synclog" className="link d-flex text-center align-items-center justify-content-center" activeclassName="active">
                                            <Col xl="1" lg="3" xs="3">
                                                <div className="icons text-center mt-1">
                                                    <img src={sync} />
                                                </div>
                                            </Col>
                                            <Col className="text-start"><div className="link_text">Sync Log</div></Col>
                                        </NavLink>
                                        <NavLink to="/Doctortrack" className="link d-flex text-center align-items-center justify-content-center" activeclassName="active">
                                            <Col xl="1" lg="3" xs="3">
                                                <div className="icons text-center mt-1">
                                                    <img src={Docter} />
                                                </div>
                                            </Col>
                                            <Col className="text-start"><div className="link_text">Doctor Track</div></Col>
                                        </NavLink>
                                        <NavLink to="/MapAnalytics" className="link d-flex text-center align-items-center justify-content-center" activeclassName="active">
                                            <Col xl="1" lg="3" xs="3">
                                                <div className="icons text-center mt-1">
                                                    <img src={map} />
                                                </div>
                                            </Col>
                                            <Col className="text-start"><div className="link_text">Map Analytics </div></Col>
                                        </NavLink>
                                        <NavLink to="/AnimalQr" className="link d-flex text-center align-items-center justify-content-center" activeclassName="active">
                                            <Col xl="1" lg="3" xs="3">
                                                <div className="icons text-center mt-1">
                                                    {/* <img src={map} /> */}
                                                    <MdOutlineQrCodeScanner className="fs-5" color="#696e6a" />

                                                </div>
                                            </Col>
                                            <Col className="text-start"><div className="link_text">Animal Qr </div></Col>
                                        </NavLink>
                                        <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowHsc()}>
                                            <Col xl="1" xs="3">
                                                <div className="icons text-center">
                                                    {/* <img src="" /> */}
                                                    <TbReport className="fs-5" color="#696e6a" />
                                                </div>
                                            </Col>
                                            <Col className="text-start">
                                                <div className="link_text">AHC Reports</div>
                                            </Col>
                                            <Col xs="1" xl="1" className="mainArrow">
                                                <img src={ahc == true ? down : right} className="link_text" />
                                            </Col>
                                        </div>
                                        {ahc && (
                                            <div>
                                                <hr className="m-0" />
                                                <NavLink to="/AhcReport" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                    <Col xl="1" lg="3" xs="3"></Col>
                                                    <Col className="text-start">
                                                        <div className="link_text">AHC Report</div>
                                                    </Col>
                                                </NavLink>
                                                <hr className="m-0" />
                                            </div>
                                        )}
                                        <NavLink to="/" className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={logoutHandler}>
                                            <Col xl="1" lg="3" xs="3">
                                                <div className="icons text-center mt-1">
                                                    <img src={sync} />
                                                </div>
                                            </Col>
                                            <Col className="text-start"><div className="link_text">Log Out</div></Col>
                                        </NavLink>
                                        {/* <NavLink to="/" className="link d-flex text-center align-items-center justify-content-center" activeclassName="active">
                                <Col xl="3" lg="3" xs="3">
                                    <div className="icons text-center mt-1">
                                        <img src={virus} />
                                    </div>
                                </Col>
                                <Col className="text-start"><div className="link_text">MPP</div></Col>
                            </NavLink> */}
                                    </div>
                                :
                                <>
                                    <div className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={() => allowVisitManagement()}>
                                        <Col xl="1" xs="3">
                                            <div className="icons text-center">
                                                <img src={transfer1} />
                                            </div>
                                        </Col>
                                        <Col className="text-start">
                                            <div className="link_text">Vo Management</div>
                                        </Col>
                                        <Col xs="1" xl="1" className="mainArrow">
                                            <img src={visitManagement == true ? down : right} className="link_text" />
                                        </Col>
                                    </div>
                                    {visitManagement && (
                                        <div>
                                            <hr className="m-0" />
                                            {/* <NavLink to="/Visit_Registration" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                <Col xl="1" lg="3" xs="3"></Col>
                                                <Col className="text-start">
                                                    <div className="link_text">Vo Visit Registration</div>
                                                </Col>
                                            </NavLink>
                                            <NavLink to="/AIT_Visit_Registration" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                <Col xl="1" lg="3" xs="3"></Col>
                                                <Col className="text-start">
                                                    <div className="link_text">AIT Visit Registration</div>
                                                </Col>
                                            </NavLink> */}
                                            <NavLink to="/Visit_Reallocation_User" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                <Col xl="1" lg="3" xs="3"></Col>
                                                <Col className="text-start">
                                                    <div className="link_text">Visit Reallocation</div>
                                                </Col>
                                            </NavLink>
                                            {/* <NavLink to="/Visit_Cancellation" className="link_sub d-flex text-end align-items-center justify-content-between" activeclassName="active">
                                                <Col xl="1" lg="3" xs="3"></Col>
                                                <Col className="text-start">
                                                    <div className="link_text">Visit Cancellation</div>
                                                </Col>
                                            </NavLink> */}
                                        </div>
                                    )}
                                    <NavLink to="/" className="link d-flex text-center align-items-center justify-content-center" activeclassName="active" onClick={logoutHandler}>
                                        <Col xl="1" lg="3" xs="3">
                                            <div className="icons text-center mt-1">
                                                <img src={sync} />
                                            </div>
                                        </Col>
                                        <Col className="text-start"><div className="link_text">Log Out</div></Col>
                                    </NavLink>

                                </>
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
};

export default Sidenav;