import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Offcanvas } from 'react-bootstrap'
import Sidenav from '../../Componet/Sidenav/Sidenav'
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg'
import Input from '../../Componet/InputFild/Input'
import Table from '../../Componet/DataTable/Table'
import { MdOutlineVpnKey } from 'react-icons/md'
import { GoSignOut } from 'react-icons/go'
import Select from 'react-select'
import './individualMilk.css'
import { STORAGE_KEY } from '../../constant/common'
import { API_URLS, serviceUrl } from '../../API/Api'
import { BiChevronsRight } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import { downloadExcel } from 'react-export-table-to-excel'
import * as XLSX from 'xlsx/xlsx'

import 'react-toastify/dist/ReactToastify.css'
import BackBtn from '../../Componet/Button/BackBtn'
import { ContactSupportOutlined } from '@mui/icons-material'
const {
  TransferAnimal,
  SocietyApi,
  FarmerApi,
  Cattleregistration,
  MilkTestApi
} = API_URLS

const IndividualMilk = () => {
  const API_KEY = serviceUrl
  // const API_KEY = "https://hais.hap.in";
  const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN)
  const TagId = localStorage.getItem('Fld')
  const Parity = localStorage.getItem(STORAGE_KEY.Parity)
  const navigate = useNavigate()

  async function getRouteData(credentials) {
    return fetch(TransferAnimal.GetVisitRoute, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }
  async function getSocietyData(credentials) {
    return fetch(SocietyApi.GetDetails, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }
  async function getFarmerData(credentials) {
    return fetch(FarmerApi.GetDetails, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }
  async function getTreeData(credentials) {
    return fetch(Cattleregistration.TreeByArea, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }
  async function getParitywiseData(credentials) {
    return fetch(MilkTestApi.getParityWiseData, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json())
  }

  const selectedToDate = new Date().toISOString().slice(0, 10)

  const [search, setSearch] = useState(TagId)
  const [searchFilter, setSearchFilter] = useState('')
  const [progressData, setProgressData] = useState('0%')
  const [route, setRoute] = useState('')
  const [society, setSociety] = useState('')
  const [farmer, setFarmer] = useState('')
  const [getData, setGetData] = useState([])
  const [searchId, setSearchId] = useState('')
  const [Lactos, setLactos] = useState(Parity)
  const [tagId, setTagId] = useState('')
  const [parity, setParity] = useState(Parity)

  const [show, setShow] = useState(false)
  const [heightOffSet, setHeight] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [headerShow, setHeaderShow] = useState(true)
  const handleClose = () => {
    setShow(false)
  }
  const [active, setActive] = useState(false)
  const [importFile, setImportFile] = useState(false)

  const handleShow = () => {
    setHeaderShow(!headerShow)
  }

  const [productionList, setProductionList] = useState([])
  const [routeList, setRouteList] = useState([])
  const [societyList, setSocietyList] = useState([])
  const [farmerList, setFarmerList] = useState([])
  const [tagIdList, setTagIdList] = useState([])
  const [parityWiseData, setParityWiseData] = useState([])
  const [excelData, setExcelData] = useState([])

  const [selectedLacno, setSelectedLacno] = useState(null)

  const [fileName, setFileName] = useState('Import')

  const fetchData = async () => {
    const res1 = await getRouteData()
    const res2 = await getSocietyData()
    const res3 = await getFarmerData()

    if (res1) {
      setRouteList(res1)
    }
    if (res2) {
      setSocietyList(res2.data)
    }
    if (res3) {
      setFarmerList(res3.data)
    }
  }

  const animalDataGet = async () => {
    await fetch(Cattleregistration.AnimalGet, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify({ tagId: tagId })
    })
      .then(response => {
        const contentLength = response.headers.get('content-length')
        let loaded = 0
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader()
              read()
              function read() {
                reader.read().then(progressEvent => {
                  if (progressEvent.done) {
                    controller.close()
                    return
                  }
                  loaded += progressEvent.value.byteLength
                  const percentageComplete =
                    Math.round((loaded / contentLength) * 100) + '%'
                  setProgressData(percentageComplete)
                  controller.enqueue(progressEvent.value)
                  read()
                })
              }
            }
          })
        )
      })
      .then(res => res.json())
      .then(result => {
        setParity(result[14]['Fld'])
        setIsLoading(false)
        setProgressData('0%')
      })
  }

  const view = async e => {
    setFileName('Import')
    setLactos(e.Lacno)
    setIsLoading(true)
    await fetch(`${API_KEY}/api/sp/Milk/Displaymilk`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify({
        IDNO: tagId,
        Parity: e.Lacno ? e.Lacno : Lactos ? Lactos : parity
      })
    })
      .then(response => {
        const contentLength = response.headers.get('content-length')
        let loaded = 0
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader()
              read()
              function read() {
                reader.read().then(progressEvent => {
                  if (progressEvent.done) {
                    controller.close()
                    return
                  }
                  loaded += progressEvent.value.byteLength
                  const percentageComplete =
                    Math.round((loaded / contentLength) * 100) + '%'
                  setProgressData(percentageComplete)
                  controller.enqueue(progressEvent.value)
                  read()
                })
              }
            }
          })
        )
      })
      .then(res => res.json())
      .then(result => {
        setProductionList(result)
        setIsLoading(false)
        setProgressData('0%')
      })
      .catch(error => {
        setIsLoading(false)
        toast.error('Please Enter Valid Parity')
      })

    await fetch(`${MilkTestApi.getParityWiseData}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify({ detailsId: tagId })
    })
      .then(response => {
        const contentLength = response.headers.get('content-length')
        let loaded = 0
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader()
              read()
              function read() {
                reader.read().then(progressEvent => {
                  if (progressEvent.done) {
                    controller.close()
                    return
                  }
                  loaded += progressEvent.value.byteLength
                  const percentageComplete =
                    Math.round((loaded / contentLength) * 100) + '%'
                  setProgressData(percentageComplete)
                  controller.enqueue(progressEvent.value)
                  read()
                })
              }
            }
          })
        )
      })
      .then(res => res.json())
      .then(result => {
        setParityWiseData(result.data)
        setIsLoading(false)
        setProgressData('0%')
      })
      .catch(error => setIsLoading(false))
  }

  const ProductionGetData = async e1 => {
    setIsLoading(true)
    await fetch(`${API_KEY}/api/sp/Milk/Displaymilk`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify({
        IDNO: TagId !== null ? TagId : tagId,
        Parity: parity !== '' ? parseInt(parity) : e1.Lactose
      })
    })
      .then(response => {
        const contentLength = response.headers.get('content-length')
        let loaded = 0
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader()
              read()
              function read() {
                reader.read().then(progressEvent => {
                  if (progressEvent.done) {
                    controller.close()
                    return
                  }
                  loaded += progressEvent.value.byteLength
                  const percentageComplete =
                    Math.round((loaded / contentLength) * 100) + '%'
                  setProgressData(percentageComplete)
                  controller.enqueue(progressEvent.value)
                  read()
                })
              }
            }
          })
        )
      })
      .then(res => res.json())
      .then(result => {
        setProductionList(result)
        setIsLoading(false)
        setProgressData('0%')
      })
      .catch(error => setIsLoading(false))
  }

  const getDataById = async () => {
    const res = await getTreeData({
      Herdcode: '1',
      Herdtype: 'Animal',
      userCode: ''
    })
    if (res) {
      setTagIdList(res)
    }
  }

  useEffect(() => {
    fetchData()
    let divHeight = document.getElementById('heightOffsetStock')?.offsetHeight
    setHeight(divHeight)
    ProductionGetData()

    let objEdit = JSON.parse(localStorage.getItem('editAnimal'))
    getData.push(objEdit)
    for (let index = 0; index < getData.length; index++) {
      const element = getData[index]
      if (element) {
        setRoute(element['herdId'])
        setSociety(element['lotId'])
        setFarmer(element['farmerId'])
      }
    }
    getDataById()
  }, [])

  const parityWiseDataChange = async e => {
    view(e)
    // setActive(true)
    // setIsLoading(true);
    // await fetch(`${MilkTestApi.getParityWiseData}`, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         "Accept": "application/json",
    //         "Authorization": `Bearer ${added_by}`,
    //     },
    //     body: JSON.stringify({ detailsId: tagId })
    // })
    //     .then(response => {
    //         const contentLength = response.headers.get('content-length');
    //         let loaded = 0;
    //         return new Response(
    //             new ReadableStream({
    //                 start(controller) {
    //                     const reader = response.body.getReader();
    //                     read();
    //                     function read() {
    //                         reader.read()
    //                             .then((progressEvent) => {
    //                                 if (progressEvent.done) {
    //                                     controller.close();
    //                                     return;
    //                                 }
    //                                 loaded += progressEvent.value.byteLength;
    //                                 const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                 setProgressData(percentageComplete)
    //                                 controller.enqueue(progressEvent.value);
    //                                 read();
    //                             })
    //                     }
    //                 }
    //             })
    //         );
    //     })
    //     .then(res => res.json())
    //     .then((result) => { setProductionList(result.data); setIsLoading(false); setProgressData("0%") })
    //     .catch((error) => setIsLoading(false))
  }

  const colum = [
    {
      name: 'Date',
      selector: 'Date',
      cell: e => <p style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{moment(e.Date).format('DD-MM-YYYY')}</p>,
      width: '115px',
      sortable: true
    },
    {
      name: 'Days in milk',
      selector: 'Days in milk',
      width: '60px',
      sortable: true,
      cell: (c) => (
        <>
          <div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{c["Days in milk"]}</div>
        </>
      ),
    },
    {
      name: 'Mor',
      selector: 'Mor',
      cell: e1 => (
        <input
          type='text'
          className='inpField1 ms-auto text-end'
          value={e1.Mor}
          onChange={e => morFn(e, e1)}
        />
      ),
      width: '60px',
      sortable: true
    },
    {
      name: 'Eve',
      selector: 'Eve',
      cell: e1 => (
        <input
          type='text'
          className='inpField1 ms-auto text-end'
          value={e1.Eve}
          onChange={e => eveFn(e, e1)}
        />
      ),
      width: '70px',
      sortable: true
    },
    {
      name: 'Night',
      selector: 'Night',
      cell: e1 => (
        <input
          type='text'
          className='inpField1 ms-auto text-end'
          value={e1.Night}
          onChange={e => nightFn(e, e1)}
        />
      ),
      width: '70px',
      sortable: true
    },
    {
      name: 'Mid Night',
      selector: 'Mid Night',
      cell: e1 => (
        <input
          type='text'
          className='inpField1 ms-auto text-end'
          value={e1['Mid Night']}
          onChange={e => midNightFn(e, e1)}
        />
      ),
      width: '70px',
      sortable: true
    },
    {
      name: 'Milk Total',
      selector: 'Milk Total',
      width: '80px',
      sortable: true,
      cell: (c) => (
        <>
          <div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{c["Milk Total"]}</div>
        </>
      ),
      // cell: (e1) => {
      //     const totalMilk = parseFloat(e1.Mor) + parseFloat(e1.Eve) + parseFloat(e1.Night) + parseFloat(e1["Mid Night"]);
      //     const roundedTotal = totalMilk.toFixed(2);
      //     return <div>{roundedTotal}</div>;
      // }
    },
    {
      name: 'FAT',
      selector: 'FAT',
      cell: e1 => (
        <input
          type='text'
          className='inpField1 ms-auto text-end'
          value={e1.FAT}
          onChange={e => fatFn(e, e1)}
        />
      ),
      width: '70px',
      sortable: true
    },
    {
      name: 'SNF',
      selector: 'SNF',
      cell: e1 => (
        <input
          type='text'
          className='inpField1 ms-auto text-end'
          value={e1['SNF']}
          onChange={e => snfFn(e, e1)}
        />
      ),
      width: '70px',
      sortable: true
    },
    {
      name: 'Protein',
      selector: 'Protein',
      cell: e1 => (
        <input
          type='text'
          className='inpField1 ms-auto text-end'
          value={e1.Protein}
          onChange={e => protineFn(e, e1)}
        />
      ),
      width: '70px',
      sortable: true
    },
    {
      name: 'Lactose',
      selector: 'Lactose',
      // cell: (e1) => <input type='text' className="inpField1 ms-auto text-end" value={e1.Lactose} onChange={(e) => loctoseFn(e, e1)} />,
      cell: e1 => <div className='ms-auto'>{e1.Lactose}</div>,
      width: '80px',
      sortable: true
    },
    {
      name: 'Days Total',
      selector: 'Days Total',
      width: '80px',
      sortable: true,
      cell: e => {
        const total =
          (parseFloat(e.Mor) || 0) +
          (parseFloat(e.Eve) || 0) +
          (parseFloat(e.Night) || 0) +
          (parseFloat(e['Mid Night']) || 0)
        return <div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{total.toFixed(2)}</div>
      }
    },
    {
      name: 'Actions',
      // cell: (e) => (<><button  className='saveMilk me-0' onClick={() => saveFn(e)}>Save</button></>),
      cell: e => (
        <button
          className='saveMilk me-0'
          style={{ width: '80px' }}
          onClick={() => saveFn(e)}
        >
          {' '}
          Save{' '}
        </button>
      ),

      width: '100px',
      paddingRigh: '5px'
    }
  ]

  const colum1 = [
    {
      // name: 'Action',
      cell: e => (
        <div
          className='align-items-center d-flex rightArrow'
          onClick={() => setLactos(e.Lacno)}
          style={{ cursor: 'pointer' }}
        >
          <BiChevronsRight size='25' color='green' className='me-2' />
        </div>
      ),
      width: '45px',
      sortable: true
    },
    {
      name: 'Lacno',
      selector: 'Lacno',
      width: '70px',
      cell: (c) => (
        <>
          <div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{c["Lacno"]}</div>
        </>
      ),
      sortable: true
    },
    // {
    //     name: "Lacno",
    //     selector: "Lacno",
    //     cell: (e) => <div className='align-items-center d-flex rightArrow ms-auto' onClick={() => { parityWiseDataChange(e) }} style={{ cursor: 'pointer', }}><BiChevronsRight size="25" color="green" className="me-2" /><div>{e.Lacno}</div></div>,
    //     width: "80px",
    // },
    {
      name: 'Calving Date',
      selector: 'Calving Date',
      cell: c => {
        const calvingDate = moment(c['Calving Date'], 'YYYY-MM-DD', true);
        return (
          <div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>
            {calvingDate.isValid() ? calvingDate.format('DD-MM-YYYY') : ''}
          </div>
        );
      },
      width: '112px',
      sortable: true
    },
    {
      name: 'Dry Date',
      selector: 'Dry Date',
      cell: c => {
        const dryDate = moment(c['Dry Date'], 'YYYY-MM-DD', true);
        return (
          <div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>
            {dryDate.isValid() ? dryDate.format('DD-MM-YYYY') : ''}
          </div>
        );
      },
      width: '110px',
      sortable: true
    },
    {
      name: 'Milk Yield',
      selector: 'Milk Yield',
      width: '80px',
      cell: (c) => (
        <>
          <div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{c["Milk Yield"]}</div>
        </>
      ),
      sortable: true
    },
    {
      name: 'Avg Yield',
      selector: 'Avg Yield',
      width: '65px',
      cell: (c) => (
        <>
          <div style={{ paddingRight: "5px", width: "110px", display: "flex", justifyContent: "flex-end" }}>{c["Avg Yield"]}</div>
        </>
      ),
      sortable: true
    }
    // {
    //     name: "Actions",
    //     cell: (e) => (<><button className='saveMilk me-0' onClick={() => saveFn(e)}>Save</button></>),
    //     width: "220px"
    // },
  ]

  const morFn = (inputEv, index) => {
    const value = inputEv.target.value
    setProductionList(
      productionList.map(o => {
        if (o === index) {
          return { ...index, Mor: value }
        } else {
          return o
        }
      })
    )
  }
  const eveFn = (inputEv, index) => {
    const value = inputEv.target.value
    setProductionList(
      productionList.map(o => {
        if (o === index) {
          return { ...index, Eve: value }
        } else {
          return o
        }
      })
    )
  }
  const nightFn = (inputEv, index) => {
    const value = inputEv.target.value
    setProductionList(
      productionList.map(o => {
        if (o === index) {
          return { ...index, Night: value }
        } else {
          return o
        }
      })
    )
  }
  const midNightFn = (inputEv, index) => {
    const value = inputEv.target.value
    setProductionList(
      productionList.map(o => {
        if (o === index) {
          return { ...index, 'Mid Night': value }
        } else {
          return o
        }
      })
    )
  }
  const fatFn = (inputEv, index) => {
    const value = inputEv.target.value
    setProductionList(
      productionList.map(o => {
        if (o === index) {
          return { ...index, FAT: value }
        } else {
          return o
        }
      })
    )
  }
  const snfFn = (inputEv, index) => {
    const value = inputEv.target.value
    setProductionList(
      productionList.map(o => {
        if (o === index) {
          return { ...index, SNF: value }
        } else {
          return o
        }
      })
    )
  }
  const loctoseFn = (inputEv, index) => {
    const value = inputEv.target.value
    setProductionList(
      productionList.map(o => {
        if (o === index) {
          return { ...index, Lactose: value }
        } else {
          return o
        }
      })
    )
  }
  const protineFn = (inputEv, index) => {
    const value = inputEv.target.value
    setProductionList(
      productionList.map(o => {
        if (o === index) {
          return { ...index, Protein: value }
        } else {
          return o
        }
      })
    )
  }

  const logOut = () => {
    navigate('/')
  }

  const saveFn = async e => {
    setLactos(e.Lacno)
    console.log('saveFn ' + e.Lacno)
    console.log('e' + Parity)
    const date = moment(selectedToDate).format('MM/DD/YYYY')

    await fetch(`${API_KEY}/v1/api/web/milk/save-milk-entry`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify({
        EveningYield: e.Eve || '',
        Fat: parseInt(e.FAT) || '',
        Date: e.Date || '',
        Lactose: e.Lactose || '',
        MidnightYield: e['Mid Night'] || '',
        MorningYield: e.Mor || '',
        NightYield: e.Night || '',
        OfficialMilk: '1',
        parity: Parity ? Parity : parity || 0,
        Protein: e.Protein || '',
        Snf: e.SNF || '',
        Tagid: TagId ? TagId : tagId || null,
        createdAt: date || ''
      })
    })
      .then(res => res.json())
      .then(result => {
        console.log(result)
      })
  }

  useEffect(() => {
    if (Lactos !== null) {
      view({ Lacno: Lactos })
    }
  }, [Lactos])

  const selectTagId = async e => {
    setTagId(e)
    setProductionList([])
    setParityWiseData([])
    await fetch(Cattleregistration.AnimalGet, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${added_by}`
      },
      body: JSON.stringify({ tagId: e })
    })
      .then(response => {
        const contentLength = response.headers.get('content-length')
        let loaded = 0
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader()
              read()
              function read() {
                reader.read().then(progressEvent => {
                  if (progressEvent.done) {
                    controller.close()
                    return
                  }
                  loaded += progressEvent.value.byteLength
                  const percentageComplete =
                    Math.round((loaded / contentLength) * 100) + '%'
                  setProgressData(percentageComplete)
                  controller.enqueue(progressEvent.value)
                  read()
                })
              }
            }
          })
        )
      })
      .then(res => res.json())
      .then(result => {
        setParity(result[14]['Fld'])
        setLactos(result[14]['Fld'])
        setRoute(result[3]['Fld'])
        setSociety(result[2]['Fld'])
        setFarmer(result[4]['Fld'])
        setIsLoading(false)
        setProgressData('0%')
      })
  }

  const handleFileChange = (e) => {
    // console.log('File selected');
    const file = e.target.files[0];
    setImportFile(true);

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // console.log("jsonData:", JSON.stringify(jsonData));

      if (jsonData.length > 1) {
        const headers = jsonData[0].map(header => header.trim());
        const excelData = jsonData.slice(1).map(row => {
          const rowObject = headers.reduce((acc, curr, index) => {
            acc[curr] = row[index] !== undefined ? row[index] : null;
            return acc;
          }, {});

          // Convert Excel date serial number to 'DD-MM-YYYY' format if it's a number
          if (typeof rowObject.Date === 'number') {
            rowObject.Date = moment(new Date((rowObject.Date - 25569) * 86400 * 1000)).format('DD-MM-YYYY');
          }

          return rowObject;
        });

        setExcelData(excelData);
        console.log("Formatted excelData:", JSON.stringify(excelData));

        let filteredList = [...productionList];

        for (let i = 0; i < excelData.length; i++) {
          const { Date, Mor, Eve, Night } = excelData[i];

          // Skip processing if Date is not present
          if (!Date) continue;

          console.log("Formatted Date:", Date);

          // Match formatted date with productionList
          for (let j = 0; j < filteredList.length; j++) {
            const filteredDate = moment(filteredList[j].Date, 'YYYY-MM-DD').format('DD-MM-YYYY');

            if (filteredDate === Date) {
              filteredList[j].Mor = Mor !== undefined ? Mor : filteredList[j].Mor; // Update Mor if available
              filteredList[j].Eve = Eve !== undefined ? Eve : filteredList[j].Eve; // Update Eve if available
              if (Night !== undefined) {
                filteredList[j].Night = Night; // Update Night if available
              }
            }
          }
        }

        setProductionList(filteredList);
      } else {
        toast.error('Invalid file format or empty file');
      }
    };
    reader.readAsArrayBuffer(file);
  };




  const userId = localStorage.getItem('USERID')

  const SaveFn = async () => {
    console.log("vs");
    if (productionList.length > 0) {
      setIsLoading(true)
      console.log("productionList",productionList)
      const MilkData = []

      // productionList.forEach(e => {
        // console.log("product",e)
        // if (
        //   e.Mor !== null ||
        //   e.Eve !== null ||
        //   (e.Night !== null && e.Night.toString() !== '0') ||
        //   (e.Midnight !== null && e.Midnight.toString() !== '0') ||
        //   (e.FAT !== null && e.FAT.toString() !== '0') ||
        //   (e.SNF !== null && e.SNF.toString() !== '0') ||
        //   (e.Lactose !== null && e.Lactose.toString() !== '0')
        // ) 
        // if (
        //   e.Mor !== null ||
        //   e.Eve !== null ||
        //   (e.Night !== null && e.Night.toString() !== '0') ||
        //   (e.Midnight !== null && e.Midnight.toString() !== '0') ||
        //   (e.FAT !== null && e.FAT.toString() !== '0') ||
        //   (e.SNF !== null && e.SNF.toString() !== '0') ||
        //   (e.Lactose !== null && e.Lactose.toString() !== '0')
        // ) {
        //   console.log('Inside if condition');
        //   // Rest of the code
        // } else {
        //   console.log("Skipped item:", e);
        // }
        // {
        //   console.log('Lactos ' + Lactos)
        //   console.log('parity ' + parity)
        //   MilkData.push({
        //     Date: e.Date,
        //     Parity: parseInt(Lactos)
        //       ? parseInt(Lactos)
        //       : parseInt(parity)
        //         ? parseInt(parity)
        //         : parseInt(e['lac No'])
        //           ? parseInt(e['lac No'])
        //           : 0,
        //     MorningYield: e.Mor,
        //     EveningYield: e.Eve,
        //     NightYield: e.Night,
        //     MidnightYield: e.Midnight,
        //     Fat: e.FAT,
        //     Snf: e.SNF,
        //     Lactose: e.Lactose,
        //     Protein: '',
        //     LactationMilkTotal: 1
        //   })
        // }
      // })

      productionList.forEach(e => {
        console.log("Processing item:", e);
      
        // Check each field and log values for clarity
        const hasMorning = e.Mor !== null && e.Mor !== 0;
        const hasEvening = e.Eve !== null && e.Eve !== 0;
        const hasNight = e.Night !== null && e.Night !== 0;
        const hasMidnight = e.Midnight !== null && e.Midnight !== 0;
        const hasFat = e.FAT !== null && e.FAT !== 0;
        const hasSnf = e.SNF !== null && e.SNF !== 0;
        const hasLactose = e.Lactose !== null && e.Lactose !== 0;
      
        console.log("Conditions:", { hasMorning, hasEvening, hasNight, hasMidnight, hasFat, hasSnf, hasLactose });
      
        // If any of these are true, add the record to MilkData
        if (hasMorning || hasEvening || hasNight || hasMidnight || hasFat || hasSnf || hasLactose) {
          console.log('Inside if condition, item:', e);
          MilkData.push({
            Date: e.Date,
            Parity: parseInt(Lactos) || parseInt(parity) || parseInt(e['lac No']) || 0,
            MorningYield: e.Mor,
            EveningYield: e.Eve,
            NightYield: e.Night,
            MidnightYield: e.Midnight,
            Fat: e.FAT,
            Snf: e.SNF,
            Lactose: e.Lactose,
            Protein: '',
            LactationMilkTotal: 1
          });
        } else {
          console.log("Skipped item:", e);
        }
      });
      
      
      if (MilkData.length > 0) {
        const payload = {
          jsonData: JSON.stringify(MilkData),
          tagid: tagId,
          createdByUser: parseInt(userId)
        }

        await fetch(`${API_KEY}/production/milk/SaveIndividualMilkEntry`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${added_by}`
          },
          body: JSON.stringify(payload)
        })
          .then(response => {
            const contentLength = response.headers.get('content-length')
            let loaded = 0
            return new Response(
              new ReadableStream({
                start(controller) {
                  const reader = response.body.getReader()
                  read()
                  function read() {
                    reader.read().then(progressEvent => {
                      if (progressEvent.done) {
                        controller.close()
                        return
                      }
                      loaded += progressEvent.value.byteLength
                      const percentageComplete =
                        Math.round((loaded / contentLength) * 100) + '%'
                      setProgressData(percentageComplete)
                      controller.enqueue(progressEvent.value)
                      read()
                    })
                  }
                }
              })
            )
          })
          .then(response => {
            return response.json()
          })
          .then(result => {
            fetchData()
            setIsLoading(false)
            if (result.success) {
              toast.success('Data Saved Successfully...')
            } else {
              toast.error('Failed to insert data..!!')
            }
          })
          .catch(error => {
            setIsLoading(false)
            console.error('Error:', error)
          })
      } else {
        setIsLoading(false)
      }
    } else {
      setIsLoading(false)
    }
  }
  const deleteFn = async () => {
    const payload = {
   
      tagid:tagId,
      Parity:parity
    };
  
    try {
      const response = await fetch(`${API_KEY}/api/deleteMilkTag`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`,
        },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // Show success message from the response
        toast.success(data.message || "Operation successful");
      } else {
        // Show error message if the response is not ok
        toast.error(data.message || "Something went wrong");
      }
  
    
    } catch (error) {
      console.error('Error saving data:', error);
      toast.error("Error saving data");
    }
  };
  // const SaveFn = () => {
  //     if (productionList.length > 0) {
  //         setIsLoading(true)
  //         productionList.forEach((e) => {
  //             console.log(e)
  //             if ((e.Mor !== 0) || (e.Eve !== 0) || (e.Night !== 0 && e.Night.toString() !== "0") || (e.Midnight !== 0 && e.Midnight.toString() !== "0") || (e.FAT !== 0 && e.FAT.toString() !== "0") ||
  //                 (e.SNF !== 0 && e.SNF.toString() !== "0") ||
  //                 (e.Lactose !== 0 && e.Lactose.toString() !== "0")
  //             ) {
  //                 fetch(`${API_KEY}/v1/api/web/milk/save-milk-entry`, {
  //                     method: 'POST',
  //                     headers: {
  //                         'Content-Type': 'application/json',
  //                         "Accept": "application/json",
  //                         "Authorization": `Bearer ${added_by}`
  //                     },
  //                     body: JSON.stringify({ eveningYield: e.Eve, fat: e.FAT, lactose: e.Lactose, midnightYield: e["Mid Night"], inputDate: e.Date, morningYield: e.Mor, nightYield: e.Night, officialMilk: 1, parity: parity, protein: "", snf: e.SNF, tagId: tagId, createdAt: selectedToDate })
  //                 })
  //                     .then(response => {
  //                         const contentLength = response.headers.get('content-length');
  //                         let loaded = 0;
  //                         return new Response(
  //                             new ReadableStream({
  //                                 start(controller) {
  //                                     const reader = response.body.getReader();
  //                                     read();
  //                                     function read() {
  //                                         reader.read()
  //                                             .then((progressEvent) => {
  //                                                 if (progressEvent.done) {
  //                                                     controller.close();
  //                                                     return;
  //                                                 }
  //                                                 loaded += progressEvent.value.byteLength;
  //                                                 const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
  //                                                 setProgressData(percentageComplete)
  //                                                 controller.enqueue(progressEvent.value);
  //                                                 read();
  //                                             })
  //                                     }
  //                                 }
  //                             })
  //                         );
  //                     })
  //                     .then(res => res.json())
  //                     .then((result) => { toast.success("Save Data Successful"); setIsLoading(false) })
  //             }
  //             else {
  //                 setIsLoading(false)
  //             }
  //         })
  //     }
  //     else {
  //         setIsLoading(false)
  //     }
  // }

  const calculateTotals = data => {
    const totals = {
      Mor: 0,
      Eve: 0,
      Night: 0,
      MidNight: 0,
      DaysTotal: 0,
      milktotal:0,
    }
    
    data.forEach(item => {
      totals.Mor += Number(item.Mor) || 0
      totals.Eve += Number(item.Eve) || 0
      totals.Night += Number(item.Night) || 0
      totals.MidNight += Number(item['Mid Night']) || 0
      totals.milktotal+= Number(item['Milk Total']) || 0

    })

    totals.Mor = Number(totals.Mor).toFixed(2)
    totals.Eve = Number(totals.Eve).toFixed(2)
    totals.Night = Number(totals.Night).toFixed(2)
    totals.MidNight = Number(totals.MidNight).toFixed(2)
    totals.milktotal = Number(totals.milktotal).toFixed(2)
    totals.DaysTotal = (parseFloat(totals.Mor) + parseFloat(totals.Eve) + parseFloat(totals.Night) + parseFloat(totals.MidNight)).toFixed(2)

    return totals
  }



  const totalsProductionList = calculateTotals(
    searchFilter ? searchFilter : productionList
  )

  const handledExcel = () => {
    // console.log('Aaa')
    setIsLoading(true)
    const keysToExport = [
      'Date',
      'Days in milk',
      'Mor',
      'Eve',
      'Night',
      'Mid Night',
      'Milk Total',
      'FAT',
      'SNF',
      'Protein',
      'Lactose',
      'Days Total'
    ]

    const filteredData = productionList.map(item => {
      const filteredItem = {}
      keysToExport.forEach(key => {
        if (key === 'Date') {
          filteredItem[key] = moment(item[key]).format('DD-MM-YYYY')
        } else if (key === 'Days Total') {
          // Calculate Days Total
          const total =
            (parseFloat(item.Mor) || 0) +
            (parseFloat(item.Eve) || 0) +
            (parseFloat(item.Night) || 0) +
            (parseFloat(item['Mid Night']) || 0)
          filteredItem[key] = total.toFixed(2)
        } else {
          filteredItem[key] = item[key]
        }
      })
      return filteredItem
    })

    const header = keysToExport.map(key => {
      const column = colum.find(col => col.selector === key)
      return column ? column.name : key
    })

    const fileName = `${tagId}`

    downloadExcel({
      fileName,
      sheet: 'Production Data',
      tablePayload: {
        header,
        body: filteredData
      }
    })
    setIsLoading(false)
  }

  return (
    <>
      <Container fluid>
        <Row>
          {headerShow && (
            <Col lg='2' className='hideNavbar'>
              {' '}
              <Sidenav />
            </Col>
          )}
          <Col className='header' lg={headerShow ? '10' : '12'}>
            <Container fluid>
              <Row className='pt-1 sub-header align-items-center justify-content-between'>
                <Col
                  md='10'
                  sm='10'
                  xs='10'
                  className='py-2 d-flex align-items-center'
                >
                  <button
                    style={{ border: 'none' }}
                    className='hideNavbar1'
                    onClick={handleShow}
                  >
                    <img src={menu} width={20} />
                  </button>
                  <button
                    style={{ border: 'none' }}
                    className='hide_Navbar'
                    onClick={() => setShow(true)}
                  >
                    <img src={menu} width={20} />
                  </button>
                  <h5 className='ms-4 mt-2'>
                    Cattle details / Individual Animal Milk Entry{' '}
                  </h5>
                </Col>
                <Col
                  md='1'
                  sm='1'
                  xs='10'
                  className='py-2 d-flex align-items-center text-end ms-auto'
                >
                  <BackBtn
                    style={{ border: 'none' }}
                    className='hideNavbar1'
                    lable='Back'
                    onClick={() => {
                      navigate('/')
                    }}
                  />
                </Col>
                <Col md='1' sm='1' xs='2' className='text-end profile_dropdown'>
                  <img src={profile} width={40} />
                  <div className='dropdpwn_Open'>
                    <Row className='text-center align-items-center'>
                      <Col xs='12' className='menuHover mb-3'>
                        <Row>
                          <Col className='' xs='3'>
                            <span className=''>
                              <MdOutlineVpnKey size={25} />
                            </span>
                          </Col>
                          <Col className='text-start' xs='9'>
                            Change Password
                          </Col>
                        </Row>
                      </Col>
                      <Col xs='12' className='menuHover' onClick={logOut}>
                        <Row>
                          <Col className='' xs='3'>
                            <span className=''>
                              <GoSignOut size={25} />
                            </span>
                          </Col>
                          <Col className='text-start' xs='9'>
                            Sign Out
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row
                className='align-items-end justify-content-start p-2'
                id='heightOffsetStock'
              >
                <Col xl='2' sm='3' className=''>
                  <Select
                    placeholder={TagId !== null ? TagId : 'Tag Id'}
                    onChange={selectedOption => {
                      selectTagId(selectedOption.label)
                    }}
                    options={tagIdList.map(item => ({
                      value: item.Animalid,
                      label: item.Animal
                    }))}
                  />
                </Col>
                <Col
                  xxl='1'
                  xs='6'
                  sm='2'
                  md='2'
                  xl='2'
                  className='justify-content-start'
                >
                  <Select
                    placeholder={route !== '' ? `${route}` : 'Route'}
                    onChange={selectedOption => {
                      setRoute(selectedOption.value)
                    }}
                    options={routeList.map(item => ({
                      value: item.Id,
                      label: item.name
                    }))}
                  />
                </Col>
                <Col
                  xxl='1'
                  xs='6'
                  sm='2'
                  md='2'
                  xl='2'
                  className='justify-content-start'
                >
                  <Select
                    placeholder={society !== '' ? `${society}` : 'Society'}
                    onChange={selectedOption => {
                      setSociety(selectedOption.value)
                    }}
                    options={societyList.map(item => ({
                      value: item.id,
                      label: item.DCSName
                    }))}
                  />
                </Col>
                <Col
                  xxl='1'
                  xs='3'
                  sm='1'
                  md='1'
                  xl='1'
                  className='justify-content-start'
                >
                  <Select
                    placeholder={farmer !== '' ? `${farmer}` : 'Farmer'}
                    onChange={selectedOption => {
                      setFarmer(selectedOption.value)
                    }}
                    options={farmerList.map(item => ({
                      value: item.id,
                      label: item.FarmerName
                    }))}
                  />
                </Col>
                <Col xxl='1' md='3' sm='3' xs='6' xl='2' className=''>
                  <div className='form-field'>
                    <div className='form-field__control'>
                      <input
                        id='email'
                        type='text'
                        className='form-field__input'
                        placeholder=' '
                        value={Lactos}
                        onChange={e => setLactos(e.target.value)}
                      />
                      <label for='email' className='form-field__label'>
                        Lactation
                      </label>
                      <div className='form-field__bar'></div>
                    </div>
                  </div>
                </Col>
                <Col
                  xxl='1'
                  md='2'
                  sm='3 mt-2'
                  xs='6'
                  xl='2'
                  className='ms-auto'
                >
                  <Input
                    label='Search'
                    value={searchId}
                    onChange={e => setSearchId(e.target.value)}
                  />
                </Col>
                <Col xxl='1' sm='2' xs='2'>
                  <button className='btn-blue-Animal mt-1 mb-1' onClick={view}>
                    View
                  </button>
                </Col>
                <Col xxl='1' sm='2' xs='3'>
                  <button
                    className='btn-blue-Animal mt-1 mb-1'
                    onClick={SaveFn}
                  >
                    Save All
                  </button>
                </Col>
                <Col xxl='1' sm='2' xs='3'>
                  <button
                    className='btn-blue-Animal mt-1 mb-1'
                    onClick={deleteFn}
                  >
                    Delete
                  </button>
                </Col>
                <Col xxl='1' md='1'>
                  <button className='btn-blue-Animal mt-1 mb-1'>
                    <label className='file-input-label mb-0'>
                      {fileName}
                      <input
                        type='file'
                        accept='.xlsx'
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                    </label>
                  </button>
                </Col>
                <Col xxl='1' md='1'>
                  <button
                    className='btn-blue-Animal mt-1 mb-1 ms-2'
                    onClick={() => handledExcel()}
                  >
                    Export
                  </button>
                </Col>
              </Row>
            </Container>
            <Container fluid>
              <Row className='mt-4'>
                <Col
                  xs='12'
                  sm='3'
                  className={
                    parityWiseData.length > 0
                      ? 'MainTableBorder m-1 mobileTable'
                      : ''
                  }
                  style={{ width: '31.9%' }}
                >
                  <Table
                    columns={colum1}
                    data={searchFilter ? searchFilter : parityWiseData}
                    height={`calc(100vh - (${heightOffSet}px + 0px))`}
                  />
                </Col>
                <Col
                  xs='12'
                  sm='8'
                  className={
                    productionList.length > 0
                      ? 'MainTableBorder m-1 ms-auto'
                      : ''
                  }
                >
                  <Table
                    columns={colum}
                    data={searchFilter ? searchFilter : productionList}
                    height={`calc(100vh - (${heightOffSet}px + 165px))`}
                  />
                  {productionList.length > 0 && (
                    <div
                      className='totals'
                      style={{
                        display: 'flex',

                        // alignItems:"cemter",
                        paddingTop: '20px'
                      }}
                    >
                      <p
                        style={{
                          backgroundColor: '#6e96c4',
                          padding: '1px 5px 1px 5px',
                          marginRight: '10px',
                          marginLeft: '10px',
                          color: 'white'
                        }}
                      >
                        Mor: {totalsProductionList.Mor}
                      </p>
                      <p
                        style={{
                          backgroundColor: '#6e96c4',
                          padding: '1px 5px 1px 5px',
                          marginRight: '10px',
                          color: 'white'
                        }}
                      >
                        Eve: {totalsProductionList.Eve}
                      </p>
                      <p
                        style={{
                          backgroundColor: '#6e96c4',
                          padding: '1px 5px 1px 5px',
                          marginRight: '10px',
                          color: 'white'
                        }}
                      >
                        Night: {totalsProductionList.Night}
                      </p>
                      <p
                        style={{
                          backgroundColor: '#6e96c4',
                          padding: '1px 5px 1px 5px',
                          marginRight: '10px',
                          color: 'white'
                        }}
                      >
                        Mid Night: {totalsProductionList.MidNight}
                      </p>
                      <p
                        style={{
                          backgroundColor: '#6e96c4',
                          padding: '1px 5px 1px 5px',
                          marginRight: '10px',
                          color: 'white'
                        }}
                      >
                        Days Total: {totalsProductionList.DaysTotal}
                      </p>
                      <p
                        style={{
                          backgroundColor: '#6e96c4',
                          padding: '1px 5px 1px 5px',
                          marginRight: '10px',
                          color: 'white'
                        }}
                      >
                        Milk total: {totalsProductionList.milktotal}
                      </p>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
        style={{ fontSize: '14px' }}
      />

      {show && (
        <Offcanvas className='hide_Navbar' show={show} onHide={handleClose}>
          <Container>
            <Row>
              <Col>
                <Sidenav />
              </Col>
            </Row>
          </Container>
        </Offcanvas>
      )}
      {isLoading ? (
        <div className='circleText'>
          <div className='progressText'>{progressData}</div>
          <div className=''>
            <span className='loader-circle-13'></span>
          </div>
        </div>
      ) : (
        false
      )}
    </>
  )
}
export default IndividualMilk
