import { Checkbox, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './visit_registration.css';
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Col, Container, Row, Offcanvas, Accordion, Modal } from "react-bootstrap";
import Input from "../../Componet/InputFild/Input";
import Sidenav from '../../Componet/Sidenav/Sidenav';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import Table from '../../Componet/DataTable/Table';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { IoMdArrowDropdown } from "react-icons/io";
import { STORAGE_KEY } from "../../constant/common";
import { API_URLS } from "../../API/Api";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { downloadExcel } from 'react-export-table-to-excel';
import { AiFillCloseCircle } from "react-icons/ai";
import { IoMdDownload } from "react-icons/io";
import { MdModeEditOutline } from "react-icons/md";
import { RxCountdownTimer } from "react-icons/rx";
import moment from 'moment/moment';
import Searcable from '../../Componet/Dropdown/Searcable';
const { CC } = API_URLS;
const { visitReallocation } = API_URLS;




const Visit_Reallocation_User = () => {

    const [headerShow, setHeaderShow] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }

    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const selectedToDate = new Date().toISOString().slice(0, 10);
    const [date, setDate] = useState(selectedToDate);
    const [reallocationData, setReallocationData] = useState([])
    const [selectedStatus, setSelectedStatus] = useState('');
    const [heightOffSet, setHeight] = useState(0);
    const [selectSearchData, setSelectSearchData] = useState('');
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [progressData, setProgressData] = useState("0%");
    const [modalShowVisitData, setModalShowVisitData] = useState(false);
    const [modalShow, setModalShow] = useState(false)
    const [editId, setEditId] = useState("")
    const [visitdata, setVisitData] = useState("");
    const [realVisitData, setRealVisitData] = useState([]);
    const [doctorName, setDoctorName] = useState([])
    const [doctorVoName, setDoctorVoName] = useState("")
    const [centerName, setCenterName] = useState("")
    const [center, setCenter] = useState([])

    // async function getVisitReallocationData(credentials) {
    //   return fetch(visitReallocation.GetReallocation, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       "Accept": "application/json",
    //       "Authorization": `Bearer ${added_by}`,
    //     },
    //     body: JSON.stringify(credentials)
    //   })

    //     .then(data => data.json())

    // }

    async function getVisitReallocationData(credentials) {
        setIsLoading(true);

        try {

            const response = await fetch(visitReallocation.GetReallocation, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${added_by}`,
                },
                body: JSON.stringify(credentials),
            });

            const contentLength = response.headers.get('content-length');
            let loaded = 0;

            const result = await new Response(
                new ReadableStream({
                    async start(controller) {
                        const reader = response.body.getReader();
                        await read();

                        async function read() {
                            const { done, value } = await reader.read();
                            if (done) {
                                controller.close();
                                return;
                            }
                            loaded += value.byteLength;
                            const percentageComplete = Math.round((loaded / contentLength) * 100) + '%';

                            setProgressData(percentageComplete);
                            controller.enqueue(value);
                            await read();
                        }
                    },
                })
            ).json();

            setIsLoading(false);
            setReallocationData(result);
            setProgressData('0%');

        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }


    }



    const fetchdata = async (stval) => {
        if (GROUP == 35) {
            const response = await getVisitReallocationData({
                columnNameSort: "",
                filterString: selectSearchData ? selectSearchData.toString() : "complaintCode",
                inputDate: date,
                limit: "",
                offset: "18",
                searchString: search ? search.toString() : "24",
                sortOrder: stval ? stval.toString() : "Completed",
                sql: "",
            });
            if (response) {
                response.forEach(obj => {
                    reallocationData.push(obj);
                });
            }

            const apiUrl = `${visitReallocation.GetAllDataUser}`;

            const response1 = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${added_by}`,
                },

            });

            const responseData = await response1.json();

            setRealVisitData(responseData)
        }
        if (GROUP == 35) {
            const response = await getVisitReallocationData({
                columnNameSort: "",
                filterString: selectSearchData ? selectSearchData.toString() : "complaintCode",
                inputDate: date,
                limit: "",
                offset: "18",
                searchString: search ? search.toString() : "25",
                sortOrder: stval ? stval.toString() : "Completed",
                sql: "",
            });
            if (response) {
                setReallocationData(response);
            }

            const apiUrl = `${visitReallocation.GetAllDataUser}`;

            const response1 = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${added_by}`,
                },

            });

            const responseData = await response1.json();

            setRealVisitData(responseData)
        }
        else {
            console.log(false)
            const response = await getVisitReallocationData({
                columnNameSort: "",
                filterString: selectSearchData ? selectSearchData.toString() : "",
                inputDate: date,
                limit: "",
                offset: "18",
                searchString: search ? search.toString() : "",
                sortOrder: stval ? stval.toString() : "",
                sql: "",
            });

            if (response) {

                setReallocationData(response);
            }

            const apiUrl = `${visitReallocation.GetAllDataUser}`;

            const response1 = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${added_by}`,
                },

            });

            const responseData = await response1.json();


            setRealVisitData(responseData)
        }

    }
    const handleDownloadExcel = () => {
        const header = [];
        const opt = `Visit_Reallocation_User ${date}`;

        const filteredColumns = colum.filter(e => e.name !== "Reallocate" && e.selector !== "Status");

        filteredColumns.forEach(e => {
            header.push(e.selector);
        });

        const body = reallocationData.map(row => {
            const rowData = {};
            header.forEach(columnName => {
                const columnValue = row[columnName] || '';
                rowData[columnName] = columnValue;
            });

            return rowData;
        });

        console.log('Header:', header);
        console.log('Body:', body);

        downloadExcel({
            fileName: opt,
            sheet: "data",
            tablePayload: {
                header,
                body,
            },
        });
    };


    const handleDate = (selectedDate) => {

        setDate(selectedDate);
        fetchdatafordate(selectedDate);

    };

    const fetchdatafordate = async (selectedDate) => {
        await fetch(`${visitReallocation.GetReallocation}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                columnNameSort: "",
                filterString: selectSearchData ? selectSearchData.toString() : "complaintCode",
                inputDate: selectedDate,
                limit: "",
                offset: "18",
                searchString: search ? search.toString() : "24",
                sortOrder: "Completed",
                sql: "",
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setReallocationData(res)
            })
        await fetch(`${visitReallocation.GetReallocation}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                columnNameSort: "",
                filterString: selectSearchData ? selectSearchData.toString() : "complaintCode",
                inputDate: selectedDate,
                limit: "",
                offset: "18",
                searchString: search ? search.toString() : "25",
                sortOrder: "Completed",
                sql: "",
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setReallocationData(prevData => [...prevData, ...res]);
            })
       
    };

    const GROUP = window.localStorage.getItem("GROUP");
    // console.log(GROUP);
    const SearchFn = async (inputValue) => {

        const abc = reallocationData;

        const searchvalue = inputValue.target.value;
        setSearch(searchvalue);

        if (GROUP == "35") {
            if (searchvalue == "24" || searchvalue == "25" || searchvalue == "" || searchvalue == null) {
                const response = await getVisitReallocationData({
                    columnNameSort: "",
                    filterString: selectSearchData ? selectSearchData.toString() : "complaintCode",
                    inputDate: date,
                    limit: "",
                    offset: "18",
                    searchString: searchvalue ? searchvalue : "25",
                    sortOrder: selectedStatus ? selectedStatus.toString() : "Completed",
                    sql: "",
                });
                console.log(response)
                if (response) {
                    console.log(response)
                    setReallocationData(response)
                }
                console.log("if");
            } else if (searchvalue == "" || searchvalue == null) {
                const response = await getVisitReallocationData({
                    columnNameSort: "",
                    filterString: selectSearchData ? selectSearchData.toString() : "",
                    inputDate: date,
                    limit: "",
                    offset: "18",
                    searchString: "",
                    sortOrder: selectedStatus ? selectedStatus.toString() : "",
                    sql: "",
                });
                console.log(response)
                if (response) {
                    console.log(response)
                    setReallocationData(response)
                }
                console.log("elseif");
            }
        } else {
            const response = await getVisitReallocationData({
                columnNameSort: "",
                filterString: selectSearchData ? selectSearchData.toString() : "",
                inputDate: date,
                limit: "",
                offset: "18",
                searchString: searchvalue,
                sortOrder: selectedStatus ? selectedStatus.toString() : "",
                sql: "",
            });
            console.log(response)
            if (response) {
                console.log(response)
                setReallocationData(response)
            }

            console.log("else");
        }

    };

    const handelpdf = async (e) => {
        setIsLoading(true)
        if (e.complaintCode === 25) {
            try {
                const apiUrl = `${visitReallocation.GetComplaintCodetwoFour}/${e.visitID}?type=false`;

                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/pdf',
                        'Authorization': `Bearer ${added_by}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const pdfBlob = await response.blob();

                const pdfUrl = URL.createObjectURL(pdfBlob);

                window.open(pdfUrl, '_blank');

                URL.revokeObjectURL(pdfUrl);
            } catch (error) {
                console.error('API request failed:', error);
            } finally {
                setIsLoading(false);
            }

        }
        else {
            try {
                const apiUrl = `${visitReallocation.GetComplaintCodetwoFour}/${e.visitID}?type=sc`;

                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/pdf',
                        'Authorization': `Bearer ${added_by}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const pdfBlob = await response.blob();

                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl, '_blank');

                URL.revokeObjectURL(pdfUrl);
            } catch (error) {
                console.error('API request failed:', error);
            } finally {
                setIsLoading(false);
            }

        }
    };

    useEffect(() => {
        fetchdata();
    }, [])

    const handleSelectChangeStatus = (stval) => {
        console.log(stval);
        setSelectedStatus(stval);
        fetchdata(stval)
    };

    const handleSearchData = (val) => {

        setSelectSearchData(val)

    }

    const handleEdit = () => {
        setModalShowVisitData(true)
    }

    const handleVisitData = async (selectedValue) => {
        console.log(selectedValue);
        setVisitData(selectedValue);

        const apiUrl = `${visitReallocation.GetEditVisitData}`;

        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${added_by}`,
            },

        });

        const responseData = await response.json();


    };

    const handlereal = async (row) => {
        setModalShow(true)

        const cname = row.centerName;
        setCenterName(cname)
        const docname = row.voName
        setDoctorVoName(docname)

        const apiUrl = `${visitReallocation.GetAllDataUser}`;
        const response1 = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${added_by}`,
            },
        });

        const responseData1 = await response1.json();
        setCenter(responseData1)


        let idToPrint = null;

        for (let i = 0; i < responseData1.length; i++) {
            const item = responseData1[i];


            if (cname === item.Name) {
                idToPrint = item.id;
            }
        }

        const response = await fetch(`${visitReallocation.GetNameFilter}?filter=%7B%22VOFlag%22:1,%22zone%22:${idToPrint ? idToPrint : 8}%7D`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${added_by}`,
            },
        })
        const responseData = await response.json();
        setDoctorName(responseData.data);

    }

    const handleSubCenter = async (val) => {
        console.log(val);
        setCenterName(val)
        let cname = val;



        let idToPrint = null;
        for (let i = 0; i < reallocationData.length; i++) {
            const item = reallocationData[i];

            if (item.centerName === cname) {
                idToPrint = item.centerId;
            }
        }
        const response = await fetch(`${visitReallocation.GetNameFilter}?filter=%7B%22VOFlag%22:1,%22zone%22:${idToPrint ? idToPrint : 8}%7D`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${added_by}`,
            },
        })
        const responseData = await response.json();
        setDoctorName(responseData.data)


    }
    const handleDocName = (val) => {

        setDoctorVoName(val)
    }
    const handleRefresh = async () => {
        const response = await getVisitReallocationData({
            columnNameSort: "",
            filterString: "",
            inputDate: date,
            limit: "",
            offset: "18",
            searchString: "",
            sortOrder: "",
            sql: "",
        });
        if (response) {

            setReallocationData(response);

        }
    }




    const StatusData = [
        { value: 'All' },
        { value: 'Pending' },
        { value: 'Completed' },
        { value: 'Reallocated' },
        { value: 'Canclelled' },

    ];


    const SearchOnData = [
        { value: 'All' },
        { value: 'visitID' },
        { value: 'complaintCode' },
        { value: 'contactNo' },
        { value: 'farmerMobile' },
        { value: 'farmerName' },
        { value: 'routeCode' },
        { value: 'routeName' },
        { value: 'dscCode' },
        { value: 'dscName' },
        { value: 'centerName' },

    ];




    const colum = [
        {
            name: "Reallocate",
            selector: "Status",
            cell: (row) => {

                const status = row.Status;
                if (status === "Pending") {
                    return (
                        <div className={`${status}`}>
                            <RxCountdownTimer className='style-icon' style={{ cursor: "pointer" }} onClick={() => handlereal(row)} />
                        </div>
                    );
                } else if (status === "Completed") {
                    return (
                        <div className={`${status}`}>
                            <IoMdDownload className='style-icon' onClick={() => handelpdf(row)} style={{ cursor: "pointer" }} /> <MdModeEditOutline className='style-icon' onClick={() => handleEdit()} style={{ cursor: "pointer" }} />
                        </div>
                    );
                }
            },
        },
        {
            name: "Status",
            selector: "Status",

            cell: (row) => {

                const status = row.Status;


                if (status === "Pending") {
                    return (
                        <div className={`status-Realcell ${status}`}>
                            <span style={{
                                textAlign: 'center',
                                width: '98px'
                            }}> {status}</span>
                        </div>
                    );
                } else if (status === "Completed") {
                    return (
                        <div className={`status-Realcell ${status}`}>
                            <span style={{
                                textAlign: 'center',
                                width: '98px'
                            }}> {status}</span>
                        </div>
                    );
                } else if (status === "Reallocated") {
                    return (
                        <div className={`status-Realcell ${status}`}>
                            <span style={{
                                textAlign: 'center',
                                width: '98px'
                            }}> {status}</span>
                        </div>
                    );
                } else if (status === "Cancelled") {
                    return (
                        <div className={`status-Realcell ${status}`}>
                            <span style={{
                                textAlign: 'center',
                                width: '98px'
                            }}>{status}</span>
                        </div>
                    );
                }
            },

        },
        {
            name: "VisitID",
            selector: "visitID",
            sortable: true

        },
        {
            name: "ComplaintCode",
            selector: "complaintCode",
            sortable: true

        },

        {
            name: "VisitType",
            selector: "visitType",
            width: "150px",
            sortable: true

        },
        {
            name: "ContactNo",
            selector: "contactNo",
        },
        {
            name: "FarmerCode",
            selector: "farmerCode",
            sortable: true

        },
        {
            name: "FarmerName",
            selector: "farmerName",
            sortable: true

        },
        {
            name: "FarmerMobileNumber",
            selector: "farmerMobileNumber",
            sortable: true

        },
        {
            name: "RouteCode",
            selector: "routeCode",
            sortable: true

        },

        {
            name: "DcsCode",
            selector: "dcsCode",
            sortable: true

        },
        {
            name: "CenterName",
            selector: "centerName",
            sortable: true

        },
        {
            name: "RouteName",
            selector: "routeName",
            sortable: true

        },
        {
            name: "DcsName",
            selector: "dcsName",
            sortable: true

        },
        {
            name: "VoName",
            selector: "voName",
            sortable: true

        },
        {
            name: "VisitTime",
            selector: "visitTime",
            sortable: true

        },
        {
            name: "WaitingTime",
            selector: "waitingTime",
            sortable: true

        },
        {
            name: "CreatedBy",
            selector: "createdBy",
            sortable: true

        },
        {
            name: "LastUpdatedBy",
            selector: "lastUpdatedBy",
            sortable: true

        },

        {
            name: "VisitTypeName",
            selector: "visitTypeName",
            sortable: true

        },

        {
            name: "VisitTypeId",
            selector: "visitTypeId",
            sortable: true

        },

    ];


    return (
        <>
            <Container fluid>
                <Row> {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Visit Management / Visit Reallocation</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-end p-2"  >

                                {/* <Col xl="1" md="5" className='d-flex'>
                                    <button className="btn-blue-Animal addBtn me-2" style={{ cursor: "pointer" }} onClick={() => handleRefresh()}><RxCountdownTimer style={{ color: "white", fontSize: "25px" }} /></button>
                                </Col> */}

                                <Col xl="1" md="5" className='d-flex'>
                                    <button className="btn-blue-Animal addAIBtn me-2" onClick={handleDownloadExcel}>Export</button>
                                </Col>




                                <Col md="3" xxl="2" xl="2" className='justify-content-end ms-auto'>
                                    <div>
                                        <sub className="mb-0 pb-0">Date</sub>
                                        <input type="date" className="dateSelect_bg" value={date} onChange={(e) => { setDate(e.target.value); handleDate(e.target.value); }} defaultValue={selectedToDate} />
                                    </div>
                                </Col>
                            </Row>
                            {/* <Row className="mt-2">
                <Col>
                  <Table columns={colum} data={reallocationData} height={`calc(100vh - (${heightOffSet}px + 215px))`} pagination />
                </Col>
              </Row> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {reallocationData.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={reallocationData} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>


            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShowVisitData} onHide={() => setModalShowVisitData(false)} >
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Edit Visit Data</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShowVisitData(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xl="5" >
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel>Select Table Object's you want to update</InputLabel>
                                    <Select value={visitdata} onChange={(e) => handleVisitData(e.target.value)}>
                                        <MenuItem value="CallCenterService">CallCenterService</MenuItem>
                                        <MenuItem value="AnimalDetails">Animal Details</MenuItem>
                                        <MenuItem value="Treatment">Treatment</MenuItem>
                                        <MenuItem value="TreatmentDetails">Treatment Details</MenuItem>

                                    </Select>
                                </FormControl>

                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-between">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} >{editId ? "Update" : "Save"}</button>
                            </Col>
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShowVisitData(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            {/* -------------------------------RealModal-------------------------------------------- */}

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)} >
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Visit Reallocation</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xl="5" >
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel>Sub Center</InputLabel>
                                    <Select value={centerName} onChange={(e) => handleSubCenter(e.target.value)}>
                                        <MenuItem value={"All"}>All</MenuItem>
                                        {center.map(v => (
                                            <MenuItem key={v.id} value={v.Name} className="drop_value">
                                                {v.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </Col>
                            <Col xl="7" >
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel>Doctor*</InputLabel>
                                    <Select value={doctorVoName} onChange={(e) => handleDocName(e.target.value)}>

                                        {
                                            doctorName.map((v) =>
                                                <MenuItem key={v.Name} value={v.Name} className="drop_value">
                                                    {v.Name}
                                                </MenuItem>
                                            )}
                                    </Select>
                                </FormControl>

                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-between">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} >{editId ? "Update" : "Save"}</button>
                            </Col>
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>



            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>

    )
}

export default Visit_Reallocation_User

