import React, { useEffect, useState } from 'react'
import './login.css'
import { Col, Container, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { STORAGE_KEY } from '../constant/common'
import { API_URLS } from '../API/Api'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { ToastContainer, toast } from 'react-toastify'
import { GoogleLogin } from '@react-oauth/google'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { jwtDecode } from 'jwt-decode'

const { LoginAPI } = API_URLS

async function loginUser (credentials) {
  return fetch(LoginAPI.login, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
    body: JSON.stringify(credentials)
  }).then(data => data.json())
}

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [username, setUsername] = useState('')
  const [group, setgroup] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [email, setEmail] = useState('')

  const currentUrl = location.pathname
  localStorage.setItem('path', currentUrl)

  const loginFn = async () => {
    if (username === '') {
      toast.error('Please Enter Username')
    } else if (password === '') {
      toast.error('Please Enter Password')
    } else {
      setIsLoading(true)
      setLoadingProgress(0)
      let progressInterval = setInterval(() => {
        setLoadingProgress(prev => {
          if (prev < 90) return prev + 10
          clearInterval(progressInterval)
          return 90
        })
      }, 100)

      try {
        const res = await loginUser({ username, password })
        clearInterval(progressInterval)
        setLoadingProgress(100)
        console.log('res.user.group',res.user.group)
        // setgroup(res.user.group)
        localStorage.setItem('USERID', res.user.id)
        localStorage.setItem('GROUP',res.user.group)
        if (res.token) {
          localStorage.setItem(STORAGE_KEY.jwtTOKEN, res.token)
          localStorage.setItem('USERNAME', username)
          toast.success('LogIn Successful')
          navigate('/Overview')
        }
      } catch (error) {
        clearInterval(progressInterval)
        setLoadingProgress(100)
        toast.error('Username or password Not Found')
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <>
      <Container fluid className='login-main-bg pt-5'>
        <Row className='mt-5 text-center align-items-center justify-content-center'>
          <Col xl='3' className='login-sub'>
            <Row className='mt-4'>
              <Col>
                <h2>HERDMAN</h2>
                <p>Sign in to your account</p>
              </Col>
            </Row>
            <Row className='mt-4 justify-content-center'>
              <Col xl='10'>
                <input
                  type='text'
                  placeholder='Username'
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  className='ps-3'
                />
              </Col>
            </Row>
            <Row className='mt-3 justify-content-center'>
              <Col xl='10'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Password'
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  className='ps-3 passInput'
                />
                <div className='eyeImg' onClick={handleTogglePassword}>
                  {!showPassword ? (
                    <FaEyeSlash size={20} />
                  ) : (
                    <FaEye size={20} />
                  )}
                </div>
                <p className='text-end'>Forget Password?</p>
              </Col>
            </Row>
            <Row className='justify-content-center'>
              <Col xl='10' className='text-center'>
                <div className='my-3 d-flex align-items-center justify-content-center'>
                  <hr style={{ width: '45%', border: '1px solid #d1d1d1' }} />
                  <span className='mx-3 fs-6' style={{ color: '#b3b1b1' }}>
                    OR
                  </span>
                  <hr style={{ width: '45%', border: '1px solid #d1d1d1' }} />
                </div>
              </Col>
            </Row>
            <Row className='mt-1 mb-5 justify-content-center'>
              <Col xl='10' className='d-flex justify-content-center'>
                <GoogleOAuthProvider clientId='15032858165-ueqpd8nptbtbdhfn7mnuj0s0747t4prv.apps.googleusercontent.com'>
                  <GoogleLogin
                    onSuccess={async credentialResponse => {
                      const { credential } = credentialResponse
                      const decodedToken = jwtDecode(credential)

                      const email = decodedToken.email
                      setEmail(email)

                      await loginUser({
                        username: email,
                        password: 'welcome@123'
                      })
                        .then(res => {
                          if (res.token) {
                            localStorage.setItem(
                              STORAGE_KEY.jwtTOKEN,
                              res.token
                            )
                            localStorage.setItem('USERID', res.user.id)
                            localStorage.setItem('USERNAME', email)
                            toast.success('LogIn Successful')
                            navigate('/Overview')
                          } else {
                            toast.error('Login Failed')
                          }
                        })
                        .catch(() => {
                          toast.error('Username or password Not Found')
                        })
                    }}
                    onError={() => {
                      console.log('Login Failed')
                    }}
                  />
                </GoogleOAuthProvider>
              </Col>
            </Row>
            <Row className='mt-3 mb-5 justify-content-center'>
              <Col xl='10'>
                <button onClick={loginFn}>Sign In</button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {isLoading && (
        <div className='circleText'>
          <div className='progressText'>{loadingProgress + `%`}</div>
          <div className=''>
            <span className='loader-circle-13'></span>
          </div>
        </div>
      )}
      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
        style={{ fontSize: '14px' }}
      />
    </>
  )
}

export default Login
