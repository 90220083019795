import { Checkbox, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './visit_registration.css';
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Col, Container, Row, Offcanvas, Accordion, Modal } from "react-bootstrap";
import Input from "../../Componet/InputFild/Input";
import Sidenav from '../../Componet/Sidenav/Sidenav';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import Table from '../../Componet/DataTable/Table';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { IoMdArrowDropdown } from "react-icons/io";
import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment/moment';
import { RxCountdownTimer } from "react-icons/rx";
import Searcable from '../../Componet/Dropdown/Searcable';

import Selectdropdown from 'react-select'
const { visitRegistration } = API_URLS;



const label = { inputProps: { "aria-label": "Switch demo" } };

const Visit_Registration = () => {

  const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
  const user_id  = localStorage.getItem('USERID')
  const navigate = useNavigate()
  const logOut = () => { navigate("/") }

  // async function addData(credentials) {
  //   return fetch(CC.AddDetails, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       "Accept": "application/json",
  //       "Authorization": `Bearer ${added_by}`,
  //     },
  //     body: JSON.stringify(credentials)
  //   })
  //     .then(res => res.json());
  // }
  // async function getVisitData(credentials) {

  //   const response = await fetch(`${visitRegistration.GetVoVisitRegistration}/reportNew?offset=0&limit=100&column_name=id&sort_order=desc&search_term=&date_filter=${date ? date : ''}&VO=1`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json',
  //       'Authorization': `Bearer ${added_by}`,
  //     },
  //     body: JSON.stringify(credentials),
  //   })
  //     .then(response => response.json())
  //   if (response) {
  //     const { data, summaryCount, total } = response;


  //     const pendingCount = summaryCount.find(item => item.status === null)?.currentCount || 0;
  //     setPending(pendingCount);
  //     const completedCount = summaryCount.find(item => item.status === "C")?.currentCount || 0;
  //     setComplated(completedCount);
  //     const cancelledCount = summaryCount.find(item => item.status === "N")?.currentCount || 0;
  //     setCancelled(cancelledCount)
  //     const realCount = summaryCount.find(item => item.status === "R")?.currentCount || 0;
  //     setReallocated(realCount)
  //     setVvList(response.data)
  //   }
  // }
  async function getVisitData(credentials) {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${visitRegistration.GetVoVisitRegistration}/reportNew?offset=0&limit=100&column_name=id&sort_order=desc&search_term=&date_filter=${date ? date : ''}&VO=1`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${added_by}`,
          },
        }
      );

      if (response.ok) {
        const contentLength = +response.headers.get('content-length'); // Convert to a number
        let loaded = 0;
        const reader = response.body.getReader();

        setVvList([]);

        const result = await new Response(
          new ReadableStream({
            async start(controller) {
              async function read() {
                const { done, value } = await reader.read();
                if (done) {
                  controller.close();
                  setIsLoading(false);
                  return;
                }

                loaded += value.byteLength;
                const percentageComplete = Math.round((loaded / contentLength) * 100) + '%';
                setProgressData(percentageComplete);

                controller.enqueue(value);
                await read();
              }

              read();
            },
          })
        ).json();

        const { summaryCount } = result;

        const pendingCount = summaryCount.find(item => item.status === null)?.currentCount || 0;
        setPending(pendingCount);
        const completedCount = summaryCount.find(item => item.status === 'C')?.currentCount || 0;
        setComplated(completedCount);
        const cancelledCount = summaryCount.find(item => item.status === 'N')?.currentCount || 0;
        setCancelled(cancelledCount);
        const realCount = summaryCount.find(item => item.status === 'R')?.currentCount || 0;
        setReallocated(realCount);
        setVvList(result.data);
      } else {
        console.error('Error fetching data:', response.status, response.statusText);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setIsLoading(false);
    }
  }


  const API_KEY = serviceUrl;
  // const API_KEY = "https://hais.hap.in";

  const [code, setCode] = useState("")
  const [name, setName] = useState("")
  const [farmerId, setfarmerId] = useState(null)
  const [farmercode , setfarmerCode] = useState(null)
  const [voId, setVoId] = useState(null) 
  const [voname,setVoName] = useState('')
   const [mobile, setMobile] = useState("")
  const [remark,setRemark] = useState("")
  const [species ,Setspecies] = useState(null)
  const [staffMobile, setStaffMobile] = useState("")
  const [search, setSearch] = useState("")
  const [editId, setEditId] = useState("")
  const [searchFilter, setSearchFilter] = useState("");
  const [progressData, setProgressData] = useState("0%");
  const [searchItems, setSearchItems] = useState("")
  const [searchData, setSearchData] = useState("")
  const [heightOffSet, setHeight] = useState(0);
const [farmernumber,setfarmernumber] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [headerShow, setHeaderShow] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false); }
  const handleShow = () => { setHeaderShow(!headerShow); }
  const [modalShow, setModalShow] = useState(false);
  const [modalShowAIPD, setModalShowAIPD] = useState(false)
  const [isSuspended, setIsSuspended] = useState(false)
  const [editToggle, setEditToggle] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [routeList, setRouteList] = useState([])
  const [route, setRoute] = useState()
  const [ccList, setCcList] = useState([])
  const [vvList, setVvList] = useState([]);
  const [completecolor, setCompletecolor] = useState(false);
  const [addVisitTime, setAddVisitTime] = useState("")
  const [dateOfAddVisit, setDateOfAddVisit] = useState("")
  const [visitRateAddvisitDeafult, setVisitRateAddvisitDeafult] = useState("")
  const [visitRateAddvisit, setVisitRateAddvisit] = useState(visitRateAddvisitDeafult)
  const [visitTypeAddVisit, setVisitTypeAddVisit] = useState([])
  const [selectedValue, setSelectedValue] = useState(18);
  const [animalTypeAddVisit, setAnimalTypeAddVisit] = useState([])
  const [dscCode, setDscCode] = useState("")
  const [farmerCodeAddVist, setFarmerCodeAddVist] = useState("")
  const [aIPDAddress, setAIPDAddress] = useState("")
  const [lotidAddVisit, setLotidAddVisit] = useState("")
  const [farmerNameAddVisit, setfarmerNameAddVisit] = useState([])
  const [getIdAddVisit, setGetIdAddVisit] = useState([])
  const [centerNameAddVisit, setCenterNameAddVisit] = useState("")
  const [voNameAddVisit, setVoNameAddVisit] = useState([])
  const [complaintName, setComplaintName] = useState([])
  const [sortedSemenChecked, setSortedSemenChecked] = useState(false);
  const [aIPDVisitRateAddvisit, setAIPDVisitRateAddvisit] = useState()
  const [animalId, setAnimalId] = useState(null)
  const [animalidvisit, setAnimalidvisit] = useState(null)
  const [aITName, setAITName] = useState([])
  const [modalShowVisitDetails, setModalShowVisitDetails] = useState(false)
  const [selectedVisitID, setSelectedVisitID] = useState(null);
  const [visitDetails, setVisitDetails] = useState([])

  const selectedToDate = new Date().toISOString().slice(0, 10);
  const [date, setDate] = useState(selectedToDate);
  const [pending, setPending] = useState("")
  const [complated, setComplated] = useState("")
  const [cancelled, setCancelled] = useState("")
  const [reallocated, setReallocated] = useState("")





  const ItemsData = [
    { value: '100' },
    { value: '200' },
    { value: '500' },
    { value: '1000' },
    { value: '2000' },
    { value: '5000' },
  ];


  useEffect(() => {
    let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
    setHeight(divHeight);
    getVisitData();
  }, [])

  // const handleChange = async (e, d) => {

  //   setIsSuspended(e.target.checked)
  //   setIsLoading(true)

  //   await fetch(`${CC.SuspendApi}?id=${d.id}&isSuspended=${e.target.checked}`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       "Accept": "application/json",
  //       "Authorization": `Bearer ${added_by}`,
  //     },
  //   })
  //     .then(response => {
  //       const contentLength = response.headers.get('content-length');
  //       let loaded = 0;
  //       return new Response(
  //         new ReadableStream({
  //           start(controller) {
  //             const reader = response.body.getReader();
  //             read();
  //             function read() {
  //               reader.read()
  //                 .then((progressEvent) => {
  //                   if (progressEvent.done) {
  //                     controller.close();
  //                     return;
  //                   }
  //                   loaded += progressEvent.value.byteLength;
  //                   const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
  //                   setProgressData(percentageComplete)
  //                   controller.enqueue(progressEvent.value);
  //                   read();
  //                 })
  //             }
  //           }
  //         })
  //       );
  //     })
  //     .then((res) => res.json())
  //     .then((result) => { toast.success("Success suspended status changed. "); setIsLoading(false); setProgressData("0%"); })
  // }
  const handlesave = async () => {
    console.log("hiii");
    console.log("void" ,voId)
    // Define payload with relevant fields to send to the backend
    const payload = {
      RequestType:selectedValue , // Example value, adjust based on the actual request type
      farmer: farmerId, // Example farmer ID
      farmerComplaint: farmercode, // Example complaint ID
      animalTagId: animalidvisit, // Example animal tag ID
      Staff: voId, // Example staff ID
      UID: user_id, // Example UID
      species: species, // Example species ID
      Remark: remark, // Example remark
      createdByUser: user_id, // Example user ID of creator
      receiptno:0, // Example receipt number
      farmerMobile: farmernumber// Example mobile number
    };
  console.log("payload",payload)
  // return
    const apiurl = `${visitRegistration.SaveVOVisitRegistration}`;
  
    // Send the API request
    const response = await fetch(apiurl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`, // Adjusted authorization token
      },
      body:JSON.stringify(payload) , // Send the payload as JSON
    });
  
    // Handle the response if needed
    if (response.ok) {
      const result = await response.json();
      console.log("Data saved successfully:", result);
    } else {
      console.log("Data saved successfully:", response.statusText);
      console.error("Error saving data:", response.statusText);
    }
  };
  


  const Add = async () => {
    setModalShow(true)
    setEditId("")
    setCode("")
    setName("")
    setMobile("")
    setStaffMobile("")

    const now = new Date();


    const day = now.getDate();
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(now);
    const year = now.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    setDateOfAddVisit(formattedDate);

    const hours = now.getHours();
    const minutes = now.getMinutes();
    const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    setAddVisitTime(formattedTime)


    const apiUrl = `${visitRegistration.GetVisitRateTimeAddVisit}?time=${formattedTime}&dcsCode=&visit_type=18`;

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },
      // body: JSON.stringify(credentials), 852
    });

    const responseData = await response.json();

    responseData.map((v) => {

      setVisitRateAddvisit(v.VisitRate);
    })

    const response2 = await fetch(visitRegistration.GetVisitTypeDataAddVisit, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },

    });
    const responseData2 = await response2.json();
    if (responseData2) {

      setVisitTypeAddVisit(responseData2.data)
    }

    const response1 = await fetch(visitRegistration.GetAllDataofModalAddVisit, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },

    });
    const responseData1 = await response1.json();


    setAnimalTypeAddVisit(responseData1)
    console.log(responseData1);


  }
  const AddAIPD = async () => {
    setModalShowAIPD(true)

    const now = new Date();


    const day = now.getDate();
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(now);
    const year = now.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    setDateOfAddVisit(formattedDate);



    const hours = now.getHours();
    const minutes = now.getMinutes();
    const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    setAddVisitTime(formattedTime)


    const apiUrl1 = `${visitRegistration.GetAIPDAllData}?limit=10000&filter=%7B%22id%22:13%7D`;

    const response1 = await fetch(apiUrl1, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },

    });

    const responseData1 = await response1.json();

    const response2 = await fetch(visitRegistration.GetAllDataofModalAddVisit, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },

    });
    const responseData2 = await response2.json();
    setAnimalTypeAddVisit(responseData2);
    console.log(responseData2);


    const response3 = await fetch(visitRegistration.GetVisitTypeDataAddVisit, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },

    });
    const responseData3 = await response3.json();
    setVisitTypeAddVisit(responseData3.data)


    const apiUrl = `${visitRegistration.GetVisitRateTimeAddVisit}?time=${formattedTime}&dcsCode=${dscCode ? dscCode : ""}&visit_type=18`;
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },
      // body: JSON.stringify(credentials), 852
    });
    const responseData = await response.json();
    responseData.map((v) => {

      setAIPDVisitRateAddvisit(v.VisitRate);

    })






  }

  const updateFn = (e) => {

    setEditId(e.id)
    setCode(e.code);
    setName(e.Name);
    setMobile(e.Mobile);
    setStaffMobile(e.staff.Mobile);
    setIsSuspended(e.IsSuspended)
    setModalShow(true);

  };
  const handleSearchItems = async (e) => {

    setSearchItems(e)


    const apiUrl = `${visitRegistration.GetVoVisitRegistration}/reportNew?offset=0&limit=${e ? e : 100}&column_name=id&sort_order=desc&search_term=${searchData ? searchData : ''}&date_filter=${date ? date : ''}&VO=0`

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },

    });

    const responseData = await response.json();
    if (responseData) {
      setVvList(responseData.data);
    }
  }

  const setDateFn = async (date) => {
    setIsLoading(true)
    setDate(date)
    const apiUrl = `${visitRegistration.GetVoVisitRegistration}/reportNew?offset=0&limit=${searchItems ? searchItems : 100}&column_name=id&sort_order=desc&search_term=${searchData ? searchData : ''}&date_filter=${date ? date : ''}&VO=0`;

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },
      // body: JSON.stringify(credentials), 852
    });

    const responseData = await response.json();
    if (responseData) {
      setVvList(responseData.data);
      setIsLoading(false)
    }

  }

  const SearchFn = async (e) => {
    setIsLoading(true);
    const data = e.target.value;
    setSearchData(data);

    const apiUrl = `${visitRegistration.GetVoVisitRegistration}/reportNew?offset=0&limit=${searchItems ? searchItems : 100}&column_name=id&sort_order=desc&search_term=${data ? data : ''}&date_filter=${date ? date : ''}&VO=1`;

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${added_by}`,
        },
      });

      if (response) {
        const contentLength = +response.headers.get('content-length');
        let loaded = 0;

        const reader = response.body.getReader();

        setVvList([]);
        setProgressData('0%');

        const result = await new Response(
          new ReadableStream({
            async start(controller) {
              async function read() {
                const { done, value } = await reader.read();
                if (done) {
                  controller.close();
                  setIsLoading(false);
                  return;
                }

                loaded += value.length;
                const percentageComplete = Math.round((loaded / contentLength) * 100) + '%';
                console.log(percentageComplete);
                setProgressData(percentageComplete);

                controller.enqueue(value);
                await read();
              }

              read();
            },
          })
        ).json();

        if (result) {
          setVvList(result.data);
        }
      } else {
        console.error('Error fetching data:', response.status, response.statusText);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setIsLoading(false);
    }
  };



  //  const SearchFn = async (e) => {
  //   setIsLoading(true)
  //   const data = e.target.value;
  //   setSearchData(data);

  //   const apiUrl = `${visitRegistration.GetVoVisitRegistration}/reportNew?offset=0&limit=${searchItems ? searchItems : 100}&column_name=id&sort_order=desc&search_term=${data ? data : ''}&date_filter=${date ? date : ''}&VO=1`;

  //   const response = await fetch(apiUrl, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json',
  //       'Authorization': `Bearer ${added_by}`,
  //     },

  //   });

  //   const responseData = await response.json();
  //   setIsLoading(false)
  //   if (responseData) {
  //     setVvList(responseData.data)
  //   }

  // };
  const FarmerMobile = async (number) => {
    setfarmernumber(number);
    const apiUrl = `${visitRegistration.GetMobileNumberAddVisit}?filter=%7B%22mobile%22:%22${number}%22%7D`;

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },

    });
    const responseData = await response.json();
    setfarmerNameAddVisit(responseData)
    let idToPrint, dcscode, visitType;;
    console.log("responseData",responseData)
    const res = responseData.map((v) => {
      dcscode = v.DCSCode
      setDscCode(dcscode)
      setFarmerCodeAddVist(v.code)
      setAIPDAddress(v.Address)

      const id = v.lot && v.lot.id;
      if (id) {
        idToPrint = id;

        setLotidAddVisit(id);
      }
    });
    const apiUrl1 = `${visitRegistration.GetIdAddVisit}?id=${idToPrint ? idToPrint : ""}`;

    const response1 = await fetch(apiUrl1, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },

    });
    const responseData1 = await response1.json();
    console.log("responseData1",responseData1)
    setGetIdAddVisit(responseData1)
    let zone;
    let herd;
    const res2 = responseData1.map((v) => {
      zone = v.zone;
      herd = v.herd;
      const obj = v.zoneObj && v.zoneObj.Name
      setCenterNameAddVisit(obj);

    })
    const apiUrl3 = `${visitRegistration.GetTimeAddVisit}/vo_by_time?time=${addVisitTime}&herd=${herd}&zone=${zone}`;

    const response3 = await fetch(apiUrl3, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },
      // body: JSON.stringify(credentials), 852
    });
    const responseData3 = await response3.json();
    console.log("responseData3",responseData3)
    setVoNameAddVisit(responseData3);
  }

  const FarmerMobileAIPD = async (number) => {

    const apiUrl = `${visitRegistration.GetMobileNumberAddVisit}?filter=%7B%22mobile%22:%22${number}%22%7D`;

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },

    });
    const responseData = await response.json();
    setfarmerNameAddVisit(responseData)
    let idToPrint, dcscode, visitType;;

    const res = responseData.map((v) => {
      dcscode = v.DCSCode
      setDscCode(dcscode)
      setFarmerCodeAddVist(v.code)
      setAIPDAddress(v.Address)

      const id = v.lot && v.lot.id;
      if (id) {
        idToPrint = id;

        setLotidAddVisit(id);
      }
    });

    const apiUrl1 = `${visitRegistration.GetIdAddVisit}?id=${idToPrint ? idToPrint : ""}`;

    const response1 = await fetch(apiUrl1, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },
      // body: JSON.stringify(credentials), 852
    });

    const responseData1 = await response1.json();
    setGetIdAddVisit(responseData1)
    let zone;
    let herd;
    const res2 = responseData1.map((v) => {
      zone = v.zone;
      herd = v.herd;
      const obj = v.zoneObj && v.zoneObj.Name
      setCenterNameAddVisit(obj);

    })

    const apiUrl3 = `${visitRegistration.GetVisitRateTimeAddVisit}?time=${addVisitTime}&dcsCode=${dcscode}&visit_type=${selectedValue}`;
    const response3 = await fetch(apiUrl3, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },
      // body: JSON.stringify(credentials), 852
    });
    const responseData3 = await response3.json();



  }

  const handleSortedSemenChange = async (event) => {
    setSortedSemenChecked(event.target.checked);


    const sortedValue = event.target.checked ? "1" : "0";
    const payload = {
      id: lotidAddVisit ? lotidAddVisit.toString() : "",
      sorted: sortedValue,
      species: animalId ? animalId.toString() : ""
    };
    const response = await fetch(visitRegistration.GetSortedAIPD, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },
      body: JSON.stringify(payload)
    });
    const responseData = await response.json();

    setAITName(responseData)

  }

  const handleComplaintCode = async (e) => {
    const data = e.target.value;
    setfarmerCode(e.target.value)
    const apiUrl1 = `${visitRegistration.GetAIPDAllData}?limit=10000&filter=%7B%22id%22:${data}%7D`;

    const response1 = await fetch(apiUrl1, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },

    });

    const responseData1 = await response1.json();
    // console.log(responseData1.data);
    setComplaintName(responseData1.data)


  }
  const handleChange = async (event) => {

    setSelectedValue(event);
    console.log(event);

    const apiUrl = `${visitRegistration.GetVisitRateTimeAddVisit}?time=${addVisitTime}&dcsCode=${dscCode}&visit_type=${event}`;
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },
      // body: JSON.stringify(credentials), 852
    });
    const responseData = await response.json();
    responseData.map((v) => {
      setVisitRateAddvisit(v.VisitRate);
    })
  };
  const handleaddress = async (e) => {
    console.log(e);
    const response3 = await fetch(visitRegistration.GetAddressAddVisit, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },
      body: JSON.stringify({ Address: e })
    });
    const responseData3 = await response3.json();

  }
  const handleaddressADPD = async (e) => {
    console.log(e);
    const response3 = await fetch(visitRegistration.GetADPDAddress, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },
      body: JSON.stringify({ Address: e })
    });
    const responseData3 = await response3.json();

  }
  const handleAnimalType = async (e) => {
    console.log(e);

    let idprint;

    animalTypeAddVisit.forEach((v) => {
      console.log("v",v)
      if (v.Name === e) {
        idprint = v.id;

      }
    });

    const payload = {
      id: lotidAddVisit.toString(),
      sorted: sortedSemenChecked ? sortedSemenChecked : "",
      species: idprint ? idprint.toString() : "",
    };

    try {
      const response = await fetch(visitRegistration.GetSortedAIPD, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${added_by}`,
        },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();
      setAITName(responseData)
    } catch (error) {
      console.error('An error occurred:', error);
    }





  };

  const handleCellClick = async (row) => {

    setModalShowVisitDetails(true)


    const visitID = row.visitID;
    setSelectedVisitID(visitID);



    const apiUrl = `${visitRegistration.GetVisitDetails}/${visitID}`;

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },

    });

    const responseData = await response.json();

    setVisitDetails(responseData);

  };
  const handleRefresh = async () => {
    setIsLoading(true)

    const apiUrl = `${visitRegistration.GetVoVisitRegistration}/reportNew?offset=0&limit=${searchItems ? searchItems : 100}&column_name=id&sort_order=desc&search_term=${searchData ? searchData : ''}&date_filter=${date ? date : ''}&VO=1`;

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },

    });

    const responseData = await response.json();
    setVvList(responseData.data)
    setIsLoading(false)
  }

  const colum = [
    {
      name: "Status ",
      selector: "Status",
      width: '150px',
      cell: (row) => (

        <div className={`status-cell ${row.Status.toLowerCase()}`} onClick={() => handleCellClick(row)} >

          <span style={{
            textAlign: 'center',
            width: '112px',
            cursor: 'pointer'
          }}> {row.Status}</span>


        </div>

      ),
    },

    {
      name: "VisitID",
      selector: "visitID",
      cell: (row) => (
        <div onClick={() => handleCellClick(row)}>
          <span style={{
            textAlign: 'center',
            width: '104px',
            cursor: 'pointer'
          }}> {row.visitID}</span>
        </div>

      ),
      width: "130px",
      sortable: true

    },
    {
      name: "VisitType",
      selector: "visitTypeName",
      cell: (row) => (
        <div className={row.visitTypeName === "Normal Visit"
          ? "normalVisitCell"
          : row.visitTypeName === "Emergency Visit"
            ? "emergencyVisitCell"
            : "defaultVisitCell"
        } onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>
          {row.visitTypeName === "Normal Visit"
            ? row.visitTypeName.slice(0, 6)
            : row.visitTypeName === "Emergency Visit"
              ? row.visitTypeName.slice(0, 9)
              : row.visitTypeName === "Local Visit"
                ? row.visitTypeName.slice(0, 5)
                : row.visitTypeName
          }
        </div>
      ),
      width: "150px",
      sortable: true

    },

    {
      name: "CreatedTime",
      selector: "createdTime",
      cell: (row) => {
        const originalDate = new Date(row.createdTime);
        const formattedDate = `${originalDate.getDate()}/${originalDate.getMonth() + 1}/${originalDate.getFullYear().toString().slice(-2)} ${originalDate.getHours()}:${originalDate.getMinutes()}`;
        return (
          <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>
            {formattedDate}
          </div>
        );
      },
      width: "120px",
      sortable: true

    },
    {
      name: "DeliveryTime",
      selector: "deliveryTime",
      cell: (row) => {
        if (row.deliveryTime != null) {
          const originalDate = new Date(row.deliveryTime);
          const formattedDate = `${originalDate.getDate()}/${originalDate.getMonth() + 1}/${originalDate.getFullYear().toString().slice(-2)} ${originalDate.getHours()}:${originalDate.getMinutes()}`;
          return (
            <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>
              {formattedDate}
            </div>
          );
        } else {
          return (
            <>In Progress</>
          )
        }

      },
      width: "120px",
      sortable: true

    },
    {
      name: "LastUpdatedTime",
      selector: "lastUpdatedTime",
      cell: (row) => <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>{row.lastUpdatedTime == "" ? "In Progress" : row.lastUpdatedTime}</div>,
      width: "120px",
      sortable: true

    },
    {
      name: "WaitingTime",
      selector: "waitingTime",
      cell: (row) => <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>{row.waitingTime}</div>,
      width: "120px",
      sortable: true

    },
    {
      name: "ContactNo",
      selector: "contactNo",
      cell: (row) => <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>{row.contactNo}</div>,
      width: "120px",
      sortable: true

    },
    {
      name: "AnimalIDno",
      selector: "animalIDno",
      cell: (row) => <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>{row.animalIDno}</div>,
      width: "130px",
      sortable: true

    },
    {
      name: "LastUpdatedBy",
      selector: "lastUpdatedBy",
      cell: (row) => <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>{row.lastUpdatedBy}</div>,
      width: "200px",
      sortable: true

    },
    {
      name: "VoName",
      selector: "voName",
      cell: (row) => <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>{row.voName}</div>,
      width: "350px",
      sortable: true

    },
    {
      name: "FarmerName",
      selector: "farmerName",
      cell: (row) => <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>{row.farmerName}</div>,
      width: "350px",
      sortable: true

    },
    {
      name: "FarmerCode",
      selector: "farmerCode",
      cell: (row) => <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>{row.farmerCode}</div>,
      width: "120px",
      sortable: true

    },
    {
      name: "FarmerMobileNumber",
      selector: "farmerMobileNumber",
      cell: (row) => <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>{row.farmerMobileNumber}</div>,
      width: "120px",
      sortable: true

    },
    {
      name: "RouteName",
      selector: "routeName",
      cell: (row) => <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>{row.routeName}</div>,
      width: "180px",
      sortable: true

    },
    {
      name: "RouteCode",
      selector: "routeCode",
      cell: (row) => <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>{row.routeCode}</div>,
      width: "120px",
      sortable: true

    },
    {
      name: "CenterName",
      selector: "centerName",
      cell: (row) => <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>{row.centerName}</div>,
      width: "180px",
      sortable: true

    },
    {
      name: "DcsName",
      selector: "dcsName",
      cell: (row) => <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>{row.dcsName}</div>,
      width: "280px",
      sortable: true

    },
    {

      name: "DcsCode",
      selector: "dcsCode",
      cell: (row) => <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>{row.dcsCode}</div>,
      width: "120px",
      sortable: true

    },
    {

      name: "ReceiptNo",
      selector: "receiptNo",
      cell: (row) => <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>{row.receiptNo}</div>,
      width: "120px",
      sortable: true

    },
    {

      name: "Time",
      // selector: "receiptNo",
      // cell: (row) => <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>{row.receiptNo}</div>,
      width: "120px",
      sortable: true

    },
    {

      name: "CreatedBy",
      selector: "createdBy",
      cell: (row) => <div onClick={() => handleCellClick(row)} style={{ cursor: 'pointer' }}>{row.createdBy}</div>,
      width: "200px",
      sortable: true

    },



  ];




  return (
    <>
      <Container fluid>
        <Row> {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
          <Col className="header" lg={headerShow ? "10" : "12"}>
            <Container fluid>
              <Row className="pt-1 sub-header align-items-center justify-content-between">
                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                  <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                  <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                  <h5 className="ms-4 mt-2">Visit Management / Vo Visit Registration</h5>
                </Col>
                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                  <img src={profile} width={40} />
                  <div className="dropdpwn_Open">
                    <Row className="text-center align-items-center">
                      <Col xs="12" className="menuHover mb-3">
                        <Row >
                          <Col className="" xs="3">
                            <span className=""><MdOutlineVpnKey size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Change Password
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" className="menuHover" onClick={logOut}>
                        <Row>
                          <Col className="" xs="3">
                            <span className=""><GoSignOut size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Sign Out
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-end p-2" id='heightOffsetStock' >

                <Col xl="1" md="5" className='d-flex'>
                  <button className="btn-blue-Animal addBtn me-2" onClick={Add}>Add Visit</button>
                </Col>

                <Col xl="2" md="5" className='d-flex'>
                  <button className="btn-blue-Animal addAIBtn me-2" onClick={AddAIPD}>Add AI-PD Request</button>
                </Col>
                <Col xl="6" md="5" className='d-flex'>

                  <button className="btn-blue-Animal pendingBtn me-2">Pending: {pending}</button>
                  <button className="btn-blue-Animal complateBtn me-2">Completed:{complated}</button>
                  <button className="btn-blue-Animal cancelBtn me-2">Cancelled:{cancelled} </button>
                  <button className="btn-blue-Animal realloactBtn me-2">Reallocated: {reallocated} </button>
                  <button className="btn-blue-Animal addBtn me-2" style={{ cursor: "pointer" }} onClick={() => handleRefresh()}><RxCountdownTimer style={{ color: "white", fontSize: "25px" }} /></button>
                </Col>
                <Col xxl="1" xs="6" sm="2" md="2" className='justify-content-start'>
                  <FormControl variant="standard" fullWidth>
                    {/* <InputLabel>Items</InputLabel>
                    <Select value={searchItems} onChange={(e) => handleSearchItems(e.target.value)}>

                      <MenuItem value={"100"}>
                        100
                      </MenuItem>
                      <MenuItem value={"200"}>
                        200
                      </MenuItem>
                      <MenuItem value={"500"}>
                        500
                      </MenuItem>
                      <MenuItem value={"1000"}>
                        1000
                      </MenuItem>
                      <MenuItem value={"2000"}>
                        2000
                      </MenuItem>
                      <MenuItem value={"5000"}>
                        5000
                      </MenuItem>

                    </Select> */}
                    <Searcable
                      placeholder="Items"
                      onChange={(selectedOption) => handleSearchItems(selectedOption.value)}
                      options={ItemsData}
                      labelKey="value"
                      valueKey="value"
                    />
                  </FormControl>
                </Col>
                <Col md="3" xxl="2" xl="2" className="">
                  <div>
                    <sub className="mb-0 pb-0">Date</sub>
                    <input type="date" className="dateSelect_bg" value={date} onChange={(e) => { setDateFn(e.target.value) }} />
                  </div>
                </Col>
                <Col md="3" xxl="2" xl="2" xs="6" className='ms-auto align-items-center visitSearch'>
                  <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                </Col>
                {/* <Col xxl="2" lg="3" md="4" sm="5" className="d-flex justify-content-end">
                  <Input label="Search" />
                </Col> */}
              </Row>

              {/* <Row className="mt-2">
                <Col>
                  <Table columns={colum} data={vvList} height={`calc(100vh - (${heightOffSet}px + 215px))`} pagination />
                </Col>
              </Row> */}

              <Row className="mt-2" >
                {vvList.length > 0 ?
                  <div className="blue_bg DataTableBorder mt-1">
                    <Table columns={colum} data={vvList} height={`calc(100vh - (${heightOffSet}px + 120px))`} pagination />
                  </div>
                  :
                  <Row>
                    <Col>
                      <div className="NoTableBorder ms-2" >
                        <div className="d-flex tabledata colmBreed">{
                          colum.map((i) => {
                            return (
                              <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                            )
                          })
                        }
                        </div>
                        <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                      </div>
                    </Col>
                  </Row>
                }
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>



      <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header style={{ background: "#DFDFDF" }}>
          <Modal.Title id="contained-modal-title-vcenter">Add New Visit</Modal.Title>
          <img src={closeButton} onClick={() => setModalShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xl="3">
                <TextField label="Farmer Mobile" id="standard-basic" fullWidth variant="standard" value={farmernumber} onChange={(e) => FarmerMobile(e.target.value)} />
              </Col>
              <Col xl="3" >
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Vo</InputLabel>
                  {/* <Select>
                    {voNameAddVisit.map((v) => (
                      <MenuItem key={v.Name} value={v.Name}>
                        {v.Name}
                      </MenuItem>
                    ))}
                  </Select> */}
                  <Selectdropdown
                                  className='mt-3'
                                  placeholder={
                                    voname
                                      ? voname
                                      : 'Select Sire ID'
                                  }
                                  value={voname}
                                  onChange={selectedOption => {
                                    setVoId(selectedOption.value)
                                    setVoName(selectedOption.label)
                                  }}
                                  options={voNameAddVisit?.map(item => ({
                                    value: item.id,
                                    label: item.Name
                                  }))}
                                />
                </FormControl>

              </Col>
              <Col xl="3">
                <TextField label="Request Time" id="standard-basic" fullWidth variant="standard" value={`${dateOfAddVisit} ${addVisitTime}`} disabled />
              </Col>
              <Col xl="3">
                <TextField label="Visit Arrival Time" id="standard-basic" fullWidth variant="standard" value={addVisitTime} />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xl="4">
                <TextField label="DSC No" id="standard-basic" fullWidth variant="standard" value={dscCode} />
              </Col>
              <Col xl="4">
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Society</InputLabel>
                  <Select >
                    {getIdAddVisit.map((v) => (
                      <MenuItem key={v.Name} value={v.id}  onClick={() => setVoId(v.id)}>
                        {v.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
              <Col xl="4">
                <TextField label="Center" id="standard-basic" fullWidth variant="standard" value={centerNameAddVisit} />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xl="6">
                <TextField label="Farmer Code" id="standard-basic" fullWidth variant="standard" value={farmerCodeAddVist} />
              </Col>
              <Col xl="6">
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Farmer</InputLabel>
                  <Select>
                    {farmerNameAddVisit.map((v) => (
                      <MenuItem key={v.Name} value={v.Name}   onClick={() => setfarmerId(v.id)}>
                        {v.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

            </Row>
            <Row className="mt-4">
              <Col xl="4">
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Animal Type</InputLabel>
                  <Select>
                    {animalTypeAddVisit.map((v) => (
                      <MenuItem key={v.Name} value={v.Name}>
                        {v.Name}
                      </MenuItem>
                    ))}
                  </Select>

                </FormControl>
              </Col>
              <Col xl="4">
                <TextField label="Animal Id" id="standard-basic" fullWidth variant="standard"  value={animalidvisit} onChange={(e) => setAnimalidvisit(e.target.value)}/>
              </Col>
              <Col xl="4">
                <TextField label="Species" fullWidth variant="standard" value={species} onChange={(e) => Setspecies(e.target.value)} />
              </Col>

            </Row>
            <Row className="mt-4">
              <Col xl="6">
                <TextField label="Complaint Code" id="standard-basic" fullWidth variant="standard" onChange={(e) => handleComplaintCode(e)} />
              </Col>
              <Col xl="6">
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Complaint</InputLabel>
                  <Select>
                    {complaintName.map((v) => (
                      <MenuItem key={v.Name} value={v.Name}>
                        {v.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

            </Row>
            <Row className="mt-4">
              <Col xl="6">
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Visit Type*</InputLabel>
                  <Select value={selectedValue} onChange={(e) => handleChange(e.target.value)}>
                    {
                      visitTypeAddVisit.map((v) => (
                        <MenuItem key={v.id} value={v.id} >
                          {v.Name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Col>
              <Col xl="6">
                <TextField label="Visit Rate" id="standard-basic" fullWidth variant="standard" value={visitRateAddvisit} disabled />
              </Col>

            </Row>
            <Row className="mt-4">
              <Col xl="6">
                <TextField label="Visit Address" fullWidth variant="standard" value={aIPDAddress} onChange={(e) => handleaddress(e.target.value)} />
              </Col>
              <Col xl="6">
                <TextField label="Remarks" id="standard-basic" fullWidth variant="standard" value={remark}  onChange={(e) => setRemark(e.target.value)}  />
              </Col>

            </Row>

          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-between">
              <Col xl="2">
                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={()=> handlesave()} >{editId ? "Update" : "Save"}</button>
              </Col>
              <Col xl="2">
                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
      <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShowAIPD} onHide={() => setModalShowAIPD(false)}>
        <Modal.Header style={{ background: "#DFDFDF" }}>
          <Modal.Title id="contained-modal-title-vcenter">Add New AI-PD Request</Modal.Title>
          <img src={closeButton} onClick={() => setModalShowAIPD(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xl="4">
                <TextField label="Farmer Mobile" id="standard-basic" fullWidth variant="standard" onChange={(e) => FarmerMobileAIPD(e.target.value)} />
              </Col>

              <Col xl="4">
                <TextField label="Request Time" id="standard-basic" fullWidth variant="standard" value={`${dateOfAddVisit} ${addVisitTime}`} />
              </Col>
              <Col xl="4">
                <TextField label="Visit Arrival Time" id="standard-basic" fullWidth variant="standard" value={addVisitTime} />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xl="4">
                <TextField label="DSC No" id="standard-basic" fullWidth variant="standard" value={dscCode} />
              </Col>
              <Col xl="4">
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Society</InputLabel>
                  <Select>
                    {getIdAddVisit.map((v) => (
                      <MenuItem key={v.Name} value={v.Name}>
                        {v.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
              <Col xl="4">
                <TextField label="Center" id="standard-basic" fullWidth variant="standard" value={centerNameAddVisit} />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xl="6">
                <TextField label="Farmer Code" id="standard-basic" fullWidth variant="standard" value={farmerCodeAddVist} />
              </Col>
              <Col xl="6">
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Farmer</InputLabel>
                  <Select>
                    {farmerNameAddVisit.map((v) => (
                      <MenuItem key={v.Name} value={v.Name}>
                        {v.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>

            </Row>
            <Row className="mt-4">
              <Col xl="6">
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Animal Type*</InputLabel>
                  <Select onChange={(e) => handleAnimalType(e.target.value)}>
                    {animalTypeAddVisit.map((v) => (
                      <MenuItem key={v.Name} value={v.Name}>
                        {v.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
              <Col xl="6">
                <Checkbox checked={sortedSemenChecked} onChange={handleSortedSemenChange} /> <span>Sorted Semen</span>
              </Col>

            </Row>
            <Row className="mt-4">
              <Col xl="6">
                <TextField label="Animal Id" id="standard-basic" fullWidth variant="standard" />
              </Col>
              <Col xl="6">
                {/* <TextField label="AIT" id="standard-basic" fullWidth variant="standard" /> */}
                <FormControl variant="standard" fullWidth>
                  <InputLabel>AIT</InputLabel>
                  <Select>
                    {aITName.map((v) => (
                      <MenuItem key={v.Name} value={v.Name}>
                        {v.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>


            </Row>
            <Row className="mt-4">
              <Col xl="6">
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Visit Type</InputLabel>
                  <Select value={selectedValue} onChange={(e) => handleChange(e.target.value)} disabled>
                    {
                      visitTypeAddVisit.map((v) => (
                        <MenuItem key={v.id} value={v.id}>
                          {v.Name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Col>
              <Col xl="6">
                <TextField label="Visit Rate" id="standard-basic" fullWidth variant="standard" value={aIPDVisitRateAddvisit} disabled />
              </Col>

            </Row>
            <Row className="mt-4">
              <Col xl="6">
                <TextField label="Visit Address" fullWidth variant="standard" value={aIPDAddress} onChange={(e) => handleaddressADPD(e.target.value)} />
              </Col>
              <Col xl="6">
                <TextField label="Remarks" id="standard-basic" fullWidth variant="standard" />
              </Col>

            </Row>

          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-between">
              <Col xl="2">
                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} >{editId ? "Update" : "Save"}</button>
              </Col>
              <Col xl="2">
                <button className="btn-blue-Animal mt-1" onClick={() => setModalShowAIPD(false)} style={{ background: "#E16659" }}> Cancel </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      {/* ---------------------------------------column modal------------------------------------------------------ */}

      <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShowVisitDetails} onHide={() => setModalShowVisitDetails(false)}>
        <Modal.Header style={{ background: "#DFDFDF" }}>
          <Modal.Title id="contained-modal-title-vcenter">Visit Details</Modal.Title>
          <img src={closeButton} onClick={() => setModalShowVisitDetails(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xl="6">
                <TextField label="Visit ID " id="standard-basic" fullWidth variant="standard" value={visitDetails['Visit ID'] !== undefined && visitDetails['Visit ID'] !== null ? visitDetails['Visit ID'] : 'NA'} />
              </Col>
              <Col xl="6" >
                <TextField label="Center Name" id="standard-basic" fullWidth variant="standard" value={visitDetails['Center Name'] !== undefined && visitDetails['Center Name'] !== null ? visitDetails['Center Name'] : 'NA'} />
              </Col>
              <Col xl="6" >
                <TextField label="RequestDate" id="standard-basic" fullWidth variant="standard" value={visitDetails.RequestDate !== undefined && visitDetails.RequestDate !== null ? visitDetails.RequestDate : 'NA'} />
              </Col>
              <Col xl="6" >
                <TextField label="RequestTime" id="standard-basic" fullWidth variant="standard" value={visitDetails.RequestTime !== undefined && visitDetails.RequestTime !== null ? visitDetails.RequestTime : 'NA'} />
              </Col>
              <Col xl="6" >
                <TextField label="visitDate" id="standard-basic" fullWidth variant="standard" value={visitDetails.visitDate !== undefined && visitDetails.visitDate !== null ? visitDetails.visitDate : 'NA'} />
              </Col>
              <Col xl="6" >
                <TextField label="VisitTime" id="standard-basic" fullWidth variant="standard" value={visitDetails.VisitTime !== undefined && visitDetails.VisitTime !== null ? visitDetails.VisitTime : 'NA'} />
              </Col>
              <Col xl="6" >
                <TextField label="IDNo" id="standard-basic" fullWidth variant="standard" value={visitDetails.IDNo !== undefined && visitDetails.IDNo !== null ? visitDetails.IDNo : 'NA'} />
              </Col>
              <Col xl="6" >
                <TextField label="Diseases" id="standard-basic" fullWidth variant="standard" value={visitDetails.Diseases !== undefined && visitDetails.Diseases !== null ? visitDetails.Diseases : 'NA'} />
              </Col>
              <Col xl="6" >
                <TextField label="Cost" id="standard-basic" fullWidth variant="standard" value={visitDetails.Cost !== undefined && visitDetails.Cost !== null ? visitDetails.Cost : 'NA'} />
              </Col>
              <Col xl="6" >
                <TextField label="Farmer Name" id="standard-basic" fullWidth variant="standard" value={visitDetails['Farmer Name'] !== undefined && visitDetails['Farmer Name'] !== null ? visitDetails['Farmer Name'] : 'NA'} />
              </Col>
              <Col xl="6" >
                <TextField label="Farmer Code" id="standard-basic" fullWidth variant="standard" value={visitDetails['Farmer Code'] !== undefined && visitDetails['Farmer Code'] !== null ? visitDetails['Farmer Code'] : 'NA'} />
              </Col>
              <Col xl="6" >
                <TextField label="SAP Code" id="standard-basic" fullWidth variant="standard" value={visitDetails['SAP Code'] !== undefined && visitDetails['SAP Code'] !== null ? visitDetails['SAP Code'] : 'NA'} />
              </Col>
              <Col xl="6" >
                <TextField label="Mobile No" id="standard-basic" fullWidth variant="standard" value={visitDetails['Mobile No'] !== undefined && visitDetails['Mobile No'] !== null ? visitDetails['Mobile No'] : 'NA'} />
              </Col>
              <Col xl="6" >
                <TextField label="DCSName" id="standard-basic" fullWidth variant="standard" value={visitDetails.DCSName !== undefined && visitDetails.DCSName !== null ? visitDetails.DCSName : 'NA'} />
              </Col>
              <Col xl="6" >
                <TextField label="DCSCode" id="standard-basic" fullWidth variant="standard" value={visitDetails.DCSCode !== undefined && visitDetails.DCSCode !== null ? visitDetails.DCSCode : 'NA'} />
              </Col>
              <Col xl="6" >
                <TextField label="Vo Name" id="standard-basic" fullWidth variant="standard" value={visitDetails['Vo Name'] !== undefined && visitDetails['Vo Name'] !== null ? visitDetails['Vo Name'] : 'NA'} />
              </Col>
              <Col xl="6" >
                <TextField label="Complaint" id="standard-basic" fullWidth variant="standard" value={visitDetails.Complaint !== undefined && visitDetails.Complaint !== null ? visitDetails.Complaint : 'NA'} />
              </Col>
              <Col xl="6" >
                <TextField label="Diagnosis" id="standard-basic" fullWidth variant="standard" value={visitDetails.Diagnosis !== undefined && visitDetails.Diagnosis !== null ? visitDetails.Diagnosis : 'NA'} />
              </Col>
              <Col xl="12" >
                <TextField label="Medicine Given" id="standard-basic" fullWidth variant="standard" value={visitDetails['Medicine Given'] !== undefined && visitDetails['Medicine Given'] !== null ? visitDetails['Medicine Given'] : 'NA'} />
              </Col>
            </Row>

          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-between">
              <Col xl="2">
                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} >{editId ? "Update" : "Save"}</button>
              </Col>
              <Col xl="2">
                <button className="btn-blue-Animal mt-1" onClick={() => setModalShowVisitDetails(false)} style={{ background: "#E16659" }}> Cancel </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ fontSize: "14px" }}
      />

      {
        isLoading ?
          <div className="circleText">
            <div className="progressText">{progressData}</div>
            <div className="">
              <span className="loader-circle-13"></span>
            </div>
          </div>
          : false
      }
    </>

  )
}

export default Visit_Registration;