import React, { useState } from 'react'
import { Col, Container, Row, Offcanvas } from 'react-bootstrap'
import Sidenav from '../../Componet/Sidenav/Sidenav'
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg'
import Input from '../../Componet/InputFild/Input'
import Table from '../../Componet/DataTable/Table'
import { useEffect } from 'react'
import { MdOutlineVpnKey } from 'react-icons/md'
import { GoSignOut } from 'react-icons/go'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import Searcable from '../../Componet/Dropdown/Searcable'
import { STORAGE_KEY } from '../../constant/common'
import { API_URLS, serviceUrl } from '../../API/Api'
import { useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx';
import { utils, writeFile } from 'xlsx';
import BackBtn from '../../Componet/Button/BackBtn'
const { Cattle_weight, DoctorApi, TransferAnimal, SocietyApi, FarmerApi } =
  API_URLS
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN)
const USERID = localStorage.getItem('USERID')

async function getRouteData(credentials) {
  return fetch(TransferAnimal.GetVisitRoute, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${added_by}`
    },
    body: JSON.stringify(credentials)
  }).then(data => data.json())
}
async function getSocietyData(credentials) {
  return fetch(SocietyApi.GetDetails, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${added_by}`
    },
    body: JSON.stringify(credentials)
  }).then(data => data.json())
}
async function getFarmerData(credentials) {
  return fetch(FarmerApi.GetDetails, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${added_by}`
    },
    body: JSON.stringify(credentials)
  }).then(data => data.json())
}

async function getZone(credentials) {
  return fetch(DoctorApi.getZone, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${added_by}`
    },
    body: JSON.stringify(credentials)
  }).then(res => res.json())
}

async function getData(credentials) {
  return fetch(Cattle_weight.GetDetails, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${added_by}`
    },
    body: JSON.stringify(credentials)
  }).then(res => res.json())
}

async function SaveBulkCalf(credentials) {
  return fetch(Cattle_weight.SaveBulkCalfEntry, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${added_by}`
    },
    body: JSON.stringify(credentials)
  }).then(res => res.json())
}

async function GetCalf(credentials) {
  return fetch(Cattle_weight.GetCalfFillgrid, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${added_by}`
    },
    body: JSON.stringify(credentials)
  }).then(res => res.json())
}

const Weight = () => {
  const API_KEY = serviceUrl
  const [heightOffSet, setHeight] = useState(0)
  const navigate = useNavigate()

  const [search, setSearch] = useState('')
  const [searchFilter, setSearchFilter] = useState('')
  const [center, setCenter] = useState('')
  const [route, setRoute] = useState('')
  const [society, setSociety] = useState('')
  const [farmer, setFarmer] = useState('')
  const [date, setDate] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [headerShow, setHeaderShow] = useState(true)

  const [routeList, setRouteList] = useState([])
  const [societyList, setSocietyList] = useState([])
  const [farmerList, setFarmerList] = useState([])
  const [centerList, setCenterList] = useState([])
  const [calfList, setCalfList] = useState([])
  const [editableData, setEditableData] = useState([])

  const getDoctorData = async () => {
    const res = await getZone()
    if (res) {
      setCenterList(res)
    }
  }

  const fetchData = async () => {
    const res1 = await getRouteData()
    const res2 = await getSocietyData()
    const res3 = await getFarmerData()

    if (res1) {
      setRouteList(res1)
    }
    if (res2) {
      setSocietyList(res2.data)
      // console.log("aaa  " + JSON.stringify(res2.data))
    }
    if (res3) {
      setFarmerList(res3.data)
      // console.log("bbb  " + JSON.stringify(res3.data))
    }
  }

  // const fatchData = async (e, e1, e2) => {
  //   if (e) {
  //     fetch(`${API_KEY}/cattle-detail/getVisitRoutes/${e.value}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Accept: 'application/json',
  //         Authorization: `Bearer ${added_by}`
  //       }
  //     })
  //       .then(res => res.json())
  //       .then(result => {
  //         setRouteList(result)
  //       })
  //   }

  //   if (route) {
  //     fetch(`${API_KEY}/cattle-detail/getVisitLot/${route.value}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Accept: 'application/json',
  //         Authorization: `Bearer ${added_by}`
  //       }
  //     })
  //       .then(res => res.json())
  //       .then(result => {
  //         setSocietyList(result)
  //       })
  //   }
  //   if (society) {
  //     // console.log(society)
  //     fetch(`${API_KEY}/cattle-detail/getVisitFarmer/${society.value}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Accept: 'application/json',
  //         Authorization: `Bearer ${added_by}`
  //       }
  //     })
  //       .then(res => res.json())
  //       .then(result => {
  //         setFarmerList(result)
  //       })
  //   }
  //   const res2 = await GetCalf()

  //   if (res2) {
  //     setCalfList(e?.data)
  //   }
  // }

  const selectCenter = e => {
    setCenter(e)
    fetchData()
  }

  useEffect(() => {
    getDoctorData()
  }, [route, society])

  const GetData = async () => {
    const dataToSend = {
      date: String(date),
      Cop_Com: String(center?.value || ''),
      route: String(route?.value || ''),
      dcs: String(society?.value || ''),
      farmer: String(farmer?.value || '')
    }

    try {
      const response = await GetCalf(dataToSend)
      // console.log('Data saved successfully' + JSON.stringify(response))
      // toast.success('Data Saved Successfully..!')
      setCalfList(response)
      // console.log('aaa ' + JSON.stringify(response))
      // console.log('bbbb ' + JSON.stringify(calfList))
    } catch (error) {
      console.error('Error saving data:', error)
    }
  }

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0]
    setDate(today)
  }, [])
  const handleDateChange = e => {
    setDate(e.target.value)
  }

  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => {
    setHeaderShow(!headerShow)
  }
  const logOut = () => {
    navigate('/')
  }

  const [weightList, setWeightList] = useState([])

  const weightGetData = async () => {
    setIsLoading(true)
    const res = await getData()
    if (res) {
      setWeightList(res)
      setIsLoading(false)
    }
  }

  const saveFn = async () => {
    const filteredCalfList = calfList.filter(calf => calf.Weight > 0)
    const transformedCalfList = filteredCalfList.map(calf => ({
      Tagid: calf['Tag ID'],
      ChestGirth: calf.ChestGirth,
      lengthc: parseInt(calf.Length),
      Weight: calf.Weight,
      createdbyuser: USERID
    }))
    const payload = {
      jsonData: JSON.stringify(transformedCalfList),
      date,
      createdByUser: USERID
    }
    try {
      await fetch(Cattle_weight.SaveBulkCalfEntry, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`
        },
        body: JSON.stringify(payload)
      })
      GetData()
      toast.success('Data Saved Successfully..!')
    } catch (error) {
      console.error('Error saving data:', error)
    }
  }
  const deleteFn = async () => {
    const payload = {
      date,
      Cop_Com: 1,
      route: route.value,
      dcs: society,
      farmer: farmer,
    };
  
    try {
      const response = await fetch(Cattle_weight.deleteBulkCalfEntry, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${added_by}`,
        },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // Show success message from the response
        toast.success(data.message || "Operation successful");
      } else {
        // Show error message if the response is not ok
        toast.error(data.message || "Something went wrong");
      }
  
      GetData();
    } catch (error) {
      console.error('Error saving data:', error);
      toast.error("Error saving data");
    }
  };
  
  const SearchFn = e => {
    const data = e.target.value
    if (data !== '') {
      const results = calfList?.filter(user => {
        return user['Tag ID']?.toLowerCase().startsWith(data.toLowerCase())
      })
      setSearchFilter(results)
    } else {
      setSearchFilter(calfList)
    }
    setSearch(data)
  }

  useEffect(() => {
    let divHeight = document.getElementById('heightOffsetStock')?.offsetHeight
    setHeight(divHeight)
    weightGetData()
  }, [])

  const handleFileChange = event => {
    console.log("A")
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Get the first sheet
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        // Extract TagId and Weight
        const importedData = worksheet.map(row => ({
          TagId: row['Tagid'],
          Weight: row['Weight']
        }));
        console.log("importedData " + JSON.stringify(importedData))
        // Update calfList by matching TagId
        const updatedCalfList = calfList.map(calf => {
          const matchingTag = importedData.find(importedCalf => importedCalf.TagId === parseInt(calf["Tag ID"]));
          if (matchingTag) {
            return {
              ...calf,
              Weight: matchingTag.Weight
            };
          }
          return calf;
        });
        console.log("updatedCalfList " + JSON.stringify(updatedCalfList))

        // Update calfList state
        setCalfList(updatedCalfList);
        setEditableData(updatedCalfList); // Sync editableData with updated calfList
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedData = editableData.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [field]: value
        };
      }
      return item;
    });

    setEditableData(updatedData);
    setCalfList(updatedData);
  };


  const colum = [
    {
      name: 'TagId',
      selector: 'Tag ID',
      cell: e => (
        <p style={{ paddingRight: '5px', margin: 0 }} className='mx-auto'>
          {e['Tag ID']}
        </p>
      ),
      sortable: true
    },
    {
      name: 'Animal Name',
      selector: 'Animal Name',
      cell: e => (
        <p style={{ paddingRight: '5px', margin: 0 }} className='mx-auto'>
          {e['Animal Name']}
        </p>
      ),
      sortable: true
    },
    {
      name: 'Species',
      selector: 'Species',
      cell: e => (
        <p style={{ paddingRight: '5px', margin: 0 }} className='mx-auto'>
          {e['Species']}
        </p>
      ),
      sortable: true
    },
    {
      name: 'Breed',
      selector: 'Breed',
      cell: e => (
        <p style={{ paddingRight: '5px', margin: 0 }} className='mx-auto'>
          {e['Breed']}
        </p>
      ),
      sortable: true
    },
    {
      name: 'Month',
      selector: 'Month',
      cell: e => (
        <p style={{ paddingRight: '5px', margin: 0 }} className='mx-auto'>
          {e['Month']}
        </p>
      ),
      sortable: true
    },
    {
      name: 'Last Recording Date',
      selector: 'Last Recording Date',
      cell: e => (
        <p style={{ paddingRight: '5px', margin: 0 }} className='mx-auto'>
          {e['Last Recording Date']}
        </p>
      ),
      sortable: true
    },
    {
      name: 'Last Weight(kg)',
      selector: 'Last Weight(kg)',
      cell: e => (
        <p style={{ paddingRight: '5px', margin: 0 }} className='mx-auto'>
          {e['Last Weight(kg)']}
        </p>
      ),
      sortable: true
    },
    {
      name: 'ChestGirth',
      selector: 'ChestGirth',
      cell: (d, i) => (
        <input
          type='text'
          className='inpField1 mx-auto text-center'
          value={editableData[i]?.ChestGirth || d.ChestGirth || ''}
          onChange={e => handleInputChange(i, 'ChestGirth', e.target.value)}
        />
      ),
      sortable: true
    },
    {
      name: 'Length',
      selector: 'Length',
      cell: (d, i) => (
        <input
          type='text'
          className='inpField1 mx-auto text-center'
          value={editableData[i]?.Length || d.Length || ''}
          onChange={e => handleInputChange(i, 'Length', e.target.value)}
        />
      ),
      sortable: true
    },
    {
      name: 'Weight',
      selector: 'Weight',
      cell: (d, i) => (
        <input
          type='text'
          className='inpField1 mx-auto text-center'
          value={editableData[i]?.Weight || d.Weight || ''}
          onChange={e => handleInputChange(i, 'Weight', e.target.value)}
        />
      ),
      sortable: true
    }
  ]



  const handleExport = () => {
    const headers = colum?.map(col => col.name);
    const dataToExport = (searchFilter ? searchFilter : calfList)?.map(item => {
      return colum?.map(col => item[col.selector]);
    });
    const ws = utils.aoa_to_sheet([headers, ...dataToExport]);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Calf List');
    writeFile(wb, 'Weight_entry.xlsx');
  };

  useEffect(() => {
    setEditableData(calfList)
  }, [calfList])

  return (
    <>
      <Container fluid>
        <Row>
          {headerShow && (
            <Col lg='2' className='hideNavbar'>
              {' '}
              <Sidenav />
            </Col>
          )}
          <Col className='header' lg={headerShow ? '10' : '12'}>
            <Container fluid>
              <Row className='pt-1 sub-header align-items-center justify-content-between'>
                <Col
                  md='10'
                  sm='10'
                  xs='10'
                  className='py-2 d-flex align-items-center'
                >
                  <button
                    style={{ border: 'none' }}
                    className='hideNavbar1'
                    onClick={handleShow}
                  >
                    <img src={menu} width={20} />
                  </button>
                  <button
                    style={{ border: 'none' }}
                    className='hide_Navbar'
                    onClick={() => setShow(true)}
                  >
                    <img src={menu} width={20} />
                  </button>
                  <h5 className='ms-4 mt-2'>
                  </h5>
                  Cattle Details / Weight Entry{' '}
                </Col>
                <Col
                  md='1'
                  sm='1'
                  xs='10'
                  className='py-2 d-flex align-items-center text-end ms-auto'
                >
                  <BackBtn
                    style={{ border: 'none' }}
                    className='hideNavbar1'
                    lable='Back'
                    onClick={() => {
                      navigate('/')
                    }}
                  />
                </Col>
                <Col md='1' sm='1' xs='2' className='text-end profile_dropdown'>
                  <img src={profile} width={40} />
                  <div className='dropdpwn_Open'>
                    <Row className='text-center align-items-center'>
                      <Col xs='12' className='menuHover mb-3'>
                        <Row>
                          <Col className='' xs='3'>
                            <span className=''>
                              <MdOutlineVpnKey size={25} />
                            </span>
                          </Col>
                          <Col className='text-start' xs='9'>
                            Change Password
                          </Col>
                        </Row>
                      </Col>
                      <Col xs='12' className='menuHover' onClick={logOut}>
                        <Row>
                          <Col className='' xs='3'>
                            <span className=''>
                              <GoSignOut size={25} />
                            </span>
                          </Col>
                          <Col className='text-start' xs='9'>
                            Sign Out
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row className='align-items-end div-2' id='heightOffsetStock'>
                <Col
                  xxl='1'
                  xs='6'
                  sm='2'
                  md='2'
                  className='justify-content-start'
                >
                  <FormControl variant='standard' fullWidth>
                    <Searcable
                      placeholder='Center'
                      options={centerList}
                      onChange={selectedOption => selectCenter(selectedOption)}
                      value={center}
                      labelKey='Name'
                      valueKey='id'
                    />
                  </FormControl>
                </Col>
                <Col
                  xxl='1'
                  xs='6'
                  sm='2'
                  md='2'
                  className='justify-content-start'
                >
                  <FormControl variant='standard' fullWidth>
                    <Searcable
                      placeholder='Route'
                      options={routeList}
                      onChange={selectedOption => setRoute(selectedOption)}
                      value={route}
                      labelKey='name'
                      valueKey='Id'
                    />
                  </FormControl>
                </Col>
                <Col
                  xxl='1'
                  xs='6'
                  sm='2'
                  md='2'
                  className='justify-content-start'
                >
                  <FormControl variant='standard' fullWidth>
                    <Searcable
                      placeholder='Society'
                      options={societyList}
                      onChange={selectedOption => setSociety(selectedOption)}
                      value={society}
                      labelKey='DCSName'
                      valueKey='DCSCodeNo'
                    />
                  </FormControl>
                </Col>
                <Col
                  xxl='1'
                  xs='6'
                  sm='2'
                  md='2'
                  className='justify-content-start'
                >
                  <FormControl variant='standard' fullWidth>
                    <Searcable
                      placeholder='Farmer'
                      options={farmerList}
                      onChange={selectedOption => setFarmer(selectedOption)}
                      value={farmer}
                      labelKey='FarmerName'
                      valueKey='FarmerCode'
                    />
                  </FormControl>
                </Col>
                <Col xs='6' xxl='1' sm='3' md='2'>
                  <sub className='mb-0 pb-0'>Date</sub>
                  <input
                    type='date'
                    value={date}
                    className='dateSelect_bg'
                    onChange={handleDateChange}
                  />
                </Col>

                <Col
                  md='3'
                  xxl='2'
                  xl='2'
                  xs='6'
                  className='align-items-center ms-auto'
                >
                  <Input
                    label='Search'
                    value={search}
                    onChange={e => SearchFn(e)}
                  />
                </Col>
                <Col xxl='1' md='1'>
                  <button
                    className='btn-blue-Animal mt-1 mb-1'
                    onClick={GetData}
                  >
                    View
                  </button>
                </Col>
                <Col xxl='1' md='1'>
                  <button className='btn-blue-Animal mt-1 mb-1' onClick={handleExport}>Export</button>
                </Col>
                <Col xxl='1' md='1'>
                  <button
                    className='btn-blue-Animal mt-1 mb-1'
                    onClick={saveFn}
                  >
                    Save
                  </button>
                </Col>
                <Col xxl='1' md='1'>
                  <button
                    className='btn-blue-Animal mt-1 mb-1'
                    onClick={deleteFn}
                  >
                    Delete 
                  </button>
                </Col>

                <Col xxl='1' md='1'>
                  {/* <button className="btn-blue-Animal mt-1 mb-1" onClick={handleFileChange}>Import</button> */}
                  <button className='btn-blue-Animal mt-1 mb-1'>
                    <label className='file-input-label mb-0'>
                      Import
                      <input
                        type='file'
                        accept='.xlsx'
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                    </label>
                  </button>
                </Col>
              </Row>
              {/* <Row
                className='align-items-center justify-content-end d-flex'
                id='heightOffsetStock'
              >
                <Col xxl='2' md='3' sm='3' xs='6' className='mt-2'>
                  <Input
                    label='Search'
                    value={search}
                    onChange={e => SearchFn(e)}
                  />
                </Col>
              </Row> */}
              {/* <Row className="mt-2">
                                <Col>
                                    <Table columns={colum} data={searchFilter ? searchFilter : weightList} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination />
                                </Col>
                            </Row> */}
              <Row className='mt-2' id='heightOffsetStock'>
                {calfList?.length > 0 ? (
                  <div className='blue_bg DataTableBorder mt-1'>
                    <Table
                      columns={colum}
                      data={searchFilter ? searchFilter : calfList}
                      height={`calc(100vh - (${heightOffSet}px + 155px))`}
                      pagination
                      paginationPerPage={calfList.length} 
                      paginationRowsPerPageOptions={[calfList.length]}
                    />
                  </div>
                ) : (
                  <Row>
                    <Col>
                      <div className='NoTableBorder ms-2'>
                        <div className='d-flex tabledata colmBreed'>
                          {/* {console.log('COLUM  : ' + JSON.stringify(calfList))} */}
                          {colum?.map(i => {
                            return (
                              <Col
                                className={`blankColumns ${i.name.replace(
                                  ' ',
                                  '_'
                                )}`}
                                style={{ backgroundColor: 'rgb(169,169,169)' }}
                                height={`calc(100vh - (${heightOffSet}px + 20px))`}
                              >
                                {i.name}
                              </Col>
                            )
                          })}
                        </div>
                        <h6 className='text-center align-items-center mt-5 noData ms-5'>
                          No Data
                        </h6>
                      </div>
                    </Col>
                  </Row>
                )}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
        style={{ fontSize: '14px' }}
      />

      {show && (
        <Offcanvas className='hide_Navbar' show={show} onHide={handleClose}>
          <Container>
            <Row>
              <Col>
                <Sidenav />
              </Col>
            </Row>
          </Container>
        </Offcanvas>
      )}
    </>
  )
}
export default Weight
